<template>
  <ButtonBase
    :data-testid="dataTestId ?? 'button'"
    :color="color"
    :size="size"
    :type="buttonElementType"
    :flat="flat"
    :round="round"
    :disabled="buttonDisabled"
    :class="{
      'min-w-16': size === ButtonSize.xs,
      'min-w-24': size === ButtonSize.sm,
      'min-w-32': size === ButtonSize.md,
      'min-w-48': size === ButtonSize.lg,
      'min-w-64': size === ButtonSize.xl,
    }"
  >
    <Spinner
      v-if="loading"
      class="mr-2"
      :color="spinnerColor"
      :size="spinnerSize"
    ></Spinner>
    <Icon
      v-if="!loading && leadingIcon"
      :color="iconColor"
      :icon="leadingIcon"
      aria-hidden="true"
      :size="iconSize"
    />
    <span v-if="label">{{ label }}</span>
    <slot v-if="slots.default"></slot>
    <Icon
      v-if="trailingIcon"
      :icon="trailingIcon"
      :color="iconColor"
      :size="iconSize"
      aria-hidden="true"
    />
  </ButtonBase>
</template>

<script setup lang="ts">
import { computed, useSlots } from "vue";
import {
  ButtonElementType,
  ButtonSize,
} from "@/components/common/button/Button.types";
import Spinner from "@/components/common/spinner/Spinner.vue";
import { IconSize, IconNames } from "@/components/common/icon/Icon.types";
import ButtonBase from "./ButtonBase.vue";
import { Color } from "@/enums";
import { getForegroundColor } from "@/utils/color";
import Icon from "@/components/common/icon/Icon.vue";

const props = withDefaults(
  defineProps<{
    size?: ButtonSize;
    color?: Color;
    iconColor?: Color;
    buttonElementType?: ButtonElementType;
    label?: string;
    leadingIcon?: IconNames;
    trailingIcon?: IconNames;
    disabled?: boolean;
    loading?: boolean;
    round?: boolean;
    flat?: boolean;
    dataTestId?: string;
  }>(),
  {
    size: ButtonSize.md,
    color: Color.Emerald,
    buttonElementType: ButtonElementType.button,
    disabled: false,
    loading: false,
    round: false,
    flat: false,
    iconColor: undefined,
    label: undefined,
    leadingIcon: undefined,
    trailingIcon: undefined,
    dataTestId: undefined,
  },
);

const buttonDisabled = computed<boolean>(() => props.disabled || props.loading);

const spinnerColor = computed(() => getForegroundColor(props.color));

const spinnerSizeMapping = new Map([
  [ButtonSize.xs, IconSize.xs],
  [ButtonSize.sm, IconSize.sm],
  [ButtonSize.md, IconSize.md],
  [ButtonSize.lg, IconSize.md],
  [ButtonSize.xl, IconSize.md],
]);
const spinnerSize = computed(() => spinnerSizeMapping.get(props.size));

const iconSize = computed(() => {
  switch (props.size) {
    case ButtonSize.xs:
      return IconSize.xs;
    case ButtonSize.sm:
      return IconSize.sm;
    case ButtonSize.md:
      return IconSize.sm;
    case ButtonSize.lg:
      return IconSize.sm;
    case ButtonSize.xl:
      return IconSize.sm;
    default:
      return IconSize.sm;
  }
});

const slots = useSlots();
</script>
