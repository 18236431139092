import { DateTime } from "luxon";
import { ContactMomentType, ContactMomentDTO } from "@/lib/eduConfigurationServiceClient";

export class ContactMoment {
  id: string;
  type: ContactMomentType;
  activityId?: string;
  activityName?: string;
  mailingName?: string;
  dateTime: DateTime;
  locationName?: string;
  registrationLinkName?: string;
  brochureNames?: string[];
  studyProgramName?: string;
  studyProgramStartsAt?: DateTime;
  editLink?: string;
  confirmationCanBeResent?: boolean;
  sessionId?: string;
  sessionName?: string;
  sessionStartsAt?: DateTime;
  studyProgramNames?: string[];
  messageText?: string;
  ticketCode?: string;
  sourceName?: string;

  constructor(dto: ContactMomentDTO) {
    this.id = dto.id;
    this.type = dto.type;
    this.activityId = dto.activityId;
    this.activityName = dto.activityName;
    this.mailingName = dto.mailingName;
    this.dateTime = dto.dateTime;
    this.locationName = dto.details.locationName;
    this.registrationLinkName = dto.details.registrationLinkName;
    this.brochureNames = dto.details.brochureNames;
    this.studyProgramName = dto.details.studyProgramName;
    this.studyProgramStartsAt = !dto.details.studyProgramStartsAt
      ? undefined
      : DateTime.fromISO(dto.details.studyProgramStartsAt);
    this.editLink = dto.details.editLink;
    this.confirmationCanBeResent = dto.details.confirmationCanBeResent;
    this.sessionId = dto.details.sessionId;
    this.sessionName = dto.details.sessionName;
    this.sessionStartsAt = !dto.details.sessionStartsAt
      ? undefined
      : DateTime.fromISO(dto.details.sessionStartsAt);
    this.studyProgramNames = dto.details.studyProgramNames;
    this.messageText = dto.details.messageText;
    this.ticketCode = dto.details.ticketCode;
    this.sourceName = dto.details.sourceName;
  }
}
