<template>
  <FormLayout @submit="submit">
    <h2 class="text-xl font-semibold text-deepteal-700">
      {{ componentTexts.basic }}
    </h2>
    <FormFieldTextInput
      :id="fieldIds.name"
      :label="componentTexts.name"
      :displayMode="FormFieldDisplayMode.Column"
      :data-testid="fieldIds.name"
    />
    <FormFieldSelect
      :id="fieldIds.formId"
      :label="componentTexts.form"
      :items="formTemplateSelectOptions"
      :displayMode="FormFieldDisplayMode.Column"
      allowUnset
    />
    <RouterLinkAuth
      v-if="formValues?.formId"
      :to="{
        name: RouteNamesSettings.FORMS_EDIT_TEMPLATE,
        params: { id: formValues.formId },
      }"
      class="-mt-4 text-sm text-emerald-500 underline hover:text-emerald-700"
    >
      {{ componentTexts.editForm }}
    </RouterLinkAuth>
    <FormFieldSelect
      :id="fieldIds.confirmationEmailTemplateId"
      :label="componentTexts.confirmationEmailTemplate"
      :items="emailTemplateSelectOptions"
      :displayMode="FormFieldDisplayMode.Column"
      allowUnset
    />
    <RouterLinkAuth
      v-if="formValues?.confirmationEmailTemplateId"
      :to="{
        name: RouteNamesSettings.EMAIL_EDIT_EMAIL_TEMPLATE,
        params: { id: formValues.confirmationEmailTemplateId },
      }"
      class="-mt-4 text-sm text-emerald-500 underline hover:text-emerald-700"
    >
      {{ componentTexts.editEmailTemplate }}
    </RouterLinkAuth>

    <h2 class="-mb-4 text-xl font-semibold text-deepteal-700">
      {{ componentTexts.studyPrograms }}
    </h2>
    <span class="text-sm text-deepteal-800">
      {{ componentTexts.studyProgramsDescription }}
    </span>
    <FormFieldStudyProgramPicker
      :id="fieldIds.studyProgramIds"
      :studyPrograms="settings.allStudyPrograms"
      :selectedIds="form.values.studyProgramIds"
    />
    <FormFieldTextInput
      :id="fieldIds.maximumStudyProgramsPerRegistrant"
      :label="componentTexts.maximumStudyProgramsPerRegistrant"
      :displayMode="FormFieldDisplayMode.Column"
      :data-testid="fieldIds.maximumStudyProgramsPerRegistrant"
      :type="TextInputType.NUMBER"
      :min="0"
    />
    <template v-slot:actions>
      <slot></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import FormLayout from "@/components/common/form/FormLayout.vue";
import FormFieldSelect from "@/components/common/select/FormFieldSelect.vue";
import { SelectOption } from "@/components/common/select/SelectOption";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import RouterLinkAuth from "@/components/router/router-link-auth/RouterLinkAuth.vue";
import FormFieldStudyProgramPicker from "@/components/study-programs/study-program-picker/FormFieldStudyProgramPicker.vue";
import { EmailTemplateType, FormType } from "@/enums";
import EmailTemplate from "@/models/emailTemplates";
import { FormTemplate } from "@/models/template";
import logger from "@/plugins/logger";
import { RouteNamesSettings } from "@/router/routeNames";
import { getEmailTemplates } from "@/services/emailTemplates.service";
import { formsServiceClient } from "@/services/formsService.client.service";
import settings from "@/store/context/settings.context";
import { fields } from "@/utils/miscellaneous";
import texts from "@/utils/texts";
import { useForm } from "vee-validate";
import { computed, ref, watch } from "vue";
import * as yup from "yup";
import { TrialDayConfigurationFormValues } from "./TrialDayConfigurationForm.types";

const componentTexts = texts.navigationItems.organize.trialDays.settings;

const props = defineProps<{
  formValues?: TrialDayConfigurationFormValues;
  selectedFormIds?: string[];
  copyToClipboard?: boolean;
}>();

const emit = defineEmits<{
  (e: "submit", values: TrialDayConfigurationFormValues): void;
}>();

const form = useForm<TrialDayConfigurationFormValues>({
  validationSchema: yup.object({
    name: yup.string().required(),
    formId: yup.string().required(),
    confirmationEmailTemplateId: yup.string(),
    studyProgramIds: yup
      .array()
      .of(yup.string())
      .min(1, componentTexts.minimumStudyPrograms)
      .required(),
    maximumStudyProgramsPerRegistrant: yup.number().positive(),
  }),
});

const fieldIds = fields<TrialDayConfigurationFormValues>();

const submit = form.handleSubmit((values) => emit("submit", values));

// Fetch email-templates
const loadingEmailTemplates = ref(true);
const errorLoadingEmailTemplates = ref(false);
const emailTemplates = ref<EmailTemplate[]>([]);
const emailTemplateSelectOptions = computed<SelectOption[]>(() =>
  emailTemplates.value.map((et) => ({
    label: et.name,
    value: et.id,
  })),
);
getEmailTemplates()
  .then(
    (res) =>
      (emailTemplates.value = res.filter(
        (et) => et.type === EmailTemplateType.TrialDayConfirmation,
      )),
  )
  .catch((e) => {
    errorLoadingEmailTemplates.value = true;
    logger.error(e);
  })
  .finally(() => (loadingEmailTemplates.value = false));

// Fetch form templates
const loadingFormTemplates = ref(true);
const errorLoadingFormTemplates = ref(false);
const formTemplates = ref<FormTemplate[]>([]);
const formTemplateSelectOptions = computed<SelectOption[]>(() =>
  formTemplates.value
    .filter((t) => !props.selectedFormIds?.includes(t.id))
    .map((t) => ({
      label: t.name,
      value: t.id,
    })),
);

formsServiceClient
  .getForms(FormType.TrialDayRegistration)
  .then(
    (res) => (formTemplates.value = res.map((r) => FormTemplate.fromDto(r))),
  )
  .catch((e) => {
    errorLoadingFormTemplates.value = true;
    logger.error(e);
  })
  .finally(() => (loadingFormTemplates.value = false));

watch(
  () => props.formValues,
  (values) => form.setValues({ ...values }),
  { immediate: true },
);
</script>
