import { SelectionPeriodFormValues } from "@/components/selection/filter/components/SelectionPeriodFilterForm.vue";
import { RecruitmentYearDTO } from "@/lib/eduConfigurationServiceClient";
import settings from "@/store/context/settings.context";
import { DateTime } from "luxon";

export function toCurrentRecruitmentYearDates(
  recruitmentYear: RecruitmentYearDTO,
): SelectionPeriodFormValues {
  const currentDate = DateTime.now();

  let start = DateTime.fromObject({
    year: currentDate.year,
    month: recruitmentYear.monthStart,
    day: recruitmentYear.dayStart,
  });

  // eg. in January 2025, current recruitment year is still 2024-2025 until start-date
  // so we need to set start and end to the previous year
  if (currentDate < start) {
    start = start.minus({ year: 1 });
  }

  return {
    start,
    end: start.plus({ year: 1 }).minus({ day: 1 }),
  };
}

export function toPreviousRecruitmentYearDates(
  recruitmentYear: RecruitmentYearDTO,
): SelectionPeriodFormValues {
  const current = toCurrentRecruitmentYearDates(recruitmentYear);

  return {
    start: current.start.minus({ year: 1 }),
    end: current.end.minus({ year: 1 }),
  };
}

export function getCurrentRecruitmentYear(): SelectionPeriodFormValues {
  return toCurrentRecruitmentYearDates(settings.recruitmentYear);
}
