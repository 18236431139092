import { Culture } from "@/enums";
import {
  ActivityLocationDTO,
  ActivityLocationLocalizationDTO,
  CreateActivityLocationDTO,
  UpdateActivityLocationDTO,
  IUpdateActivityLocationLocalizationDTO,
  ICreateActivityLocationLocalizationDTO,
} from "@/lib/eduConfigurationServiceClient";

export interface ActivityLocationFormValues {
  localizations: ActivityLocationFormValuesLocalizations;
}

export type ActivityLocationFormValuesLocalizations = Partial<
  Record<Culture, ActivityLocationFormValuesLocalization>
>;

export type ActivityLocationFormValuesLocalization = {
  name: string;
  city?: string;
  informationUrl?: string;
};

export const convertToFormValues = (
  activityLocation: ActivityLocationDTO,
): ActivityLocationFormValues => {
  const localizations = localizationArrayToObject(
    activityLocation.localizations,
  );

  return {
    localizations,
  };
};

export const convertToCreateDto = (
  values: ActivityLocationFormValues,
): CreateActivityLocationDTO => {
  return new CreateActivityLocationDTO({
    localizations: updateLocalizationObjectToArray(values.localizations),
  });
};

export const convertToUpdateDto = (
  values: ActivityLocationFormValues,
): UpdateActivityLocationDTO => {
  return new UpdateActivityLocationDTO({
    localizations: updateLocalizationObjectToArray(values.localizations),
  });
};

export function localizationArrayToObject(
  localizations: ActivityLocationLocalizationDTO[],
) {
  return localizations.reduce((cur, localization) => {
    const key = localization.locale as Culture;
    cur[key] = {
      name: localization.name,
      city: localization.city,
      informationUrl: localization.informationUrl,
    };
    return cur;
  }, {} as ActivityLocationFormValuesLocalizations);
}

export function updateLocalizationObjectToArray(
  localizations: ActivityLocationFormValuesLocalizations,
): (
  | IUpdateActivityLocationLocalizationDTO
  | ICreateActivityLocationLocalizationDTO
)[] {
  return Object.entries(localizations).map(([locale, localization]) => ({
    locale: locale as Culture,
    name: localization.name,
    city: localization.city,
    informationUrl: localization.informationUrl,
  }));
}
