<template>
  <FormLayout @submit="submit">
    <FormFieldTextInput id="name" :label="texts.generic.name" />
    <FormFieldSelectNative
      id="activityTypeId"
      :label="texts.models.activityType.title"
      :options="activityTypeOptions"
    />
    <template v-slot:actions>
      <slot></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import FormLayout from "@/components/common/form/FormLayout.vue";
import FormFieldSelectNative from "@/components/common/select/FormFieldSelectNative.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import { ActivityMailingsConfigurationDto } from "@/lib/eduConfigurationServiceClient";
import settings from "@/store/context/settings.context";
import texts from "@/utils/texts";
import { useForm } from "vee-validate";
import { computed } from "vue";
import * as yup from "yup";

const props = defineProps<{
  item?: ActivityMailingsConfigurationDto;
}>();

const emit = defineEmits<{
  submit: [ActivityMailingsConfigurationFormValues];
}>();

export type ActivityMailingsConfigurationFormValues = {
  name: string;
  activityTypeId: string;
};

const form = useForm<ActivityMailingsConfigurationFormValues>({
  initialValues: props.item ? convertToFormValues(props.item) : undefined,
  validationSchema: yup.object({
    name: yup.string().required(),
    activityTypeId: yup.string().required(),
  }),
});

const activityTypeOptions = computed(() =>
  settings.activityTypes.map((activityType) => ({
    label: activityType.name,
    value: activityType.id,
  })),
);

const submit = form.handleSubmit((values) => emit("submit", values));

function convertToFormValues(
  item: ActivityMailingsConfigurationDto,
): ActivityMailingsConfigurationFormValues {
  return {
    name: item.name,
    activityTypeId: item.activityTypeId,
  };
}
</script>
