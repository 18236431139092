import { alterStudyProgramMetadataLocalizationsInDictionary } from "@/dictionary";
import {
  ActivityLocationLocalizedDTO,
  EmailTemplateOverviewDto,
  FromEmailAddressDTO,
  LocalizedActivityTypeDTO,
  LocalizedLanguage,
  LocalizedLanguageDTO,
  LocalizedSessionTypeDto,
  LocalizedStudyProgramDTO,
  PreEducationSchool,
  RecruitmentYearDTO,
  ReplyToEmailAddressDto,
  TargetAudienceDTO,
} from "@/lib/eduConfigurationServiceClient";
import { LocalizedFormDTO } from "@/lib/formsServiceClient";
import { MailingType } from "@/models/mailingType";
import { StudyProgramMetadataLocalization } from "@/models/study-program-metadata-localization";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { formsServiceClient } from "@/services/formsService.client.service";
import { getStudyProgramMetadataLocalizations } from "@/services/localizations.service";
import { getMailingTypes } from "@/services/mailings.service";
import HttpClient from "@/utils/httpClient";
import { Permission } from "@/lib/eduConfigurationServiceClient";
import { hasPermissions } from "@/router/helpers/userCanGoTo";
import { reactive } from "vue";
import { Culture } from "@/enums";
import { Settings } from "luxon";
import storedCulture from "@/services/culture.service";
import { alterYupLocalizations } from "@/yup-extensions";

interface SettingsContext {
  culture: Culture;
  localizations: StudyProgramMetadataLocalization;
  languages: LocalizedLanguageDTO[];
  availableLanguages: LocalizedLanguage[];
  mainLanguage: LocalizedLanguage;
  mailingTypes: MailingType[];
  allStudyPrograms: LocalizedStudyProgramDTO[];
  studyPrograms: LocalizedStudyProgramDTO[];
  activityTypes: LocalizedActivityTypeDTO[];
  activityLocations: ActivityLocationLocalizedDTO[];
  sessionTypes: LocalizedSessionTypeDto[];
  forms: LocalizedFormDTO[];
  emailTemplates: EmailTemplateOverviewDto[];
  targetAudiences: TargetAudienceDTO[];
  recruitmentYear: RecruitmentYearDTO;
  preEducationSchools: PreEducationSchool[];
  fromEmailAddresses: FromEmailAddressDTO[];
  replyToEmailAddresses: ReplyToEmailAddressDto[];
}

const settings: SettingsContext = reactive<SettingsContext>({
  culture: storedCulture.value,
  localizations: {} as StudyProgramMetadataLocalization,
  languages: [],
  availableLanguages: [],
  mainLanguage: {} as LocalizedLanguage,
  mailingTypes: [],
  allStudyPrograms: [],
  studyPrograms: [],
  activityTypes: [],
  activityLocations: [],
  sessionTypes: [],
  forms: [],
  emailTemplates: [],
  targetAudiences: [],
  recruitmentYear: {} as RecruitmentYearDTO,
  preEducationSchools: [],
  fromEmailAddresses: [],
  replyToEmailAddresses: [],
});

export default settings;

export async function loadSettings() {
  await Promise.all([
    loadCulture(storedCulture.value),
    loadLocalizations(),
    loadLanguages(),
    loadAvailableLanguages(),
    loadMailingTypes(),
    loadStudyPrograms(),
    loadActivityTypes(),
    loadActivityLocations(),
    loadSessionTypes(),
    loadForms(),
    loadEmailTemplates(),
    loadTargetAudiences(),
    loadRecruitmentYear(),
    loadPreEducationSchools(),
    loadFromEmailAddresses(),
    loadReplyToEmailAddresses(),
  ]);
}

export async function loadCulture(culture: Culture) {
  HttpClient.setCulture(culture);
  Settings.defaultLocale = culture;
  settings.culture = culture;
  storedCulture.value = culture;
  alterYupLocalizations(culture);
}

export async function loadLocalizations() {
  const canRead = hasPermissions([Permission.StudyprogramsRead]);

  settings.localizations = canRead
    ? await getStudyProgramMetadataLocalizations()
    : ({
        areaOfInterestLocalizations: { items: [] },
        departmentLocalizations: { items: [] },
        levelLocalizations: { items: [] },
        locationLocalizations: { items: [] },
        modeLocalizations: { items: [] },
      } as StudyProgramMetadataLocalization);

  alterStudyProgramMetadataLocalizationsInDictionary(settings.localizations);
}

export async function loadLanguages() {
  settings.languages = (await HttpClient.getLanguages()).data;
}

export async function loadAvailableLanguages() {
  const availableLanguages =
    await eduConfigurationServiceClient.getAvailableLanguages();

  settings.availableLanguages = [
    availableLanguages.mainLanguage,
    ...availableLanguages.otherLanguages,
  ];

  settings.mainLanguage = availableLanguages.mainLanguage;
}

export async function loadMailingTypes() {
  const canRead = hasPermissions([Permission.EmailtemplatesRead]);
  settings.mailingTypes = canRead ? await getMailingTypes() : [];
}

export async function loadStudyPrograms() {
  const canRead = hasPermissions([Permission.StudyprogramsRead]);

  settings.allStudyPrograms = canRead
    ? await eduConfigurationServiceClient.getStudyPrograms(true)
    : [];
  settings.studyPrograms = settings.allStudyPrograms.filter(
    (sp) => sp.isArchived === false,
  );
}

export async function loadActivityTypes() {
  const canRead = hasPermissions([Permission.ActivitiesRead]);
  settings.activityTypes = canRead
    ? await eduConfigurationServiceClient.getActivityTypes()
    : [];
}

export async function loadActivityLocations() {
  const canRead = hasPermissions([Permission.ActivitiesRead]);
  settings.activityLocations = canRead
    ? await eduConfigurationServiceClient.getAllActivityLocations()
    : [];
}

export async function loadSessionTypes() {
  const canRead = hasPermissions([Permission.SessionsRead]);
  settings.sessionTypes = canRead
    ? await eduConfigurationServiceClient.getSessionTypes()
    : [];
}

export async function loadForms() {
  const canRead = hasPermissions([Permission.FormsRead]);
  settings.forms = canRead ? await formsServiceClient.getForms(undefined) : [];
}

export async function loadEmailTemplates() {
  const canRead = hasPermissions([Permission.EmailtemplatesRead]);
  settings.emailTemplates = canRead
    ? await eduConfigurationServiceClient.getEmailTemplates()
    : [];
}

export async function loadTargetAudiences() {
  const canRead = hasPermissions([Permission.TargetaudiencesRead]);
  settings.targetAudiences = canRead
    ? await eduConfigurationServiceClient.getTargetAudiences()
    : [];
}

export async function loadRecruitmentYear() {
  settings.recruitmentYear =
    await eduConfigurationServiceClient.getTenantRecruitmentYear();
}

export async function loadPreEducationSchools() {
  settings.preEducationSchools =
    await eduConfigurationServiceClient.getAllPreEducationSchools();
}

export async function loadFromEmailAddresses() {
  settings.fromEmailAddresses =
    await eduConfigurationServiceClient.getFromEmailAddresses(
      true,
      undefined,
      undefined,
    );
}

export async function loadReplyToEmailAddresses() {
  settings.replyToEmailAddresses =
    await eduConfigurationServiceClient.getReplyToEmailAddresses();
}
