<template>
  <DropdownWrapper :origin="DropdownOrigin.TopCenter">
    <template v-slot:button>
      <ButtonGroup :color="Color.White" :size="ButtonSize.sm">
        <ButtonBase @click="previousYear">
          <Icon
            :icon="'keyboard_double_arrow_left'"
            :size="IconSize.sm"
            filled
          />
        </ButtonBase>
        <ButtonBase @click="previousMonth">
          <Icon :icon="'chevron_left'" :size="IconSize.sm" filled />
        </ButtonBase>
        <MenuButton
          :as="Button"
          :color
          :size
          :flat
          :round
          :disabled
          :grouped
          class="min-w-48"
        >
          <span class="text-nowrap">{{ monthLabel }}</span>
          <Icon
            icon="calendar_month"
            :color="Color.DeepTeal"
            :size="IconSize.sm"
            clickable
          />
        </MenuButton>
        <ButtonBase @click="nextMonth">
          <Icon :icon="'chevron_right'" :size="IconSize.sm" filled />
        </ButtonBase>
        <ButtonBase @click="nextYear">
          <Icon
            :icon="'keyboard_double_arrow_right'"
            :size="IconSize.sm"
            filled
          />
        </ButtonBase>
      </ButtonGroup>
    </template>
    <template v-slot:items>
      <MenuItem class="rounded-md bg-gray-50">
        <div class="grid grid-cols-3 gap-1 p-3">
          <template v-for="month in 12" :key="month">
            <ButtonBase
              class="focus:ring-primary-500 flex h-10 w-full items-center justify-center rounded-md border border-gray-200 bg-white text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2"
              :color="Color.White"
              @click="setMonth(month)"
            >
              {{ monthAbbreviation(month) }}
            </ButtonBase>
          </template>
        </div>
      </MenuItem>
    </template>
  </DropdownWrapper>
</template>

<script setup lang="ts">
import { ButtonSize } from "@/components/common/button/Button.types";
import Button from "@/components/common/button/Button.vue";
import ButtonBase from "@/components/common/button/ButtonBase.vue";
import ButtonGroup from "@/components/common/button/ButtonGroup.vue";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import DropdownWrapper from "@/components/common/dropdown/DropdownWrapper.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import Icon from "@/components/common/icon/Icon.vue";
import { Color } from "@/enums";
import { MenuButton, MenuItem } from "@headlessui/vue";
import { DateTime } from "luxon";
import { computed } from "vue";

const dateTime = defineModel<DateTime | undefined>("dateTime");

const monthAbbreviation = (month: number) => {
  return DateTime.fromObject({ month }).toFormat("LLL");
};

const setMonth = (newMonth: number) => {
  dateTime.value = dateTime.value?.set({ month: newMonth });
};

defineProps<{
  grouped?: boolean;
  color?: Color;
  size?: ButtonSize;
  flat?: boolean;
  round?: boolean;
  disabled?: boolean;
}>();

const monthLabel = computed(() => dateTime.value?.toFormat("LLLL yyyy"));

const previousMonth = () => {
  dateTime.value = dateTime.value?.minus({ months: 1 });
};

const nextMonth = () => {
  dateTime.value = dateTime.value?.plus({ months: 1 });
};

const previousYear = () => {
  dateTime.value = dateTime.value?.minus({ years: 1 });
};

const nextYear = () => {
  dateTime.value = dateTime.value?.plus({ years: 1 });
};
</script>
