<template>
  <div v-if="isReady" class="flex flex-col gap-2">
    <ButtonBack :toRouteName="texts.generic.previous" @click="router.go(-1)" />
    <ModelPageBuilder
      :modelTexts="{
        title:
          activityMailingsConfiguration?.name ??
          texts.models.mailingConfiguration.title,
        plural:
          activityMailingsConfiguration?.name ??
          texts.models.mailingConfiguration.plural,
      }"
      :list="list"
      :editItem="editItem"
    >
      <template v-slot:tag>
        <ActivityTypeTag
          v-if="activityMailingsConfiguration?.activityTypeId"
          :activityTypeId="activityMailingsConfiguration.activityTypeId"
        />
      </template>
      <template v-slot:createButton="{ isLoading }">
        <ButtonAdd
          :disabled="isLoading"
          @click="typeSelectionModalVisible = true"
        />
        <MailingConfigurationTypeSelectionModal
          v-model:visible="typeSelectionModalVisible"
          @type="setMailingType"
        />
      </template>
    </ModelPageBuilder>
  </div>
</template>

<script setup lang="ts">
import ButtonBack from "@/components/common/button/ButtonBack.vue";
import ModelPageBuilder, {
  EditItemProps,
} from "@/components/model-page-builder/ModelPageBuilder.vue";
import { RouteNamesMailingConfiguration } from "@/router/routeNames";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";

import texts from "@/utils/texts";
import MailingConfigurationListItem from "@/views/settings/activity-mailings-configurations/mailing-configuration/components/MailingConfigurationListItem.vue";
import MailingConfigurationListItemHeader from "@/views/settings/activity-mailings-configurations/mailing-configuration/components/MailingConfigurationListItemHeader.vue";
import { useAsyncState } from "@vueuse/core";
import { useRouter } from "vue-router";
import ActivityTypeTag from "@/views/settings/activity-mailings-configurations/mailing-configuration/components/ActivityTypeTag.vue";
import {
  MailingConfigurationDto,
  MailingType,
} from "@/lib/eduConfigurationServiceClient";
import { DateTime } from "luxon";
import ButtonAdd from "@/components/common/button/ButtonAdd.vue";
import { ref } from "vue";
import MailingConfigurationTypeSelectionModal from "@/views/settings/activity-mailings-configurations/mailing-configuration/components/MailingConfigurationTypeSelectionModal.vue";

const props = defineProps<{
  activityMailingsConfigurationId: string;
}>();

const { state: activityMailingsConfiguration, isReady } = useAsyncState(
  () =>
    eduConfigurationServiceClient.getActivityMailingsConfiguration(
      props.activityMailingsConfigurationId,
    ),
  undefined,
);

const router = useRouter();

const list = {
  load: () =>
    eduConfigurationServiceClient
      .getAllMailingConfigurations(props.activityMailingsConfigurationId)
      .then((dtos) =>
        dtos

          .sort((a, b) => a.name.localeCompare(b.name))
          .sort((a, b) => a.type.localeCompare(b.type))
          .sort((a, b) =>
            toPlanningDateTime(a) >= toPlanningDateTime(b) ? 1 : -1,
          ),
      ),
  item: MailingConfigurationListItem,
  header: MailingConfigurationListItemHeader,
};

function toPlanningDateTime(mailingConfig: MailingConfigurationDto) {
  let dateTime = DateTime.now();
  if (mailingConfig.relativePlanningDayOffset) {
    dateTime = dateTime.plus({ days: mailingConfig.relativePlanningDayOffset });
    dateTime = dateTime.set({
      hour: mailingConfig.relativePlanningTimeOfDay?.hours ?? 0,
      minute: mailingConfig.relativePlanningTimeOfDay?.minutes ?? 0,
    });
  }
  return dateTime;
}

const typeSelectionModalVisible = ref<boolean>(false);
const selectedMailingType = ref<MailingType>();

async function setMailingType(type: MailingType) {
  selectedMailingType.value = type;
  router.push({
    name: RouteNamesMailingConfiguration.MAILING_CONFIGURATION_CREATE,
    params: {
      activityMailingsConfigurationId: props.activityMailingsConfigurationId,
      mailingType: type,
    },
  });
}

const editItem: EditItemProps = {
  route: {
    name: RouteNamesMailingConfiguration.MAILING_CONFIGURATION_EDIT,
    params: {
      mailingConfigurationId: props.activityMailingsConfigurationId,
    },
  },
};
</script>
