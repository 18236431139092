<template>
  <div class="flex flex-col items-stretch gap-1 text-sm">
    <div v-if="$slots.header" class="flex text-gray-300">
      <slot name="header"></slot>
    </div>
    <ul
      :class="[
        'flex flex-col rounded-md',
        compact ? 'border border-gray-100' : 'gap-2',
      ]"
    >
      <slot></slot>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { provide } from "vue";
import { ListInjectionKeyCompact } from "./List.types";

const props = defineProps<{ compact?: boolean }>();

provide(ListInjectionKeyCompact, props.compact);
</script>
