<template>
  <ListItem clickable @click="emit('edit', item)">
    <ListItemColumn>{{ item.name }}</ListItemColumn>
    <ListItemColumn>{{
      settings.preEducationSchools.find(
        (s) => s.id === item.preEducationSchoolId,
      )?.displayName
    }}</ListItemColumn>
  </ListItem>
</template>

<script setup lang="ts">
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import { IAffiliateDto } from "@/lib/eduConfigurationServiceClient";
import settings from "@/store/context/settings.context";

defineProps<{
  item: IAffiliateDto;
}>();

const emit = defineEmits<{
  edit: [IAffiliateDto];
}>();
</script>
