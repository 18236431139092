<template>
  <Card
    class="flex flex-col gap-4"
    :data-testid="testIds.contact.details.title"
  >
    <div class="flex w-full gap-2">
      <Icon icon="description"></Icon>
      <CardHeader>{{ texts.models.contactDetails.notes.plural }}</CardHeader>
    </div>
    <template v-if="showForm">
      <form class="flex flex-col gap-4" @submit="submit">
        <FormFieldWysiwyg id="note" />
        <div class="flex flex-col gap-2 sm:flex-row">
          <ButtonSubmit class="flex-1" :size="ButtonSize.sm" />
          <ButtonCancel
            class="flex-1"
            :size="ButtonSize.sm"
            @click="onClickCancel"
          />
        </div>
      </form>
    </template>
    <template v-else>
      <div v-for="note in notes" :key="note.id" class="flex flex-col gap-4">
        <div class="flex flex-col">
          <div class="flex flex-row justify-between gap-3">
            <div class="text-sm font-medium text-deepteal-500">
              {{ note.noteDateTime.toLocaleString(DateTime.DATE_SHORT) }}
            </div>
            <div
              v-if="hasPermissions([Permission.ContactsWrite])"
              class="flex flex-row gap-2"
            >
              <button
                name="button"
                class="text-sm text-deepteal-500"
                @click="onClickEdit(note)"
              >
                <Icon icon="edit" :size="IconSize.sm" clickable />
              </button>
              <button
                name="button"
                class="text-sm text-alert-500"
                @click="onClickDelete(note.id)"
              >
                <Icon icon="delete" :size="IconSize.sm" clickable />
              </button>
            </div>
          </div>
          <div class="text-sm" v-html="note.value"></div>
        </div>
        <Divider />
      </div>
      <TextButton
        v-if="hasPermissions([Permission.ContactsWrite])"
        class="text-sm"
        :color="Color.Emerald"
        :data-testid="testIds.action.edit"
        @click="onClickCreate"
        >{{
          interpolate(
            texts.actions.add,
            texts.models.contactDetails.notes.title,
          )
        }}</TextButton
      >
    </template>
  </Card>
  <ModelPageBuilderDeleteModal
    v-model="deleteId"
    v-model:visible="showDeleteModal"
    :deleteItem
    :modelTexts="{
      title: texts.models.contactDetails.notes.title,
      plural: texts.models.contactDetails.notes.plural,
    }"
  />
</template>

<script setup lang="ts">
import TextButton from "@/components/common/button/TextButton.vue";
import Card from "@/components/common/card/Card.vue";
import CardHeader from "@/components/common/card/CardHeader.vue";
import { Prospect } from "@/models/contact";
import { Color } from "@/enums";
import Icon from "@/components/common/icon/Icon.vue";
import { testIds } from "@/utils/testing";
import FormFieldWysiwyg from "@/components/common/wysiwyg/FormFieldWysiwyg.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import { ButtonSize } from "@/components/common/button/Button.types";
import { ref } from "vue";
import { DateTime } from "luxon";
import Divider from "@/components/common/divider/Divider.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import { useForm } from "vee-validate";
import * as yup from "yup";
import ModelPageBuilderDeleteModal from "@/components/model-page-builder/ModelPageBuilderDeleteModal.vue";
import { DeleteItemProps } from "@/components/model-page-builder/ModelPageBuilder.vue";
import { hasPermissions } from "@/router/helpers/userCanGoTo";
import { Permission } from "@/lib/eduConfigurationServiceClient";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { NoteDTO } from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";
import { interpolate } from "@/dictionary";
import { useAsyncState } from "@vueuse/core";
import Notify from "@/utils/notify";
const props = defineProps<{
  prospect: Prospect;
}>();

export type ContactNoteFormValues = {
  note: string;
};

const form = useForm<ContactNoteFormValues>({
  validationSchema: yup.object({
    note: yup.string().required(),
  }),
});

const submit = form.handleSubmit(async (values) => {
  if (editNoteDTO.value) {
    try {
      await eduConfigurationServiceClient.updateProspectNote(
        props.prospect.id,
        editNoteDTO.value.id,
        values.note,
      );

      Notify.success(texts.notifications.edit.success, [
        texts.models.contactDetails.notes.title,
      ]);
      editNoteDTO.value = undefined;
    } catch (error) {
      Notify.failure(texts.notifications.edit.failure, [
        texts.models.contactDetails.notes.title,
      ]);
      throw error;
    }
  } else {
    try {
      await eduConfigurationServiceClient.addProspectNote(
        props.prospect.id,
        values.note,
      );

      //await createNote(values);

      Notify.success(texts.notifications.create.success, [
        texts.models.contactDetails.notes.title,
      ]);
    } catch (error) {
      Notify.failure(texts.notifications.create.failure, [
        texts.models.contactDetails.notes.title,
      ]);
      throw error;
    }
  }
  await reloadNotes();
  showForm.value = false;
});

const showForm = ref(false);

function onClickCreate() {
  showForm.value = true;
  form.setFieldValue("note", "", false);
}

function onClickCancel() {
  showForm.value = false;
  form.setFieldValue("note", "", false);
}

const { state: notes, execute: reloadNotes } = useAsyncState(
  async () =>
    await eduConfigurationServiceClient.getProspectNotes(props.prospect.id),
  [],
  {
    immediate: true,
  },
);

const editNoteDTO = ref<NoteDTO>();
function onClickEdit(note: NoteDTO) {
  showForm.value = true;
  form.setFieldValue("note", note.value);
  editNoteDTO.value = note;
}

const showDeleteModal = ref(false);
const deleteId = ref<string>();

function onClickDelete(id: string) {
  deleteId.value = id;
  showDeleteModal.value = true;
}

const deleteItem: DeleteItemProps = {
  submit: async (id: string) => {
    await eduConfigurationServiceClient.deleteProspectNote(
      props.prospect.id,
      id,
    );

    deleteId.value = undefined;
    showDeleteModal.value = false;
    await reloadNotes();
  },
};
</script>
