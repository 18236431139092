import { EmailTemplateType } from "@/enums";
import EmailTemplate, {
  EmailTemplateCreateOrUpdateDTO,
  EmailTemplateCopyDTO,
  EmailTemplateDTO,
  EmailTemplateExtended,
  EmailTemplateExtendedDTO,
} from "@/models/emailTemplates";
import EmailTemplateTypeVariables, {
  EmailTemplateTypeVariablesDTO,
} from "@/models/emailTemplateVariables";
import HttpClient from "@/utils/httpClient";

export const getEmailTemplateTypeVariables = async (): Promise<
  Record<EmailTemplateType, EmailTemplateTypeVariables>
> => {
  return await HttpClient.educonfig
    .get<EmailTemplateTypeVariablesDTO[]>(`email-template-types`)
    .then((response) => {
      const result: Record<string, EmailTemplateTypeVariables> = {};
      for (const key of response.data) {
        result[key.type] = new EmailTemplateTypeVariables(key);
      }
      return result;
    });
};

export const getEmailTemplates = async (): Promise<EmailTemplate[]> => {
  return await HttpClient.educonfig
    .get<EmailTemplateDTO[]>(`email-templates`)
    .then((response) => response.data.map((dto) => new EmailTemplate(dto)));
};

export const getEmailTemplate = async (
  id: string,
): Promise<EmailTemplateExtended> => {
  return await HttpClient.educonfig
    .get<EmailTemplateExtendedDTO>(`email-templates/${id}`)
    .then((response) => new EmailTemplateExtended(response.data));
};

export const createEmailTemplate = async (
  body: EmailTemplateCreateOrUpdateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.post(
    `email-templates`,
    JSON.stringify(body),
  );
};

export const copyEmailTemplate = async (
  id: string,
): Promise<EmailTemplateCopyDTO> => {
  return await HttpClient.educonfig
    .post<EmailTemplateCopyDTO>(`email-templates/${id}/copy`)
    .then((response) => response.data);
};

export const updateEmailTemplate = async (
  id: string,
  body: EmailTemplateCreateOrUpdateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.put(
    `email-templates/${id}`,
    JSON.stringify(body),
  );
};
