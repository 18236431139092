import {
  CreateSessionTypeDto,
  UpdateSessionTypeDto,
} from "@/lib/eduConfigurationServiceClient";
import { Localization } from "@/models/helpers/localizable";

export type SessionTypeFormValues = {
  localizations: SessionTypeFormValuesLocalization[];
};

export type SessionTypeFormValuesLocalization = Localization<{
  name: string;
}>;
export function convertToCreateDto(values: SessionTypeFormValues) {
  return new CreateSessionTypeDto({
    localizations: values.localizations.filter((loc) => !!loc.name),
  });
}

export function convertToUpdateDto(
  values: SessionTypeFormValues,
): UpdateSessionTypeDto {
  return new UpdateSessionTypeDto({
    localizations: values.localizations.filter((loc) => !!loc.name),
  });
}
