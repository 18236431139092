<template>
  <ModelPageBuilder
    :modelTexts="{
      title: texts.models.affiliate.title,
      plural: texts.models.affiliate.plural,
    }"
    :list
    :createItem
    :editItem
  />
</template>

<script setup lang="ts">
import ModelPageBuilder from "@/components/model-page-builder/ModelPageBuilder.vue";
import texts from "@/utils/texts";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import {
  AffiliateFormValues,
  convertToCreateDto,
  convertToUpdateDto,
} from "@/views/settings/organization/affiliates/AffiliateForm.types";
import AffiliateForm from "@/views/settings/organization/affiliates/AffiliateForm.vue";
import AffiliatesListItemHeader from "@/views/settings/organization/affiliates/AffiliatesListItemHeader.vue";
import AffiliatesListItem from "@/views/settings/organization/affiliates/AffiliatesListItem.vue";

const list = {
  load: async () => {
    return eduConfigurationServiceClient.getAllAffiliates();
  },
  item: AffiliatesListItem,
  header: AffiliatesListItemHeader,
};

const createItem = {
  form: {
    component: AffiliateForm,
    submit: async (values: AffiliateFormValues) => {
      await eduConfigurationServiceClient.createAffiliate(
        convertToCreateDto(values),
      );
    },
  },
};

const editItem = {
  form: {
    load: (id: string) => eduConfigurationServiceClient.getAffiliateById(id),
    component: AffiliateForm,
    submit: (id: string, values: AffiliateFormValues) =>
      eduConfigurationServiceClient.updateAffiliate(
        id,
        convertToUpdateDto(values),
      ),
  },
};
</script>
