import { Culture } from "@/enums";
import {
  QuestionEditorAnswerOptionDTO,
  QuestionEditorAnswerOptionLocalizationDTO,
  QuestionEditorQuestionDTO,
  QuestionEditorQuestionLocalizationDTO,
} from "@/lib/formsServiceClient";
import { QuestionType } from "@/lib/formsServiceClient";
import { IconNames } from "@/components/common/icon/Icon.types";

export interface QuestionEditorFormValues {
  answers?: AnswerFormValues[];
  localizations: Record<Culture, QuestionAndAnswerLocalizableProperties>;
  type: QuestionType;
  maxNumberOfAnswers?: number;
  minNumberOfAnswers?: number;
  isSurveyQuestion: boolean;
}

export interface AnswerFormValues {
  localizations: Record<Culture, QuestionAndAnswerLocalizableProperties>;
  disabled?: boolean;
  identifier: string;
  isOtherOption?: boolean;
}
export interface QuestionAndAnswerLocalizableProperties {
  label?: string;
}

export function convertToFormValues(
  dto: QuestionEditorQuestionDTO,
  mainLanguage: Culture,
): QuestionEditorFormValues {
  const localizations = localizationArrayToObject(
    [...dto.localizations].toSorted((a) =>
      a.language === mainLanguage ? -1 : 1,
    ),
  );

  return {
    answers: dto.answers?.map((answer) => ({
      localizations: localizationArrayToObject(answer.localizations),
      identifier: answer.identifier,
      disabled: answer.disabled,
    })),
    localizations,
    type: dto.type as QuestionType,
    maxNumberOfAnswers: dto.maxNumberOfAnswers ?? undefined,
    minNumberOfAnswers: dto.minNumberOfAnswers ?? undefined,
    isSurveyQuestion: dto.isSurveyQuestion,
  };
}

export function convertToDto(
  values: QuestionEditorFormValues,
  originalDto: QuestionEditorQuestionDTO,
  mainCulture: Culture,
): QuestionEditorQuestionDTO {
  const mainLocalizationLabel = values.localizations[mainCulture].label;
  if (!mainLocalizationLabel) throw new Error("Main localization is required");
  const isNewQuestion = originalDto.identifier.startsWith("-");
  const newAnswers = () => {
    if (!isQuestionWithAnswers(values.type) && !values.answers?.length) {
      return undefined;
    }
    const answers = values.answers ?? undefined;
    if (!answers) throw new Error("Answers are required");

    return answers.map((answer, i) => {
      return newAnswersObject(
        answer,
        i,
        originalDto,
        mainCulture,
        isNewQuestion,
      );
    });
  };
  return new QuestionEditorQuestionDTO({
    identifier: originalDto.identifier,
    type: values.type ?? originalDto.type,
    destination: isNewQuestion
      ? mainLocalizationLabel
      : originalDto.destination,
    answers: newAnswers(),
    localizations: localizationObjectToArray(values.localizations).map(
      (l) => new QuestionEditorQuestionLocalizationDTO(l),
    ),
    maxNumberOfAnswers: values.maxNumberOfAnswers,
    minNumberOfAnswers: values.minNumberOfAnswers,
    isSurveyQuestion: values.isSurveyQuestion,
  });
}

export function leadingIconForQuestionType(type: QuestionType): IconNames {
  switch (type) {
    case QuestionType.CheckBox:
      return "check_box";
    case QuestionType.RadioButton:
      return "radio_button_checked";
    case QuestionType.DropDown:
      return "arrow_drop_down_circle";
    case QuestionType.Info:
      return "subject";
    case QuestionType.Rating:
      return "star";
    case QuestionType.Text:
      return "notes";
    case QuestionType.Date:
      return "event";
    case QuestionType.DateOfBirth:
      return "event";
    case QuestionType.Email:
      return "mail";
    case QuestionType.Phone:
      return "call";
    case QuestionType.Name:
      return "notes";
    case QuestionType.StudyProgramInterest:
    case QuestionType.Brochure:
    case QuestionType.Session:
    case QuestionType.ApplicationStudyProgram:
    case QuestionType.TrialDayStudyProgram:
      return "calendar_view_day";
    case QuestionType.PreEducationSchool:
      return "search";
    case QuestionType.PreEducationLevel:
      return "arrow_drop_down_circle";
  }
}

export function isQuestionWithAnswers(questionType: QuestionType) {
  return (
    questionType === QuestionType.RadioButton ||
    questionType === QuestionType.CheckBox ||
    questionType === QuestionType.DropDown
  );
}

export function localizationArrayToObject(
  localizations: { language: string; label?: string }[],
) {
  return localizations.reduce(
    (cur, localization) => {
      const key = localization.language as Culture;
      cur[key] = { label: localization.label };
      return cur;
    },
    {} as Record<Culture, { label?: string }>,
  );
}

function localizationObjectToArray(
  localizations: Record<Culture, { label?: string }>,
) {
  return Object.keys(localizations).map((key) => ({
    language: key,
    label: localizations[key as Culture].label,
  }));
}

const newAnswersObject = (
  answer: AnswerFormValues,
  i: number,
  originalDto: QuestionEditorQuestionDTO,
  mainCulture: Culture,
  isNewQuestion: boolean,
) => {
  const mainLocalization = answer.localizations[mainCulture]?.label;
  if (!mainLocalization)
    throw new Error("Main answer localization is required");
  return new QuestionEditorAnswerOptionDTO({
    key:
      originalDto.answers?.find((a) => a.identifier === answer.identifier)
        ?.key ?? mainLocalization,
    sequenceorder: i,
    isOtherOption:
      originalDto.answers?.find((a) => a.identifier === answer.identifier)
        ?.isOtherOption ??
      answer.isOtherOption ??
      false,
    localizations: localizationObjectToArray(answer.localizations).map(
      (l) => new QuestionEditorAnswerOptionLocalizationDTO(l),
    ),
    identifier: isNewQuestion ? "-1" : answer.identifier,
    disabled: answer.disabled,
  });
};

// This is a temporary workaround in absence of discerning between editable and not-editable questions
// Destinations are magic strings and should not be used to determine if a question is editable or not
// Solution: https://summit-dev.atlassian.net/browse/EDU-6551
export function isPreEducationLevelQuestion(dto: QuestionEditorQuestionDTO) {
  return dto.destination === "PreEducationLevel";
}
