<template>
  <Page :loading="loading" :error="error" class="flex flex-col gap-8">
    <template v-if="!!contact">
      <ContactHeader
        :prospect="contact"
        :contactMoments="contactMoments"
        @anonymized="loadContact"
        @added:enrollment="loadContactMomentList"
      />
      <div class="grid gap-6 xl:grid-cols-2 xl:items-start">
        <div class="flex flex-col gap-6">
          <ContactDetails
            :contact="contact"
            @editProfile="editSlideOverOpen = true"
          />
          <ContactNotes :prospect="contact" />
        </div>

        <Loader v-if="loadingContactMoments"></Loader>
        <ContactJourney
          v-else-if="contactMoments"
          :contactMoments="contactMoments"
          @view:details="handleViewDetails"
        ></ContactJourney>
      </div>
    </template>

    <ContactEdit
      v-if="contact && editSlideOverOpen"
      v-model:editProfile="editSlideOverOpen"
      :contact="contact"
      @update:editProfile="loadContact"
    />

    <ContactMomentDetails
      v-if="currentContactMoment"
      v-model:viewingDetails="viewingDetails"
      :contactMoment="currentContactMoment"
      @deleted:enrollment="loadContactMomentList"
      @deleted:application="loadContactMomentList"
      @add:visit="openAddVisitModal"
    />
  </Page>

  <Modal
    v-model:visible="addVisitModalVisible"
    :title="componentTexts.addVisit.title"
    :description="componentTexts.addVisit.subtitle"
    :type="ModalType.success"
  >
    <template v-slot:content>
      <ContactMomentAddVisitForm
        v-if="activity"
        :activity="activity"
        @submit="submitAddVisit"
        @cancel="addVisitModalVisible = false"
      >
        <ButtonSubmit :loading="addingVisit" :size="ButtonSize.sm" />
        <ButtonCancel :size="ButtonSize.sm" @click="cancelAddVisit" />
      </ContactMomentAddVisitForm>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ref } from "vue";
import Page from "@/components/common/page/Page.vue";
import { getContact } from "@/services/contact.service";
import { Prospect } from "@/models/contact";
import logger from "@/plugins/logger";
import ContactDetails from "@/views/contacts/components/ContactDetails.vue";
import ContactJourney from "@/views/contacts/components/ContactJourney.vue";
import ContactEdit from "@/views/contacts/components/ContactEdit.vue";
import ContactMomentDetails from "@/views/contacts/components/ContactMomentDetails.vue";
import { ContactMoment } from "@/models/contact-moment";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import {
  ActivityDTO,
  HelpdeskCreateVisitDto,
} from "@/lib/eduConfigurationServiceClient";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import texts from "@/utils/texts";
import Modal from "@/components/common/modal/Modal.vue";
import ContactMomentAddVisitForm from "@/views/contacts/components/ContactMomentAddVisitForm.vue";
import { ContactMomentAddVisitFormValues } from "@/views/contacts/components/ContactMomentAddVisitForm.types";
import Notify from "@/utils/notify";
import { ModalType } from "@/components/common/modal/Modal.types";
import { ButtonSize } from "@/components/common/button/Button.types";
import Loader from "@/components/common/loader/Loader.vue";
import ContactHeader from "@/views/contacts/components/ContactHeader.vue";
import ContactNotes from "@/views/contacts/components/ContactNotes.vue";

const componentTexts = texts.navigationItems.contact.contactMoments;

const props = defineProps<{
  id: string;
}>();

const loading = ref<boolean>(true);
const error = ref<boolean>(false);
const contact = ref<Prospect | undefined>(undefined);

const editSlideOverOpen = ref<boolean>(false);

const loadContact = () => {
  getContact(props.id)
    .then((dto) => {
      contact.value = new Prospect(dto);
    })
    .catch((e) => {
      error.value = true;
      logger.error(e);
    });
};

const loadingContactMoments = ref<boolean>(true);
const errorLoadingContactMoments = ref<boolean>(false);
const contactMoments = ref<ContactMoment[]>();
const loadContactMomentList = () => {
  eduConfigurationServiceClient
    .getProspectContactMoments(props.id)
    .then((dtos) => {
      contactMoments.value = dtos.map((dto) => new ContactMoment(dto));
    })
    .catch((e) => {
      logger.error(e);

      errorLoadingContactMoments.value = true;
    })
    .finally(() => {
      loadingContactMoments.value = false;
    });
};

initialize();
function initialize() {
  loading.value = true;
  Promise.all([loadContact(), loadContactMomentList()]).finally(() => {
    loading.value = false;
  });
}

const currentContactMoment = ref<ContactMoment>();
const viewingDetails = ref(false);
const handleViewDetails = (contactMoment: ContactMoment) => {
  currentContactMoment.value = contactMoment;
  viewingDetails.value = true;
};

const addVisitModalVisible = ref(false);
const activity = ref<ActivityDTO>();
const addVisitParams = ref<{
  activityId: string;
  contactMomentId: string;
}>();

const openAddVisitModal = async (
  contactMomentId: string,
  activityId: string,
) => {
  addVisitParams.value = {
    activityId,
    contactMomentId,
  };

  activity.value =
    await eduConfigurationServiceClient.getActivityById(activityId);
  addVisitModalVisible.value = true;
};

const addingVisit = ref(false);
const submitAddVisit = async (values: ContactMomentAddVisitFormValues) => {
  if (!contact.value) {
    throw new Error("Contact not found");
  }

  if (!addVisitParams.value) {
    throw new Error("Params not found");
  }

  try {
    addingVisit.value = true;
    await eduConfigurationServiceClient.addProspectVisit(
      contact.value.id,
      new HelpdeskCreateVisitDto({
        activityId: addVisitParams.value.activityId,
        visitDateTime: values.visitDateTime,
        locationId: values.activityLocationId,
        registrationId: addVisitParams.value.contactMomentId,
      }),
    );
    Notify.success(texts.notifications.create.success, [
      texts.models.activity.visit,
    ]);
    loadContactMomentList();
    addVisitModalVisible.value = false;
  } catch (e) {
    Notify.failure(texts.notifications.create.failure, [
      texts.models.activity.visit,
    ]);
    throw e;
  } finally {
    addingVisit.value = false;
  }
};

const cancelAddVisit = () => {
  addVisitModalVisible.value = false;
  addVisitParams.value = undefined;
};
</script>
