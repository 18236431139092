import {
  SelectionCriterionDtoType,
  SelectionCriterionType,
} from "@/lib/eduConfigurationServiceClient";

export enum SelectionFilterType {
  StudyProgram = "StudyProgram",
  DateRange = "DateRange",
}

// Private mapping of criterion types to their supported filters
const criterionSupportedFilters: Partial<
  Record<SelectionCriterionType, SelectionFilterType[]>
> = {
  [SelectionCriterionType.Unknown]: [],
  [SelectionCriterionType.ShowedInterest]: [
    SelectionFilterType.StudyProgram,
    SelectionFilterType.DateRange,
  ],
  [SelectionCriterionType.IsRegisteredForActivity]: [
    SelectionFilterType.StudyProgram,
  ],
  [SelectionCriterionType.ThirdPartyProspectSource]: [
    SelectionFilterType.StudyProgram,
    SelectionFilterType.DateRange,
  ],
  [SelectionCriterionType.KeepMeInformed]: [
    SelectionFilterType.StudyProgram,
    SelectionFilterType.DateRange,
  ],
  [SelectionCriterionType.IsInterestedInStudyProgram]: [
    SelectionFilterType.DateRange,
  ],
  [SelectionCriterionType.HasRequestedBrochure]: [
    SelectionFilterType.StudyProgram,
    SelectionFilterType.DateRange,
  ],
  [SelectionCriterionType.IsRegisteredForTrialDay]: [
    SelectionFilterType.StudyProgram,
    SelectionFilterType.DateRange,
  ],
  [SelectionCriterionType.IsInterestedInActivityType]: [
    SelectionFilterType.DateRange,
  ],
} as const;

/**
 * Checks if a selection criterion supports a specific filter type
 */
export function doesCriterionSupportFilter(
  criterionType: SelectionCriterionType | SelectionCriterionDtoType,
  filterType: SelectionFilterType,
): boolean {
  return (
    criterionSupportedFilters[criterionType]?.includes(filterType) ?? false
  );
}
