<template>
  <SlideOver
    v-model:visible="visible"
    :title="interpolate(texts.actions.editItem, modelTexts.title)"
    :size="editItemForm.options?.slideOverSize"
  >
    <Error v-if="error" />
    <Loader v-else-if="dtoLoading" />
    <component
      :is="editItemForm.component"
      v-else-if="dto"
      :item="dto"
      @submit="onSubmitEdit"
    >
      <ButtonSubmit :loading="submitLoading" />
      <ButtonCancel @click="reset" />
      <ButtonDelete v-if="deleteItem" @click="onClickDelete" />
    </component>
  </SlideOver>
</template>

<script setup lang="ts" generic="IEditDTO, IEditFormValues">
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ButtonDelete from "@/components/common/button/ButtonDelete.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import Error from "@/components/common/error/Error.vue";
import Loader from "@/components/common/loader/Loader.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import {
  DeleteItemProps,
  EditItemFormProps,
  ModelTextProps,
} from "@/components/model-page-builder/ModelPageBuilder.vue";
import { interpolate } from "@/dictionary";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import { useAsyncState } from "@vueuse/core";
import { ref, watch } from "vue";

const props = defineProps<{
  modelTexts: ModelTextProps;
  editItemForm: EditItemFormProps<IEditDTO, IEditFormValues>;
  deleteItem?: DeleteItemProps;
}>();

const emit = defineEmits<{
  edited: [];
  delete: [id: string];
}>();

const id = defineModel<string>();

const visible = defineModel<boolean>("visible", {
  required: true,
  default: false,
});

const {
  state: dto,
  isLoading: dtoLoading,
  error,
  execute: load,
} = useAsyncState((id: string) => props.editItemForm.load(id), null, {
  immediate: false,
});

watch(
  id,
  async (id) => {
    if (!id) {
      dto.value = null;
      return;
    }
    await load(0, id);
  },
  { immediate: true },
);

function onClickDelete() {
  if (!id.value) {
    throw new Error("Id is not defined");
  }
  emit("delete", id.value);
}

const submitLoading = ref(false);

async function onSubmitEdit(values: IEditFormValues) {
  try {
    if (!dto.value) {
      throw new Error("EditDTO is not defined");
    }
    if (!id.value) {
      throw new Error("Id is not defined");
    }
    submitLoading.value = true;
    await props.editItemForm.submit(id.value, values);
    Notify.success(texts.notifications.edit.success, [props.modelTexts.title]);
    reset();
    emit("edited");
  } catch (e) {
    Notify.failure(texts.notifications.edit.failure, [props.modelTexts.title]);
    throw e;
  } finally {
    submitLoading.value = false;
  }
}

function reset() {
  id.value = undefined;
  dto.value = null;
  visible.value = false;
}
</script>
