<template>
  <ModelPageBuilder
    :modelTexts="{
      title: texts.models.activityType.title,
      plural: texts.models.activityType.plural,
    }"
    :list
    :createItem
    :editItem
    :deleteItem
  />
</template>

<script setup lang="ts">
import ModelPageBuilder from "@/components/model-page-builder/ModelPageBuilder.vue";
import settings, { loadActivityTypes } from "@/store/context/settings.context";
import texts from "@/utils/texts";
import ActivityTypeForm from "@/views/settings/organization/activity-types/ActivityTypeForm.vue";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import {
  CreateActivityTypeDTO,
  UpdateActivityTypeDTO,
} from "@/lib/eduConfigurationServiceClient";
import { ActivityTypeFormValues } from "@/views/settings/organization/activity-types/ActivityTypeForm.types";

const list = {
  load: async () => {
    await loadActivityTypes();
    return settings.activityTypes;
  },
};

const createItem = {
  form: {
    component: ActivityTypeForm,
    submit: async (values: ActivityTypeFormValues) => {
      await eduConfigurationServiceClient.createActivityType(
        new CreateActivityTypeDTO({
          localizations: values.localizations.filter((loc) => !!loc.name),
        }),
      );
    },
  },
};

const editItem = {
  form: {
    load: (id: string) => eduConfigurationServiceClient.getActivityTypeById(id),
    component: ActivityTypeForm,
    submit: (id: string, values: ActivityTypeFormValues) =>
      eduConfigurationServiceClient.updateActivityType(
        id,
        new UpdateActivityTypeDTO({
          id,
          localizations: values.localizations.filter((loc) => !!loc.name),
        }),
      ),
  },
};

const deleteItem = {
  submit: (id: string) => eduConfigurationServiceClient.deleteActivityType(id),
};
</script>
