import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import { Component, shallowRef } from "vue";
import {
  NavigationGuard,
  NavigationGuardNext,
  RouteLocationNormalized,
} from "vue-router";

export const layout = shallowRef<Component>(DefaultLayout);

const layoutGuard: NavigationGuard = async (
  to: RouteLocationNormalized,
  _: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (to.meta.layout) {
    layout.value = to.meta.layout;
  } else {
    layout.value = DefaultLayout;
  }
  next();
};

export default layoutGuard;
