<template>
  <ListItem :clickable="isEditable" @click="emit('edit', item)">
    <ListItemColumn>{{ item.name ?? item.id }}</ListItemColumn>
    <ListItemColumn v-if="showActions" :flex="0">
      <ListItemActions @click.stop>
        <DropdownItem v-if="isEditable" @click="emit('edit', item)">
          <Icon icon="edit" :color="Color.DeepTeal"></Icon>
          <span class="text-deepteal-500">{{ texts.actions.edit }}</span>
        </DropdownItem>
        <Divider class="mx-2 my-1" />
        <DropdownItem v-if="isDeletable" @click="emit('delete', item.id)">
          <Icon icon="delete" :color="Color.Alert"></Icon>
          <span class="text-alert-500">{{ texts.actions.delete }}</span>
        </DropdownItem>
      </ListItemActions>
    </ListItemColumn>
  </ListItem>
</template>

<script setup lang="ts" generic="T extends { id: string; name?: string }">
import ListItem from "@/components/common/list/ListItem.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import ListItemActions from "@/components/common/list/ListItemActions.vue";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import Icon from "@/components/common/icon/Icon.vue";
import { Color } from "@/enums";
import Divider from "@/components/common/divider/Divider.vue";
import texts from "@/utils/texts";
import { computed } from "vue";

const props = defineProps<{
  item: T;
  isEditable?: boolean;
  isDeletable?: boolean;
}>();

const emit = defineEmits<{
  edit: [T];
  delete: [string];
}>();

// show actions icon only if isEditable is not the only action present
const showActions = computed(() => {
  return props.isDeletable;
});
</script>
