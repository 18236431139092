<template>
  <FormLayout @submit="submit">
    <h2 class="text-xl font-semibold text-deepteal-700">
      {{ componentTexts.basic }}
    </h2>
    <FormFieldToggle
      :id="fieldIds.isHardCopy"
      :label="componentTexts.isHardCopy"
      :displayMode="FormFieldDisplayMode.Column"
    />
    <FormFieldTextInput
      :id="fieldIds.name"
      :label="componentTexts.name"
      :displayMode="FormFieldDisplayMode.Column"
      :data-testid="fieldIds.name"
    />
    <FormFieldSelect
      :id="fieldIds.formId"
      :label="componentTexts.form"
      :items="formTemplateSelectOptions"
      :displayMode="FormFieldDisplayMode.Column"
      allowUnset
    />
    <RouterLinkAuth
      v-if="formValues?.formId"
      :to="{
        name: RouteNamesSettings.FORMS_EDIT_TEMPLATE,
        params: { id: formValues.formId },
      }"
      class="-mt-4 text-sm text-emerald-500 underline hover:text-emerald-700"
    >
      {{ componentTexts.editForm }}
    </RouterLinkAuth>
    <FormFieldSelect
      :id="fieldIds.confirmationEmailTemplateId"
      :label="componentTexts.confirmationEmailTemplate"
      :items="emailTemplateSelectOptions"
      :displayMode="FormFieldDisplayMode.Column"
      allowUnset
    />
    <RouterLinkAuth
      v-if="formValues?.confirmationEmailTemplateId"
      :to="{
        name: RouteNamesSettings.EMAIL_EDIT_EMAIL_TEMPLATE,
        params: { id: formValues.confirmationEmailTemplateId },
      }"
      class="-mt-4 text-sm text-emerald-500 underline hover:text-emerald-700"
    >
      {{ componentTexts.editEmailTemplate }}
    </RouterLinkAuth>

    <h2 class="-mb-4 text-xl font-semibold text-deepteal-700">
      {{ componentTexts.brochures }}
    </h2>
    <span class="text-sm text-deepteal-800">
      {{ componentTexts.brochuresDescription }}
    </span>
    <ComboboxField
      :loading="loadingBrochures"
      :formFieldId="fieldIds.brochureIds"
      :displayMode="FormFieldDisplayMode.Column"
      :options="brochureSelectOptions"
      :formatOptionDisplayString="brochuresOptionDisplayFormatter"
      :searchFilter="brochuresSearchFilter"
    />

    <template v-if="form.values.isHardCopy">
      <h2 class="text-xl font-semibold text-deepteal-700">
        {{ componentTexts.extra }}
      </h2>
      <FormFieldTextInput
        :id="fieldIds.mailRoomEmailAddress"
        :label="componentTexts.mailRoomEmailAddress"
        :displayMode="FormFieldDisplayMode.Column"
        :data-testid="fieldIds.mailRoomEmailAddress"
      />
      <FormFieldSelect
        :id="fieldIds.mailRoomEmailTemplateId"
        :label="componentTexts.mailRoomEmailTemplate"
        :items="mailRoomEmailTemplateSelectOptions"
        :displayMode="FormFieldDisplayMode.Column"
        allowUnset
      />
      <RouterLinkAuth
        v-if="formValues?.mailRoomEmailTemplateId"
        :to="{
          name: RouteNamesSettings.EMAIL_EDIT_EMAIL_TEMPLATE,
          params: { id: formValues.mailRoomEmailTemplateId },
        }"
        class="-mt-4 text-sm text-emerald-500 underline hover:text-emerald-700"
      >
        {{ componentTexts.editEmailTemplate }}
      </RouterLinkAuth>
    </template>
    <template v-slot:actions>
      <slot></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import ComboboxField from "@/components/common/combobox/ComboboxField.vue";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import FormLayout from "@/components/common/form/FormLayout.vue";
import FormFieldSelect from "@/components/common/select/FormFieldSelect.vue";
import { SelectOption } from "@/components/common/select/SelectOption";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import FormFieldToggle from "@/components/common/toggle/FormFieldToggle.vue";
import RouterLinkAuth from "@/components/router/router-link-auth/RouterLinkAuth.vue";
import { EmailTemplateType, FormType } from "@/enums";
import { BrochureOverviewDto } from "@/lib/eduConfigurationServiceClient";
import { FormTemplate } from "@/models/template";
import logger from "@/plugins/logger";
import { RouteNamesSettings } from "@/router/routeNames";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { formsServiceClient } from "@/services/formsService.client.service";
import settings from "@/store/context/settings.context";
import { fields } from "@/utils/miscellaneous";
import texts from "@/utils/texts";
import { DateTime } from "luxon";
import { useForm } from "vee-validate";
import { computed, ref, watch } from "vue";
import * as yup from "yup";
import { BrochureConfigurationFormValues } from "./BrochureConfigurationForm.types";

const componentTexts = texts.navigationItems.brochures.settings;

const props = defineProps<{
  formValues?: BrochureConfigurationFormValues;
  selectedFormIds?: string[];
}>();

const emit = defineEmits<{
  (e: "submit", values: BrochureConfigurationFormValues): void;
}>();

const form = useForm<BrochureConfigurationFormValues>({
  initialValues: props.formValues,
  validationSchema: yup.object({
    name: yup.string().required(),
    formId: yup.string().required(),
    confirmationEmailTemplateId: yup.string(),
    isHardCopy: yup.boolean().required(),
    mailRoomEmailAddress: yup.string().email(),
    mailRoomEmailTemplateId: yup.string(),
    brochureIds: yup.array().of(yup.string().required()).required(),
  }),
});

const fieldIds = fields<BrochureConfigurationFormValues>();

const submit = form.handleSubmit((values) => emit("submit", values));

const createSelectOptions = (isHardCopy: boolean) => {
  if (isHardCopy) {
    formTemplateSelectOptions.value = hardCopyFormTemplateSelectOptions.value;
    emailTemplateSelectOptions.value = hardCopyEmailTemplateSelectOptions.value;
    brochureSelectOptions.value = brochures.value.filter(
      (brochure) => brochure.isHardCopy,
    );
  } else {
    formTemplateSelectOptions.value = digitalFormTemplateSelectOptions.value;
    emailTemplateSelectOptions.value = digitalEmailTemplateSelectOptions.value;
    brochureSelectOptions.value = brochures.value;
  }
};

// Fetch email-templates
const emailTemplateSelectOptions = ref<SelectOption[]>([]);
const digitalEmailTemplateSelectOptions = computed<SelectOption[]>(() =>
  settings.emailTemplates
    .filter((template) => template.type == EmailTemplateType.BrochureRequest)
    .map((et) => ({
      label: et.name,
      value: et.id,
    })),
);
const hardCopyEmailTemplateSelectOptions = computed<SelectOption[]>(() =>
  settings.emailTemplates
    .filter(
      (template) => template.type == EmailTemplateType.BrochureHardCopyRequest,
    )
    .map((et) => ({
      label: et.name,
      value: et.id,
    })),
);
const mailRoomEmailTemplateSelectOptions = computed<SelectOption[]>(() =>
  settings.emailTemplates
    .filter((template) => template.type == EmailTemplateType.BrochureMailRoom)
    .map((et) => ({
      label: et.name,
      value: et.id,
    })),
);

// Fetch form templates
const loadingFormTemplates = ref(true);
const errorLoadingFormTemplates = ref(false);
const digitalFormTemplates = ref<FormTemplate[]>([]);
const hardCopyFormTemplates = ref<FormTemplate[]>([]);
const formTemplateSelectOptions = ref<SelectOption[]>([]);
const digitalFormTemplateSelectOptions = computed<SelectOption[]>(() =>
  digitalFormTemplates.value
    .filter((t) => !props.selectedFormIds?.includes(t.id))
    .map((t) => ({
      label: t.name,
      value: t.id,
    })),
);
const hardCopyFormTemplateSelectOptions = computed<SelectOption[]>(() =>
  hardCopyFormTemplates.value
    .filter((t) => !props.selectedFormIds?.includes(t.id))
    .map((t) => ({
      label: t.name,
      value: t.id,
    })),
);

Promise.all([
  formsServiceClient.getForms(FormType.DigitalBrochureRequest),
  formsServiceClient.getForms(FormType.HardCopyBrochureRequest),
])
  .then(([digitalForms, hardCopyForms]) => {
    digitalFormTemplates.value = digitalForms.map((r) =>
      FormTemplate.fromDto(r),
    );
    hardCopyFormTemplates.value = hardCopyForms.map((r) =>
      FormTemplate.fromDto(r),
    );

    createSelectOptions(props.formValues?.isHardCopy ?? false);
  })
  .catch((e) => {
    errorLoadingFormTemplates.value = true;
    logger.error(e);
  })
  .finally(() => (loadingFormTemplates.value = false));

// Brochures data
const loadingBrochures = ref(true);
const errorLoadingBrochures = ref(false);
const brochures = ref<BrochureOverviewDto[]>([]);
const brochureSelectOptions = ref<BrochureOverviewDto[]>([]);
const brochuresSearchFilter = (brochure: BrochureOverviewDto, query: string) =>
  brochure.name.toLowerCase().includes(query.toLowerCase());
const brochuresOptionDisplayFormatter = (brochure: BrochureOverviewDto) =>
  brochure.name;
eduConfigurationServiceClient
  .getBrochuresForOverview(DateTime.now(), DateTime.now()) // This endpoint returns localised brochures but we don't need the stats, hence dates are irrelevant
  .then((res) => {
    brochures.value = res;
    brochureSelectOptions.value = res;
  })
  .catch((e) => {
    errorLoadingBrochures.value = true;
    logger.log(e);
  })
  .finally(() => (loadingBrochures.value = false));

watch(
  () => form.values.isHardCopy,
  (value) => createSelectOptions(value),
  { immediate: true },
);
</script>
