<template>
  <div class="flex flex-col gap-4">
    <FormField
      id="period"
      v-slot="{ value }"
      :label="texts.models.mailingConfiguration.scheduling"
    >
      <span :class="['inline-flex rounded-md shadow-sm']">
        <Button
          v-for="option in schedulingOptions"
          :key="option.value"
          :color="value.value === option.value ? Color.DeepTeal : Color.White"
          :size="ButtonSize.sm"
          leadingIcon="event_upcoming"
          grouped
          :disabled="option.disabled"
          @click="value.value = option.value"
        >
          {{ option.label }}
        </Button>
      </span>
    </FormField>
    <div class="flex items-start gap-2">
      <FormFieldTextInput
        id="relativePlanningDayOffset"
        :type="TextInputType.NUMBER"
        :label="
          schedulingOptions.find((o) => o.value === form.values.period)?.label
        "
        :placeholder="
          schedulingOptions.find((o) => o.value === form.values.period)?.label
        "
        :min="0"
        class="flex-1"
      />
      <FormFieldTime
        id="relativePlanningTimeOfDay"
        :label="texts.models.mailingConfiguration.schedulingTime.title"
        :placeholder="texts.models.mailingConfiguration.schedulingTime.title"
        class="flex-1"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ButtonSize } from "@/components/common/button/Button.types";
import Button from "@/components/common/button/Button.vue";
import FormField from "@/components/common/form/FormField.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import FormFieldTime from "@/components/common/time/FormFieldTime.vue";
import { interpolate } from "@/dictionary";
import { Color, MailingType } from "@/enums";
import texts from "@/utils/texts";
import {
  MailingConfigurationFormSchedulingPeriod,
  MailingConfigurationFormValues,
} from "@/views/settings/activity-mailings-configurations/mailing-configuration/mailing-configuration-form/MailingConfigurationForm.vue";
import { Duration } from "luxon";
import { FormContext } from "vee-validate";

const form = defineModel<FormContext<MailingConfigurationFormValues>>("form", {
  required: true,
});

const schedulingOptions: {
  label: string;
  value: MailingConfigurationFormSchedulingPeriod;
  disabled?: boolean;
}[] = [
  {
    label: interpolate(
      texts.models.mailingConfiguration.schedulingPeriod.relativeBefore,
      "..",
    ),
    value: "relativeBefore",
    disabled: form.value.values.type === MailingType.ActivityVisitedSurvey,
  },
  {
    label: interpolate(
      texts.models.mailingConfiguration.schedulingPeriod.relativeAfter,
      "..",
    ),
    value: "relativeAfter",
    disabled: form.value.values.type !== MailingType.ActivityVisitedSurvey,
  },
];

if (!form.value.values.period) {
  form.value.setValues(
    {
      period:
        schedulingOptions.find((o) => !o.disabled)?.value ?? "relativeBefore",
      relativePlanningDayOffset: 0,
      relativePlanningTimeOfDay: Duration.fromObject({ hour: 12, minute: 0 }),
    },
    false,
  );
}
</script>
