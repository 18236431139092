<template>
  <ModelPageBuilder
    :modelTexts="{
      title: texts.models.targetAudience.title,
      plural: texts.models.targetAudience.plural,
    }"
    :list
    :createItem
    :editItem
    :deleteItem
  />
</template>

<script setup lang="ts">
import ModelPageBuilder from "@/components/model-page-builder/ModelPageBuilder.vue";
import settings, {
  loadTargetAudiences,
} from "@/store/context/settings.context";
import texts from "@/utils/texts";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import TargetAudienceForm from "@/views/settings/organization/target-audiences/TargetAudienceForm.vue";
import {
  convertToCreateDto,
  convertToUpdateDto,
  TargetAudienceSubmitValues,
} from "@/views/settings/organization/target-audiences/TargetAudienceForm.types";
import { Size } from "@/enums";

const list = {
  load: async () => {
    await loadTargetAudiences();
    return settings.targetAudiences;
  },
};

const createItem = {
  form: {
    component: TargetAudienceForm,
    submit: async (values: TargetAudienceSubmitValues) => {
      await eduConfigurationServiceClient.createTargetAudience(
        convertToCreateDto(values),
      );
    },
    options: {
      slideOverSize: "xl" as Size,
    },
  },
};

const editItem = {
  form: {
    load: (id: string) =>
      eduConfigurationServiceClient.getTargetAudienceById(id),
    component: TargetAudienceForm,
    submit: (id: string, values: TargetAudienceSubmitValues) =>
      eduConfigurationServiceClient.updateTargetAudience(
        id,
        convertToUpdateDto(id, values),
      ),
    options: {
      slideOverSize: "xl" as Size,
    },
  },
};

const deleteItem = {
  submit: (id: string) =>
    eduConfigurationServiceClient.deleteTargetAudience(id),
};
</script>
