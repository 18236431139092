<template>
  <div class="flex flex-col gap-4">
    <FormFieldTextInput
      id="name"
      :label="texts.models.mailingConfiguration.name"
      :placeholder="texts.models.mailingConfiguration.name"
    />
    <FormFieldSelectNative
      id="locale"
      :label="texts.models.mailingConfiguration.language"
      :placeholder="texts.models.mailingConfiguration.language"
      :options="languageOptions"
    />
  </div>
</template>

<script setup lang="ts">
import FormFieldSelectNative from "@/components/common/select/FormFieldSelectNative.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import { Culture } from "@/enums";
import settings from "@/store/context/settings.context";
import texts from "@/utils/texts";
import { MailingConfigurationFormValues } from "@/views/settings/activity-mailings-configurations/mailing-configuration/mailing-configuration-form/MailingConfigurationForm.vue";
import { FormContext } from "vee-validate";
import { computed } from "vue";

const form = defineModel<FormContext<MailingConfigurationFormValues>>("form", {
  required: true,
});

const languageOptions = computed(() =>
  settings.availableLanguages.map((language) => ({
    value: language.locale.value!,
    label: language.name,
  })),
);

if (!form.value.values.locale) {
  form.value.setFieldValue(
    "locale",
    settings.mainLanguage.locale.value as Culture,
  );
}
</script>
