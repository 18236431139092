import { Prospect } from "@/models/contact";
import { BadgeProps, BadgeType } from "@/components/common/badge/BadgeProps";
import {
  AnonymizationState,
  ContactMomentType,
} from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";
import { ContactMoment } from "@/models/contact-moment";

const detailsTexts = texts.models.contactDetails;
export function getContactBadge(
  contact?: Prospect,
  contactMoments?: ContactMoment[],
): BadgeProps | undefined {
  if (!contact) return;
  if (contact.anonymization === AnonymizationState.Anonymized) {
    return {
      type: BadgeType.warning,
      label: detailsTexts.anonymized,
      icon: "person_off",
    };
  }
  if (contact.anonymization === AnonymizationState.AnonymizationScheduled) {
    return {
      type: BadgeType.warning,
      label: detailsTexts.pendingAnonymization,
      icon: "person_off",
    };
  }
  if (!contactMoments) return;
  if (contactMoments.some((c) => c.type === ContactMomentType.Enrollment))
    return {
      type: BadgeType.emerald,
      label: detailsTexts.enrolledStudent,
      icon: "school",
    };
  if (contactMoments.some((c) => c.type === ContactMomentType.Application))
    return {
      type: BadgeType.lavender,
      label: detailsTexts.appliedForStudyProgram,
      icon: "hotel_class",
    };
}
