<template>
  <Async :state="state">
    <template v-slot:failure>
      <ErrorComponent />
    </template>
    <template v-slot:loading>
      <Loader />
    </template>
    <template v-slot>
      <div class="flex flex-col gap-6">
        <div class="flex flex-col gap-4">
          <h2 class="text-lg font-semibold text-deepteal-700">
            {{ emailTexts.title }}
          </h2>
        </div>
        <div class="flex flex-col gap-4">
          <div class="flex items-center">
            <h3 class="flex-grow text-base font-semibold text-deepteal-700">
              {{ emailTexts.fromEmailAddresses.title }}
            </h3>
            <ButtonAdd @click="fromAddressSlideOverOpen = true"></ButtonAdd>
          </div>
          <span class="text-sm font-light text-deepteal-700">
            {{ emailTexts.fromEmailAddresses.description }}
          </span>
        </div>
        <List>
          <template v-slot:header>
            <ListItemColumn>{{
              texts.models.mailingAddress.title
            }}</ListItemColumn>
            <ListItemColumn>{{
              texts.models.mailingAddress.status
            }}</ListItemColumn>
          </template>
          <ListItem
            v-for="fromAddress in fromAddresses"
            :key="fromAddress.id"
            data-testid="from-address"
          >
            <ListItemColumn data-testid="email" accent>{{
              fromAddress.emailAddress
            }}</ListItemColumn>
            <ListItemColumn
              ><Badge
                :label="
                  fromAddress.readyToUse
                    ? texts.models.emailAddress.readyToUse
                    : texts.models.emailAddress.notReadyToUse
                "
                :type="
                  fromAddress.readyToUse ? BadgeType.success : BadgeType.danger
                "
              ></Badge
            ></ListItemColumn>
          </ListItem>
        </List>
        <div class="flex flex-col gap-4">
          <div class="flex items-center">
            <h3 class="flex-grow text-base font-semibold text-deepteal-700">
              {{ emailTexts.replyToEmailAddresses.title }}
            </h3>
            <ButtonAdd @click="toAddressSlideOverOpen = true"></ButtonAdd>
          </div>
          <span class="text-sm font-light text-deepteal-700">
            {{ emailTexts.replyToEmailAddresses.description }}
          </span>
        </div>
        <List>
          <template v-slot:header>
            <ListItemColumn>{{
              texts.models.mailingAddress.title
            }}</ListItemColumn>
            <ListItemColumn></ListItemColumn>
          </template>
          <ListItem
            v-for="replyToAddress in replyToAddresses"
            :key="replyToAddress.id"
            data-testid="reply-to-address"
          >
            <ListItemColumn data-testid="email" accent>{{
              replyToAddress.emailAddress
            }}</ListItemColumn>
          </ListItem>
        </List>
      </div>
    </template>
  </Async>
  <SlideOver
    v-model:visible="fromAddressSlideOverOpen"
    :title="emailTexts.fromEmailAddresses.create.title"
    :subTitle="emailTexts.fromEmailAddresses.create.subTitle"
    data-testid="fromaddress-slide-over"
  >
    <FromAndToEmailForm
      :domainItems="domainItems"
      fromForm
      @submit="createFromAddress"
    >
      <ButtonSubmit :loading="actionLoading"></ButtonSubmit>
      <ButtonCancel @click="cancelCreateFromAddress"></ButtonCancel
    ></FromAndToEmailForm>
  </SlideOver>
  <SlideOver
    v-model:visible="toAddressSlideOverOpen"
    :title="emailTexts.replyToEmailAddresses.create.title"
    :subTitle="emailTexts.replyToEmailAddresses.create.subTitle"
    data-testid="fromaddress-slide-over"
  >
    <FromAndToEmailForm @submit="createToAddress">
      <ButtonSubmit :loading="actionLoading"></ButtonSubmit>
      <ButtonCancel @click="cancelCreateToAddress"></ButtonCancel
    ></FromAndToEmailForm>
  </SlideOver>
</template>

<script setup lang="ts">
import Badge from "@/components/common/badge/Badge.vue";
import { BadgeType } from "@/components/common/badge/BadgeProps";
import { ref } from "vue";
import {
  getFromEmailAdresses,
  getReplyToEmailAdresses,
} from "@/services/email-address";
import { FromEmailAddress, ReplyToEmailAddress } from "@/models/email-address";
import Async from "@/components/common/async/Async.vue";
import texts from "@/utils/texts";
import { useAsyncState } from "@/components/common/async/Async.types";
import ErrorComponent from "@/components/common/error/Error.vue";
import Loader from "@/components/common/loader/Loader.vue";
import ButtonAdd from "@/components/common/button/ButtonAdd.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import { FromEmailFormValues } from "./FromEmailForm.types";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import {
  CreateReplyToEmailAddressDto,
  FromEmailAddressCreateDTO,
} from "@/lib/eduConfigurationServiceClient";
import Notify from "@/utils/notify";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import List from "@/components/common/list/List.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import FromAndToEmailForm from "./FromAndToEmailForm.vue";
import { SelectOption } from "@/components/common/select/SelectOption";
import {
  loadFromEmailAddresses,
  loadReplyToEmailAddresses,
} from "@/store/context/settings.context";

const emailTexts =
  texts.navigationItems.manage.settings.organization.emailAddresses;

const fromAddressSlideOverOpen = ref<boolean>(false);
const toAddressSlideOverOpen = ref<boolean>(false);
const actionLoading = ref<boolean>(false);

const fromAddresses = ref<FromEmailAddress[] | undefined>();
const replyToAddresses = ref<ReplyToEmailAddress[] | undefined>();
const { state, handler } = useAsyncState(async () => {
  const [from, replyTo] = await Promise.all([
    getFromEmailAdresses(),
    getReplyToEmailAdresses(),
  ]);
  fromAddresses.value = from;
  replyToAddresses.value = replyTo;
});

const getDomainItems = async () => {
  const domains = await eduConfigurationServiceClient.getTenantDomainNames();
  if (!domains) {
    return undefined;
  }
  return domains.map((domain) => ({
    value: domain.domainName,
    label: "@" + domain.domainName,
  }));
};

const domainItems = ref<SelectOption[] | undefined>(undefined);
try {
  getDomainItems().then((domains) => {
    if (domains === undefined) {
      domainItems.value = undefined;
    } else {
      domainItems.value = domains.toSorted((a, b) =>
        a.label.localeCompare(b.label),
      );
    }
  });
} catch (e) {
  console.warn(e);
}

const createFromAddress = async (values: FromEmailFormValues) => {
  actionLoading.value = true;

  await eduConfigurationServiceClient
    .createFromEmailAddress(
      new FromEmailAddressCreateDTO({
        emailAddress: values.email,
      }),
    )
    .then(async () => {
      await loadFromEmailAddresses();
      Notify.success(texts.notifications.create.success, [values.email]);
    })
    .catch((e) => {
      console.warn(e);
      Notify.failure(texts.notifications.create.failure);
    })
    .finally(() => {
      fromAddressSlideOverOpen.value = false;
      handler();
      actionLoading.value = false;
    });
};

const createToAddress = async (values: FromEmailFormValues) => {
  actionLoading.value = true;

  await eduConfigurationServiceClient
    .createReplyToEmailAddress(
      new CreateReplyToEmailAddressDto({
        emailAddress: values.email,
      }),
    )
    .then(async () => {
      await loadReplyToEmailAddresses();
      Notify.success(texts.notifications.create.success, [values.email]);
    })
    .catch((e) => {
      console.warn(e);
      Notify.failure(texts.notifications.create.failure);
    })
    .finally(() => {
      toAddressSlideOverOpen.value = false;
      handler();
      actionLoading.value = false;
    });
};

const cancelCreateFromAddress = () => {
  fromAddressSlideOverOpen.value = false;
};

const cancelCreateToAddress = () => {
  toAddressSlideOverOpen.value = false;
};

handler();
</script>
