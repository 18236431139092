<template>
  <Page :error="!!error" class="flex flex-col gap-4">
    <header class="flex flex-wrap items-center justify-between gap-3">
      <div class="flex items-center gap-3">
        <h2 class="text-lg font-semibold text-deepteal-700">
          {{ modelTexts.plural }}
        </h2>
        <slot name="tag" v-bind="{ items }" />
        <Spinner v-if="isLoading" :size="IconSize.sm" />
      </div>

      <ButtonAdd
        v-if="createItem && !slots.createButton"
        :disabled="isLoading"
        @click="onClickCreate"
      />
      <slot
        v-else
        name="createButton"
        v-bind="{ items, isLoading, onClickCreate }"
      />
    </header>
    <List>
      <template v-slot:header>
        <component :is="list.header" />
      </template>
      <template v-for="item in items" :key="item.id">
        <template v-if="list.item">
          <component
            :is="list.item"
            :item="item"
            v-on="{
              edit: editItem ? () => onClickEdit(item) : undefined,
              delete: deleteItem ? () => onClickDelete(item.id) : undefined,
            }"
          />
        </template>
        <template v-else>
          <ModelPageBuilderDefaultListItem
            :item="item"
            :isEditable="!!editItem"
            :isDeletable="!!deleteItem"
            v-on="{
              edit: editItem ? () => onClickEdit(item) : undefined,
              delete: deleteItem ? () => onClickDelete(item.id) : undefined,
            }"
          />
        </template>
      </template>
    </List>
  </Page>
  <ModelPageBuilderCreateSlideOver
    v-if="createItem?.form"
    v-model:visible="createVisible"
    :modelTexts
    :createItemForm="createItem.form"
    @created="loadItems"
  />
  <ModelPageBuilderEditSlideOver
    v-if="editItem?.form"
    v-model="editId"
    v-model:visible="editVisible"
    :modelTexts
    :editItemForm="editItem.form"
    :deleteItem
    @delete="(id) => onClickDelete(id)"
    @edited="loadItems"
  />
  <ModelPageBuilderDeleteModal
    v-if="deleteItem"
    v-model="deleteId"
    v-model:visible="deleteVisible"
    :modelTexts
    :deleteItem
    @deleted="loadItems"
  />
</template>

<script
  setup
  lang="ts"
  generic="
    IDTO extends { id: string },
    ICreateFormValues,
    IEditDTO,
    IEditFormValues
  "
>
import Page from "@/components/common/page/Page.vue";
import ButtonAdd from "@/components/common/button/ButtonAdd.vue";
import { ref } from "vue";
import { useAsyncState } from "@vueuse/core";
import ModelPageBuilderDeleteModal from "@/components/model-page-builder/ModelPageBuilderDeleteModal.vue";
import { Size } from "@/enums";
import ModelPageBuilderCreateSlideOver from "@/components/model-page-builder/ModelPageBuilderCreateSlideOver.vue";
import ModelPageBuilderEditSlideOver from "@/components/model-page-builder/ModelPageBuilderEditSlideOver.vue";
import List from "@/components/common/list/List.vue";
import ModelPageBuilderDefaultListItem from "@/components/model-page-builder/ModelPageBuilderDefaultListItem.vue";
import Spinner from "@/components/common/spinner/Spinner.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import { RouteLocationRaw, useRouter } from "vue-router";

export type ModelTextProps = {
  title: string;
  plural: string;
};

export type ListProps<IDTO> = {
  load: () => Promise<IDTO[]>;
  header?: object;
  item?: object;
};

export type CreateItemProps<ICreateFormValues = undefined> = {
  route?: RouteLocationRaw;
  form?: CreateItemFormProps<ICreateFormValues>;
  modal?: CreateItemModalProps<ICreateFormValues>;
};

export type CreateItemFormProps<ICreateFormValues> = {
  component: object;
  submit: (values: ICreateFormValues) => Promise<void>;
  options?: {
    slideOverSize?: Size;
  };
};

export type CreateItemModalProps<ICreateFormValues> = {
  component: object;
  submit: (values: ICreateFormValues) => Promise<void>;
};

export type EditItemProps<IEditDTO = undefined, IEditFormValues = undefined> = {
  route?: RouteLocationRaw;
  form?: EditItemFormProps<IEditDTO, IEditFormValues>;
};

export type EditItemFormProps<IEditDTO, IEditFormValues> = {
  component: object;
  load: (id: string) => Promise<IEditDTO>;
  submit: (id: string, values: IEditFormValues) => Promise<void>;
  options?: {
    slideOverSize?: Size;
  };
};
export type DeleteItemProps = {
  submit: (id: string) => Promise<void>;
};

const props = defineProps<{
  modelTexts: ModelTextProps;
  list: ListProps<IDTO>;
  createItem?: CreateItemProps<ICreateFormValues>;
  editItem?: EditItemProps<IEditDTO, IEditFormValues>;
  deleteItem?: DeleteItemProps;
}>();

const slots = defineSlots<{
  tag: (props: { items: IDTO[] }) => void;
  createButton: (props: {
    items: IDTO[];
    isLoading: boolean;
    onClickCreate: () => void;
  }) => void;
}>();

const {
  state: items,
  isLoading,
  error,
  execute: loadItems,
} = useAsyncState(() => props.list.load(), [], {
  immediate: true,
  resetOnExecute: false,
});

// Create
const router = useRouter();

const createVisible = ref(false);

function onClickCreate() {
  if (props.createItem?.route) {
    router.push(props.createItem.route);
  } else {
    createVisible.value = true;
  }
}

// Edit
const editVisible = ref(false);
const editId = ref<string>();

async function onClickEdit(item: IDTO) {
  editId.value = item.id;
  editVisible.value = true;
}

// Delete
const deleteVisible = ref(false);
const deleteId = ref<string>();

function onClickDelete(id: string) {
  editVisible.value = false;
  deleteId.value = id;
  deleteVisible.value = true;
}
</script>
