<template>
  <ModelPageBuilder
    :modelTexts="{
      title: texts.models.activityLocation.title,
      plural: texts.models.activityLocation.plural,
    }"
    :list
    :createItem
    :editItem
    :deleteItem
  />
</template>

<script setup lang="ts">
import ModelPageBuilder from "@/components/model-page-builder/ModelPageBuilder.vue";
import settings, {
  loadActivityLocations,
} from "@/store/context/settings.context";
import texts from "@/utils/texts";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import ActivityLocationForm from "@/views/settings/organization/activity-locations/ActivityLocationForm.vue";
import {
  ActivityLocationFormValues,
  convertToCreateDto,
  convertToUpdateDto,
} from "@/views/settings/organization/activity-locations/ActivityLocationForm.types";

const list = {
  load: async () => {
    await loadActivityLocations();
    return settings.activityLocations;
  },
};

const createItem = {
  form: {
    component: ActivityLocationForm,
    submit: async (values: ActivityLocationFormValues) => {
      await eduConfigurationServiceClient.createActivityLocation(
        convertToCreateDto(values),
      );
    },
  },
};

const editItem = {
  form: {
    load: (id: string) => eduConfigurationServiceClient.getActivityLocation(id),
    component: ActivityLocationForm,
    submit: (id: string, values: ActivityLocationFormValues) =>
      eduConfigurationServiceClient.updateActivityLocation(
        id,
        convertToUpdateDto(values),
      ),
  },
};

const deleteItem = {
  submit: (id: string) =>
    eduConfigurationServiceClient.deleteActivityLocation(id),
};
</script>
