<template>
  <form @submit="submit">
    <div class="flex flex-col gap-8">
      <div class="flex flex-col gap-2">
        <ButtonBack
          :toRouteName="texts.generic.previous"
          @click="router.go(-1)"
        />
        <div class="flex items-center gap-3">
          <h1 class="text-2xl font-normal text-deepteal-600">
            {{
              item
                ? item.name
                : interpolate(
                    texts.actions.addNew,
                    texts.models.mailingConfiguration.title,
                  )
            }}
          </h1>

          <MailingTypeTag v-if="form.values.type" :type="form.values.type" />
          <ActivityTypeTag
            v-if="activityMailingsConfiguration"
            :activityTypeId="activityMailingsConfiguration.activityTypeId"
          />
        </div>
      </div>
      <MailingConfigurationFormBasicSettings v-model:form="form" />
      <Divider />
      <MailingConfigurationFormAddressing v-model:form="form" />
      <MailingConfigurationFormTemplate v-model:bee="bee" v-model:form="form" />
      <Divider />
      <MailingConfigurationFormScheduling v-model:form="form" />
      <Divider />
      <div class="flex flex-col gap-4 sm:flex-row-reverse sm:justify-end">
        <slot :disabled="bee.state.loading"></slot>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import { Culture } from "@/enums";
import { MailingType as MailingTypeEnum } from "@/lib/eduConfigurationServiceClient";
import { useBee } from "@/utils/bee/bee-instance";
import MailingConfigurationFormTemplate from "@/views/settings/activity-mailings-configurations/mailing-configuration/mailing-configuration-form/MailingConfigurationFormTemplate.vue";
import { useForm } from "vee-validate";
import { onMounted, ref } from "vue";
import * as yup from "yup";
import MailingConfigurationFormBasicSettings from "@/views/settings/activity-mailings-configurations/mailing-configuration/mailing-configuration-form/MailingConfigurationFormBasicSettings.vue";
import MailingConfigurationFormScheduling from "@/views/settings/activity-mailings-configurations/mailing-configuration/mailing-configuration-form/MailingConfigurationFormScheduling.vue";
import { MailingType } from "@/models/mailingType";
import texts from "@/utils/texts";
import settings from "@/store/context/settings.context";
import { Duration } from "luxon";
import ButtonBack from "@/components/common/button/ButtonBack.vue";
import { useRouter } from "vue-router";
import MailingConfigurationFormAddressing from "@/views/settings/activity-mailings-configurations/mailing-configuration/mailing-configuration-form/MailingConfigurationFormAddressing.vue";
import Divider from "@/components/common/divider/Divider.vue";
import { interpolate } from "@/dictionary";
import { MailingConfigurationDto } from "@/lib/eduConfigurationServiceClient";
import { IEntityContentJson } from "@beefree.io/sdk/dist/types/bee";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import ActivityTypeTag from "@/views/settings/activity-mailings-configurations/mailing-configuration/components/ActivityTypeTag.vue";
import { useAsyncState } from "@vueuse/core";
import MailingTypeTag from "@/views/settings/activity-mailings-configurations/mailing-configuration/components/MailingTypeTag.vue";

export type MailingConfigurationFormValues = {
  name: string;
  type: MailingTypeEnum;
  locale: string;
  subject: string;
  fromName: string;
  fromEmailAddress: string;
  replyToEmailAddress: string;
  period: MailingConfigurationFormSchedulingPeriod;
  relativePlanningDayOffset: number;
  relativePlanningTimeOfDay: Duration;
};

export type MailingConfigurationFormSchedulingPeriod =
  | "relativeBefore"
  | "relativeAfter";

export type MailingConfigurationContentSubmitValues = {
  configuration: string;
  content: string;
};

export interface MailingConfigurationSubmitValues
  extends MailingConfigurationFormValues {
  mailingContent: MailingConfigurationContentSubmitValues;
}

const props = defineProps<{
  activityMailingsConfigurationId: string;
  item?: MailingConfigurationDto;
  mailingType?: MailingTypeEnum;
}>();

const emit = defineEmits<{
  submit: [MailingConfigurationSubmitValues];
}>();

const router = useRouter();

// Form
const form = useForm<MailingConfigurationFormValues>({
  validationSchema: yup.object({
    name: yup.string().required(),
    type: yup.mixed<MailingTypeEnum>().required(),
    locale: yup.mixed<Culture>().required(),
    subject: yup.string().required(),
    fromName: yup.string().required(),
    fromEmailAddress: yup.string().required(),
    replyToEmailAddress: yup.string().required(),
    period: yup.mixed<MailingConfigurationFormSchedulingPeriod>().required(),
    relativePlanningDayOffset: yup.number().required(),
    relativePlanningTimeOfDay: yup.mixed<Duration>().required(),
  }),
  initialValues: props.item ? convertToFormValues(props.item) : undefined,
});

function convertToFormValues(
  item: MailingConfigurationDto,
): Partial<MailingConfigurationFormValues> {
  return {
    name: item.name,
    type: item.type,
    locale: item.locale,
    subject: item.subject,
    fromName: item.fromName,
    fromEmailAddress: item.fromEmailAddress,
    replyToEmailAddress: item.replyToEmailAddress,
    period:
      (item.relativePlanningDayOffset ?? 0) > 0
        ? "relativeAfter"
        : "relativeBefore",
    relativePlanningDayOffset: Math.abs(item.relativePlanningDayOffset ?? 0),
    relativePlanningTimeOfDay:
      item.relativePlanningTimeOfDay ??
      Duration.fromObject({ hour: 12, minute: 0 }),
  };
}

const submit = form.handleSubmit(async (values) => {
  const [configuration, content] = await bee.save();

  const mailingContent: MailingConfigurationContentSubmitValues = {
    configuration,
    content,
  };

  emit("submit", { ...values, mailingContent });
});

// Mailing type specific Bee instance
const bee = useBee("bee-container");

const typeSelectionModalVisible = ref(!props.item);
const configMailingType = ref<MailingType>();

async function setTypeAndInitBee(type: MailingTypeEnum) {
  form.setFieldValue("type", type, false);
  typeSelectionModalVisible.value = false;
  configMailingType.value = settings.mailingTypes.find(
    (t) => t.mailingType === type,
  );

  if (!configMailingType.value) {
    throw new Error(`Mailing type ${type} not found`);
  }

  await bee.init({
    template: props.item
      ? (props.item.mailingContent?.configuration as
          | IEntityContentJson
          | undefined)
      : undefined,
    mergeTags: configMailingType.value.mergeTags,
    mergeContents: configMailingType.value.mergeContents,
    specialLinks: configMailingType.value.specialLinks,
  });
}

const { state: activityMailingsConfiguration } = useAsyncState(
  () =>
    eduConfigurationServiceClient.getActivityMailingsConfiguration(
      props.activityMailingsConfigurationId,
    ),
  undefined,
);

onMounted(() => {
  const type = props.item?.type ?? props.mailingType;

  if (!type) {
    throw new Error(
      "Could not initialize Mailing Configuration Form: Mailing type not provided",
    );
  }

  setTypeAndInitBee(type);
});
</script>
