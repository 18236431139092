<template>
  <Modal
    v-model:visible="typeSelectionModalVisible"
    :size="ModalSize.Large"
    disabled
  >
    <template v-slot:content>
      <div class="flex flex-col justify-center gap-4">
        <div class="grid w-full grid-cols-2">
          <template v-for="type in mailingTypeOptions" :key="type.value">
            <button
              type="button"
              :disabled="!isEnabled(type.value)"
              :class="[
                'group flex flex-col items-start gap-6 border border-gray-50 p-8 text-left shadow-sm transition-all duration-300',
                isEnabled(type.value)
                  ? 'bg-white hover:border-gray-100 hover:bg-gray-50'
                  : 'bg-gray-50 opacity-25',
              ]"
              @click="onClickType(type.value)"
            >
              <div class="flex w-full justify-between">
                <Card
                  :color="mailingTypeColor[type.value]"
                  :size="CardSize.sm"
                  flat
                >
                  <Icon
                    :icon="mailingTypeIcon[type.value]"
                    :size="IconSize.lg"
                  />
                </Card>

                <Icon
                  v-if="isEnabled(type.value)"
                  icon="north_east"
                  :size="IconSize.lg"
                  :class="[
                    'transition-all duration-300',
                    isEnabled(type.value)
                      ? 'text-gray-300 group-hover:text-gray-500'
                      : 'text-gray-300',
                  ]"
                />
              </div>
              <div class="flex flex-col gap-2">
                <h2
                  :class="[
                    'text-lg font-semibold text-deepteal-600',
                    isEnabled(type.value)
                      ? 'group-hover:text-deepteal-800'
                      : '',
                  ]"
                >
                  {{ compontentTexts.mailingTypes[type.value].title }}
                </h2>
                <p
                  :class="[
                    'text-sm text-deepteal-500',
                    isEnabled(type.value)
                      ? 'group-hover:text-deepteal-700'
                      : '',
                  ]"
                >
                  {{ compontentTexts.mailingTypes[type.value].description }}
                </p>
              </div>
            </button>
          </template>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { CardSize } from "@/components/common/card/Card.types";
import Card from "@/components/common/card/Card.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import Icon from "@/components/common/icon/Icon.vue";
import { ModalSize } from "@/components/common/modal/Modal.types";
import Modal from "@/components/common/modal/Modal.vue";
import { MailingType } from "@/lib/eduConfigurationServiceClient";

import {
  isActivityMailingType,
  mailingTypeColor,
  mailingTypeIcon,
} from "@/models/mailingType";
import texts from "@/utils/texts";
import { computed } from "vue";

const emit = defineEmits<{
  type: [MailingType];
}>();

const compontentTexts = texts.navigationItems.mailings.mailingTypePicker;

const typeSelectionModalVisible = defineModel<boolean>("visible", {
  default: false,
});

const mailingTypeOptions = computed(() =>
  Object.values(MailingType)
    .filter((type) => isActivityMailingType(type))
    .map((type) => ({
      label: texts.enums.mailingType[type],
      value: type,
    })),
);

const isEnabled = (type: MailingType) => {
  return type === MailingType.ActivityReminder;
};

const onClickType = (type: MailingType) => {
  emit("type", type);
};
</script>
