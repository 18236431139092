<template>
  <SlideOver
    v-model:visible="visibleInternal"
    :title="title"
    data-testid="create-slide-over"
    size="lg"
  >
    <ErrorComponent v-if="error" />
    <Loader v-else-if="loading" class="h-full" />
    <SessionForm
      v-else
      :formValues="formValues"
      :activityStartDateTime="activity.startDateTime"
      :activityEndDateTime="activity.endDateTime"
      :isActivityOnline="activity.isOnline"
      :groups="groups"
      :locations="locations"
      :types="types"
      :registrationLinks="registrationLinks"
      :studyProgramIds="activity.studyProgramIds"
      @submit="submitUpdate"
    >
      <ButtonSubmit :loading="actionLoading" />
      <ButtonCancel @click="visibleInternal = false" />
      <ButtonCopy @click="clickCopy" />
      <ButtonDelete @click="clickDelete" />
    </SessionForm>
  </SlideOver>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import logger from "@/plugins/logger";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import Notify from "@/utils/notify";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import texts from "@/utils/texts";
import SessionForm from "./SessionForm.vue";
import {
  convertToDto,
  convertToFormValues,
  SessionFormValues,
} from "./SessionForm.types";
import SessionGroup from "@/models/sessionGroup";
import Loader from "@/components/common/loader/Loader.vue";
import ErrorComponent from "@/components/common/error/Error.vue";
import {
  ActivityDTO,
  ActivityLocationLocalizedDTO,
  LocalizedSessionTypeDto,
  RegistrationLinkWithRegistrationCountDTO,
} from "@/lib/eduConfigurationServiceClient";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ButtonCopy from "@/components/common/button/ButtonCopy.vue";
import ButtonDelete from "@/components/common/button/ButtonDelete.vue";

const props = defineProps<{
  visible: boolean;
  sessionId?: string;
  activity: ActivityDTO;
  groups: SessionGroup[];
  locations: ActivityLocationLocalizedDTO[];
  types: LocalizedSessionTypeDto[];
  registrationLinks: RegistrationLinkWithRegistrationCountDTO[];
}>();

const emit = defineEmits<{
  "update:visible": [value: boolean];
  "click:copy": [value: string];
  "click:delete": [value: string];
  "edit:succeeded": [];
}>();

const visibleInternal = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});

const loading = ref<boolean>(false);
const error = ref<boolean>(false);

const title = ref<string>();
const formValues = ref<SessionFormValues | undefined>();

watch(visibleInternal, async (value) => {
  if (value) {
    if (!props.sessionId) {
      throw new Error("Cannot complete action. No session-id provided");
    }
    title.value = undefined;
    formValues.value = undefined;
    loading.value = true;
    try {
      const session = await eduConfigurationServiceClient.getSessionById(
        props.activity.id,
        props.sessionId,
      );
      title.value = session.name;
      formValues.value = convertToFormValues(session);
    } catch (e) {
      logger.error(e as Error);
      error.value = true;
    } finally {
      loading.value = false;
    }
  } else {
    error.value = false;
  }
});

const clickCopy = () => {
  if (!props.sessionId) {
    throw new Error("Cannot complete action. No session-id provided");
  }
  visibleInternal.value = false;
  emit("click:copy", props.sessionId);
};

const clickDelete = () => {
  if (!props.sessionId) {
    throw new Error("Cannot complete action. No session-id provided");
  }
  visibleInternal.value = false;
  emit("click:delete", props.sessionId);
};

const actionLoading = ref<boolean>(false);
const submitUpdate = async (values: SessionFormValues) => {
  if (!props.sessionId) {
    throw new Error("Cannot complete action. No session-id provided");
  }

  actionLoading.value = true;
  try {
    await eduConfigurationServiceClient.updateSession(
      props.activity.id,
      props.sessionId,
      convertToDto(values),
    );
    Notify.success(texts.notifications.edit.successCached);
    visibleInternal.value = false;
    emit("edit:succeeded");
  } catch (e) {
    Notify.failure(texts.notifications.edit.failure, [
      texts.models.session.title,
    ]);
    logger.error(e as Error);
  } finally {
    actionLoading.value = false;
  }
};
</script>
