<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="visibleInternal">
    <Dialog
      class="relative z-modal"
      :data-testid="testIds.modal"
      @close="disabled ? false : (visibleInternal = false)"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              :class="[
                'relative w-full transform overflow-hidden rounded-lg bg-white px-4 py-6 text-left shadow-xl transition-all sm:my-8 sm:px-6 sm:py-8',
                {
                  'max-w-lg': size === ModalSize.Normal,
                  'max-w-6xl': size === ModalSize.Large,
                },
              ]"
            >
              <div class="flex flex-col gap-6 sm:gap-8">
                <div
                  v-if="title || description || $slots.description"
                  class="flex flex-col items-center gap-4 text-center text-sm text-gray-600 sm:flex-row sm:items-start sm:gap-6 sm:text-start"
                >
                  <ModalIcon v-if="type" :type />
                  <div class="flex flex-col gap-2">
                    <DialogTitle
                      v-if="title"
                      as="header"
                      class="text-lg font-semibold text-gray-900"
                      data-testid="modal-title"
                    >
                      {{ title }}
                    </DialogTitle>
                    <p
                      v-if="description || $slots.description"
                      class="text-sm text-gray-500"
                    >
                      {{ description }}
                      <slot name="description"></slot>
                    </p>
                  </div>
                </div>

                <slot name="content"></slot>

                <footer
                  v-if="$slots.buttons"
                  class="flex w-full flex-col gap-2 self-stretch *:flex-1 sm:flex-row-reverse"
                >
                  <slot name="buttons">
                    <!-- Default button because Dialog-component requires a button element to focus-->
                    <button></button>
                  </slot>
                </footer>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import { computed } from "vue";
import { ModalType, ModalSize } from "@/components/common/modal/Modal.types";
import ModalIcon from "@/components/common/modal/ModalIcon.vue";
import { testIds } from "@/utils/testing";

const props = withDefaults(
  defineProps<{
    visible: boolean;
    type?: ModalType;
    title?: string;
    description?: string;
    centered?: boolean;
    size?: ModalSize;
    disabled?: boolean;
  }>(),
  {
    type: undefined,
    icon: undefined,
    title: undefined,
    description: undefined,
    centered: false,
    size: ModalSize.Normal,
    disabled: false,
  },
);

const emit = defineEmits<{
  (e: "update:visible", value: boolean): void;
}>();

const visibleInternal = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});
</script>
