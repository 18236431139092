<template>
  <div class="flex flex-col gap-4">
    <FormFieldTextInput
      id="subject"
      :label="texts.models.mailingConfiguration.subject"
      :placeholder="texts.models.mailingConfiguration.subject"
    />
    <FormFieldTextInput
      id="fromName"
      :label="texts.models.mailingConfiguration.recipientName"
      :placeholder="texts.models.mailingConfiguration.recipientName"
    />

    <div class="flex items-start gap-4">
      <FormFieldSelectNative
        id="fromEmailAddress"
        class="flex-1"
        :label="texts.models.mailingConfiguration.recipientEmail"
        :placeholder="texts.models.mailingConfiguration.recipientEmail"
        :options="fromEmailOptions"
      />
      <FormFieldSelectNative
        id="replyToEmailAddress"
        class="flex-1"
        :label="texts.models.mailingConfiguration.replyToEmail"
        :placeholder="texts.models.mailingConfiguration.replyToEmail"
        :options="replyToEmailAddressOptions"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import FormFieldSelectNative from "@/components/common/select/FormFieldSelectNative.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import settings from "@/store/context/settings.context";
import texts from "@/utils/texts";
import { MailingConfigurationFormValues } from "@/views/settings/activity-mailings-configurations/mailing-configuration/mailing-configuration-form/MailingConfigurationForm.vue";
import { FormContext } from "vee-validate";
import { computed } from "vue";

const form = defineModel<FormContext<MailingConfigurationFormValues>>("form", {
  required: true,
});

const fromEmailOptions = computed(() =>
  settings.fromEmailAddresses.map((fromEmailAddress) => ({
    value: fromEmailAddress.emailAddress,
    label: fromEmailAddress.emailAddress,
  })),
);

const replyToEmailAddressOptions = computed(() =>
  settings.replyToEmailAddresses.map((replyToEmailAddress) => ({
    value: replyToEmailAddress.emailAddress,
    label: replyToEmailAddress.emailAddress,
  })),
);

if (!form.value.values.fromEmailAddress) {
  form.value.setFieldValue(
    "fromEmailAddress",
    settings.fromEmailAddresses[0]?.emailAddress,
  );
}

if (!form.value.values.replyToEmailAddress) {
  form.value.setFieldValue(
    "replyToEmailAddress",
    settings.replyToEmailAddresses[0]?.emailAddress,
  );
}
</script>
