<template>
  <Card
    class="flex flex-col gap-4"
    :data-testid="testIds.contact.details.title"
  >
    <div class="flex w-full gap-2">
      <Icon icon="person"></Icon>
      <CardHeader>{{ texts.models.contactDetails.title }}</CardHeader>
    </div>
    <DescriptionList>
      <DescriptionListItem
        v-if="contact.gender"
        :name="texts.models.contactDetails.gender"
      >
        <SingleValue :value="contact.gender"></SingleValue>
      </DescriptionListItem>
      <DescriptionListItem
        :name="texts.models.contactDetails.fullName"
        :data-testid="testIds.contact.details.fullName"
      >
        <SingleValue
          :value="contact.firstName + ' ' + contact.lastName"
        ></SingleValue>
      </DescriptionListItem>
      <DescriptionListItem
        v-if="contact.emailAddress"
        :name="texts.models.contactDetails.emailAddress"
      >
        <SingleValue :value="contact.emailAddress"></SingleValue>
      </DescriptionListItem>
      <DescriptionListItem
        v-if="contact.mobilePhoneNumber"
        :name="texts.models.contactDetails.mobilePhoneNumber"
      >
        <SingleValue :value="contact.mobilePhoneNumber"></SingleValue>
      </DescriptionListItem>
      <DescriptionListItem
        v-if="contact.phoneNumber"
        :name="texts.models.contactDetails.phoneNumber"
      >
        <SingleValue :value="contact.phoneNumber"></SingleValue>
      </DescriptionListItem>
      <DescriptionListItem :name="texts.models.contactDetails.optInOptOut">
        <SingleValue
          :value="
            contact.receiveMarketingEmail
              ? texts.models.contactDetails.optIn
              : texts.models.contactDetails.optOut
          "
        ></SingleValue>
      </DescriptionListItem>
      <DescriptionListItem
        v-if="contact.street"
        :name="texts.models.contactDetails.address"
      >
        <SingleValue :value="addressString"></SingleValue>
      </DescriptionListItem>
    </DescriptionList>
    <TextButton
      class="text-sm"
      :label="texts.models.contactDetails.editProfile"
      :color="Color.Emerald"
      :data-testid="testIds.action.edit"
      @click="emit('editProfile')"
    ></TextButton>
  </Card>
</template>

<script setup lang="ts">
import TextButton from "@/components/common/button/TextButton.vue";
import Card from "@/components/common/card/Card.vue";
import CardHeader from "@/components/common/card/CardHeader.vue";
import DescriptionList from "@/components/common/description-list/DescriptionList.vue";
import DescriptionListItem from "@/components/common/description-list/DescriptionListItem.vue";
import SingleValue from "@/components/common/description-list/DescriptionListSingleValue.vue";
import { Prospect } from "@/models/contact";
import { Color } from "@/enums";
import Icon from "@/components/common/icon/Icon.vue";
import texts from "@/utils/texts";
import { computed } from "vue";
import { testIds } from "@/utils/testing";

const emit = defineEmits(["editProfile"]);

const props = defineProps<{
  contact: Prospect;
}>();

const addressString = computed(() => {
  const street = props.contact?.street || "";
  const houseNumber = props.contact?.houseNumber || "";
  const postalCode = props.contact?.postalCode || "";
  const city = props.contact?.city || "";
  const country = props.contact?.country || "";

  return `${street} ${houseNumber}, ${postalCode}, ${city}, ${country}`.replace(
    /^\s*,|,\s*,|,\s*$/g,
    "",
  );
});
</script>
