<template>
  <MailingConfigurationForm
    v-if="editDTO"
    :activityMailingsConfigurationId
    :item="editDTO"
    @submit="onSubmitEdit"
  >
    <template v-slot="{ disabled }">
      <ButtonSubmit :loading="submitLoading" :disabled="disabled" />
      <ButtonCancel :disabled="disabled" @click="onClickCancelEdit" />
    </template>
  </MailingConfigurationForm>
</template>

<script setup lang="ts">
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import {
  CreateUpdateMailingConfigurationDto,
  MailingConfigurationDto,
} from "@/lib/eduConfigurationServiceClient";
import { RouteNamesMailingConfiguration } from "@/router/routeNames";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import MailingConfigurationForm, {
  MailingConfigurationSubmitValues,
} from "@/views/settings/activity-mailings-configurations/mailing-configuration/mailing-configuration-form/MailingConfigurationForm.vue";
import { useAsyncState } from "@vueuse/core";
import { ref } from "vue";
import { useRouter } from "vue-router";

const props = defineProps<{
  activityMailingsConfigurationId: string;
  mailingConfigurationId: string;
}>();

const router = useRouter();

const { state: editDTO } = useAsyncState<MailingConfigurationDto | undefined>(
  () =>
    eduConfigurationServiceClient.getMailingConfiguration(
      props.activityMailingsConfigurationId,
      props.mailingConfigurationId,
    ),
  undefined,
);

function onClickCancelEdit() {
  router.push({
    name: RouteNamesMailingConfiguration.MAILING_CONFIGURATIONS,
    params: {
      activityMailingsConfigurationId: props.activityMailingsConfigurationId,
    },
  });
}

const submitLoading = ref(false);
async function onSubmitEdit(values: MailingConfigurationSubmitValues) {
  try {
    if (!editDTO.value) {
      throw new Error("EditDTO is not defined");
    }
    submitLoading.value = true;
    await eduConfigurationServiceClient.updateMailingConfiguration(
      props.activityMailingsConfigurationId,
      props.mailingConfigurationId,
      convertToUpdateDto(editDTO.value, values),
    );
    Notify.success(texts.notifications.edit.success, [
      texts.models.mailingConfiguration.title,
    ]);
    router.push({
      name: RouteNamesMailingConfiguration.MAILING_CONFIGURATIONS,
      params: {
        activityMailingsConfigurationId: props.activityMailingsConfigurationId,
      },
    });
  } catch (e) {
    Notify.failure(texts.notifications.edit.failure, [
      texts.models.mailingConfiguration.title,
    ]);
    throw e;
  } finally {
    submitLoading.value = false;
  }
}

function convertToUpdateDto(
  dto: MailingConfigurationDto,
  values: MailingConfigurationSubmitValues,
): CreateUpdateMailingConfigurationDto {
  return new CreateUpdateMailingConfigurationDto({
    locale: values.locale,
    name: values.name,
    type: values.type,
    subject: values.subject,
    relativePlanningDayOffset:
      values.period === "relativeBefore"
        ? -values.relativePlanningDayOffset
        : values.relativePlanningDayOffset,
    relativePlanningTimeOfDay: values.relativePlanningTimeOfDay,
    fromName: values.fromName,
    fromEmailAddress: values.fromEmailAddress,
    replyToEmailAddress: values.replyToEmailAddress,
    mailingContent: {
      id: dto.mailingContent?.id,
      configuration: values.mailingContent.configuration,
      content: values.mailingContent.content,
    },
  });
}
</script>
