<template>
  <template v-for="tab in tabs" :key="tab.name">
    <SettingsSidebarNavigationItem :to="tab.to" :icon="tab.icon">
      {{ tab.name }}
    </SettingsSidebarNavigationItem>
  </template>
</template>

<script setup lang="ts">
import { IconNames } from "@/components/common/icon/Icon.types";
import { RouteNamesSettings } from "@/router/routeNames";
import texts from "@/utils/texts";
import SettingsSidebarNavigationItem from "@/views/settings/layout/SettingsSidebarNavigationItem.vue";
import { RouteLocationNamedRaw } from "vue-router";

const tabs: { name: string; icon: IconNames; to: RouteLocationNamedRaw }[] = [
  {
    name: texts.navigationItems.manage.settings.navigationTabs.general,
    to: { name: RouteNamesSettings.GENERAL },
    icon: "settings",
  },
  {
    name: texts.navigationItems.manage.settings.navigationTabs.organization,
    to: { name: RouteNamesSettings.ORGANIZATION },
    icon: "business_center",
  },
  {
    name: texts.navigationItems.manage.settings.navigationTabs
      .studyProgramVariables,
    to: { name: RouteNamesSettings.STUDY_PROGRAM_VARIABLES },
    icon: "school",
  },
  {
    name: texts.navigationItems.manage.settings.navigationTabs.studyPrograms,
    to: { name: RouteNamesSettings.STUDY_PROGRAMS },
    icon: "school",
  },
  {
    name: texts.navigationItems.manage.settings.navigationTabs.accounts,
    to: { name: RouteNamesSettings.ACCOUNTS },
    icon: "group",
  },
  {
    name: texts.navigationItems.manage.settings.navigationTabs.emailTemplates,
    to: { name: RouteNamesSettings.EMAIL_OVERVIEW },
    icon: "mail",
  },
  {
    name: texts.navigationItems.manage.settings.navigationTabs.formTemplates,
    to: { name: RouteNamesSettings.FORMS },
    icon: "assignment",
  },
  {
    name: texts.navigationItems.manage.settings.navigationTabs.questions,
    to: { name: RouteNamesSettings.QUESTIONS },
    icon: "help",
  },
  {
    name: texts.navigationItems.manage.settings.navigationTabs.activityMailings,
    to: { name: RouteNamesSettings.ACTIVITY_MAILINGS_CONFIGURATION },
    icon: "automation",
  },
];
</script>
