<template>
  <ModelPageBuilder
    :modelTexts="{
      title: texts.models.sessionType.title,
      plural: texts.models.sessionType.plural,
    }"
    :list
    :createItem
    :editItem
    :deleteItem
  />
</template>

<script setup lang="ts">
import ModelPageBuilder from "@/components/model-page-builder/ModelPageBuilder.vue";
import settings, { loadSessionTypes } from "@/store/context/settings.context";
import texts from "@/utils/texts";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import SessionTypeForm from "@/views/settings/organization/session-types/SessionTypeForm.vue";
import {
  convertToCreateDto,
  convertToUpdateDto,
  SessionTypeFormValues,
} from "@/views/settings/organization/session-types/SessionTypeForm.types";

const list = {
  load: async () => {
    await loadSessionTypes();
    return settings.sessionTypes;
  },
};

const createItem = {
  form: {
    component: SessionTypeForm,
    submit: async (values: SessionTypeFormValues) => {
      await eduConfigurationServiceClient.createSessionType(
        convertToCreateDto(values),
      );
    },
  },
};

const editItem = {
  form: {
    load: (id: string) => eduConfigurationServiceClient.getSessionTypeById(id),
    component: SessionTypeForm,
    submit: (id: string, values: SessionTypeFormValues) =>
      eduConfigurationServiceClient.updateSessionType(
        id,
        convertToUpdateDto(values),
      ),
  },
};

const deleteItem = {
  submit: (id: string) => eduConfigurationServiceClient.deleteSessionType(id),
};
</script>
