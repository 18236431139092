<template>
  <DropdownWrapper :origin="DropdownOrigin.TopRight">
    <template v-slot:button>
      <MenuButton :as="ButtonAdd" />
    </template>
    <template v-slot:items>
      <DropdownItem
        v-for="item in createItems"
        :key="item.params.type"
        :icon="item.icon"
        @click="router.push(item)"
      >
        {{ item.label }}
      </DropdownItem>
    </template>
  </DropdownWrapper>
</template>
<script setup lang="ts">
import { FormType } from "@/enums";
import { RouteNamesSettings } from "@/router/routeNames";
import DropdownWrapper from "@/components/common/dropdown/DropdownWrapper.vue";
import { MenuButton } from "@headlessui/vue";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import ButtonAdd from "@/components/common/button/ButtonAdd.vue";
import texts from "@/utils/texts";
import { useRouter } from "vue-router";
import { formTypeIcons } from "@/views/settings/forms/FormExtensions";

const router = useRouter();

const createItems = Object.values(FormType)
  .map((type) => ({
    name: RouteNamesSettings.FORMS_CREATE_TEMPLATE,
    params: { type: type },
    label: texts.enums.formType[type],
    icon: formTypeIcons[type],
  }))
  .toSorted((a, b) => a.label.localeCompare(b.label));
</script>
