<template>
  <SlideOver
    v-model:visible="visible"
    :title="interpolate(texts.actions.add, modelTexts.title)"
    :size="createItemForm.options?.slideOverSize"
  >
    <component :is="createItemForm.component" @submit="onSubmitCreate">
      <ButtonSubmit :loading="createLoading" />
      <ButtonCancel @click="onClickCancelCreate" />
    </component>
  </SlideOver>
</template>

<script setup lang="ts" generic="ICreateFormValues">
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import {
  CreateItemFormProps,
  ModelTextProps,
} from "@/components/model-page-builder/ModelPageBuilder.vue";
import { interpolate } from "@/dictionary";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import { ref } from "vue";

const props = defineProps<{
  modelTexts: ModelTextProps;
  createItemForm: CreateItemFormProps<ICreateFormValues>;
}>();

const emit = defineEmits<{
  created: [];
}>();

const visible = defineModel<boolean>("visible", {
  required: true,
  default: false,
});

const createLoading = ref(false);

function onClickCancelCreate() {
  visible.value = false;
}

async function onSubmitCreate(values: ICreateFormValues) {
  try {
    createLoading.value = true;
    await props.createItemForm.submit(values);
    Notify.success(texts.notifications.create.success, [
      props.modelTexts.title,
    ]);
    emit("created");
  } catch (e) {
    Notify.failure(texts.notifications.create.failure, [
      props.modelTexts.title,
    ]);
    throw e;
  } finally {
    createLoading.value = false;
    visible.value = false;
  }
}
</script>
