<template>
  <FormLayout @submit="submitForm">
    <LocalizationTabs
      v-slot="{ current }"
      v-model="activeCulture"
      :locales
      @add="addLocalization"
      @remove="removeLocalization"
    >
      <LocalizationSlot v-slot="{ locale }" :current :locales>
        <FormFieldTextInput
          :id="`localizations.${locale}.name`"
          :label="`${texts.models.activityLocation.name} (${
            texts.enums.culture[locale]
          })`"
          :data-testid="`name_${locale}`"
        />
        <FormFieldTextInput
          :id="`localizations.${locale}.city`"
          :label="`${texts.models.activityLocation.city} (${
            texts.enums.culture[locale]
          })`"
          :data-testid="`city_${locale}`"
        />
        <FormFieldTextInput
          :id="`localizations.${locale}.informationUrl`"
          :label="`${texts.models.activityLocation.informationUrl} (${
            texts.enums.culture[locale]
          })`"
          data-testid="`informationUrl_${locale}`"
          :type="TextInputType.URL"
        />
      </LocalizationSlot>
    </LocalizationTabs>
    <Divider />
    <template v-slot:actions>
      <slot></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import texts from "@/utils/texts";
import {
  ActivityLocationFormValues,
  convertToFormValues,
} from "@/views/settings/organization/activity-locations/ActivityLocationForm.types";
import { useForm } from "vee-validate";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import { Culture } from "@/enums";
import FormLayout from "@/components/common/form/FormLayout.vue";
import LocalizationTabs from "@/components/localization-tabs/LocalizationTabs.vue";
import { computed, ref } from "vue";
import settings from "@/store/context/settings.context";
import LocalizationSlot from "@/components/localization-tabs/LocalizationSlot.vue";
import Divider from "@/components/common/divider/Divider.vue";
import { ActivityLocationDTO } from "@/lib/eduConfigurationServiceClient";
import * as yup from "yup";
import { objectLocalizationSchemaOf } from "@/yup-extensions";

const props = defineProps<{
  item?: ActivityLocationDTO;
}>();

const emit = defineEmits<{
  submit: [ActivityLocationFormValues];
}>();

const validationSchema = yup.object({
  localizations: objectLocalizationSchemaOf(
    yup.object({
      name: yup.string().required(),
      city: yup.string(),
      informationUrl: yup.string().url(),
    }),
  ),
});

const defaultCulture = settings.mainLanguage.locale.value as Culture;
const activeCulture = ref<Culture>(
  settings.mainLanguage.locale.value as Culture,
);

const locales = computed(() =>
  form.values.localizations
    ? (Object.keys(form.values.localizations) as Culture[])
    : [defaultCulture],
);

function addLocalization(culture: Culture) {
  form.setFieldValue("localizations", {
    ...form.values.localizations,
    [culture]: { name: "" },
  });
}

function removeLocalization(culture: Culture) {
  const newDict = { ...form.values.localizations };
  delete newDict[culture];
  form.setFieldValue("localizations", newDict);
}

const form = useForm<ActivityLocationFormValues>({
  validationSchema,
  initialValues: props.item ? convertToFormValues(props.item) : undefined,
});

const submitForm = form.handleSubmit((values) => emit("submit", values));
</script>
