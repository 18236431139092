<template>
  <MailingConfigurationForm
    v-slot="{ disabled }"
    :activityMailingsConfigurationId
    :mailingType
    @submit="onSubmitCreate"
  >
    <ButtonSubmit :loading="createLoading" :disabled="disabled" />
    <ButtonCancel :disabled="disabled" @click="onClickCancelCreate" />
  </MailingConfigurationForm>
</template>

<script setup lang="ts">
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import {
  CreateUpdateMailingConfigurationDto,
  MailingType,
} from "@/lib/eduConfigurationServiceClient";
import { RouteNamesMailingConfiguration } from "@/router/routeNames";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import MailingConfigurationForm, {
  MailingConfigurationSubmitValues,
} from "@/views/settings/activity-mailings-configurations/mailing-configuration/mailing-configuration-form/MailingConfigurationForm.vue";
import { ref } from "vue";
import { useRouter } from "vue-router";

const props = defineProps<{
  activityMailingsConfigurationId: string;
  mailingType: MailingType;
}>();

const router = useRouter();

function onClickCancelCreate() {
  router.push({
    name: RouteNamesMailingConfiguration.MAILING_CONFIGURATIONS,
    params: {
      activityMailingsConfigurationId: props.activityMailingsConfigurationId,
    },
  });
}

const createLoading = ref(false);
async function onSubmitCreate(values: MailingConfigurationSubmitValues) {
  try {
    createLoading.value = true;
    await eduConfigurationServiceClient.createMailingConfiguration(
      props.activityMailingsConfigurationId,
      convertToCreateDto(values),
    );
    Notify.success(texts.notifications.create.success, [
      texts.models.mailingConfiguration.title,
    ]);
    router.push({
      name: RouteNamesMailingConfiguration.MAILING_CONFIGURATIONS,
      params: {
        activityMailingsConfigurationId: props.activityMailingsConfigurationId,
      },
    });
  } catch (e) {
    Notify.failure(texts.notifications.create.failure, [
      texts.models.mailingConfiguration.title,
    ]);
    throw e;
  } finally {
    createLoading.value = false;
  }
}

function convertToCreateDto(
  values: MailingConfigurationSubmitValues,
): CreateUpdateMailingConfigurationDto {
  return new CreateUpdateMailingConfigurationDto({
    locale: values.locale,
    name: values.name,
    type: values.type,
    subject: values.subject,
    relativePlanningDayOffset:
      values.period === "relativeBefore"
        ? -values.relativePlanningDayOffset
        : values.relativePlanningDayOffset,
    relativePlanningTimeOfDay: values.relativePlanningTimeOfDay,
    fromName: values.fromName,
    fromEmailAddress: values.fromEmailAddress,
    replyToEmailAddress: values.replyToEmailAddress,
    mailingContent: {
      configuration: values.mailingContent.configuration,
      content: values.mailingContent.content,
    },
  });
}
</script>
