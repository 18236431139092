import * as yup from "yup";
import { Culture } from "@/enums";
import { Localization } from "@/models/helpers/localizable";

export type ActivityTypeFormValues = {
  localizations: ActivityTypeFormValuesLocalization[];
};

export type ActivityTypeFormValuesLocalization = Localization<{
  name: string;
}>;

export const validationSchema = yup.object({
  localizations: yup.array().of(
    yup.object({
      locale: yup.mixed<Culture>().required(),
      name: yup.string().requiredWhenMainLocale(),
    }),
  ),
});
