<template>
  <FormFieldMultiCriterion
    :id
    :options
    :initialValue="
      criterion.activityTypeIds ? [...criterion.activityTypeIds] : undefined
    "
    :disabled="readonly || criterion.isReadOnly"
  />
</template>

<script setup lang="ts">
import { TypedCriterionFieldProps } from "@/components/selection/SelectionCriterionForm.types";
import { fields } from "@/utils/miscellaneous";
import { SelectionCriterionDto } from "@/lib/eduConfigurationServiceClient";
import FormFieldMultiCriterion from "@/components/selection/type/components/FormFieldMultiCriterion.vue";
import { computed } from "vue";

const props = defineProps<TypedCriterionFieldProps>();

const id = fields<SelectionCriterionDto>().activityTypeIds;

const options = computed(() =>
  props.context.activityTypes.map((activityType) => ({
    id: activityType.id,
    label: activityType.name,
  })),
);
</script>
