<template>
  <Page class="flex flex-col gap-5">
    <div class="flex items-center">
      <h3 class="flex-grow text-base font-semibold text-deepteal-700">
        {{ texts.navigationItems.organize.styling.stylingPerActivity.title }}
      </h3>
    </div>
    <span class="text-sm font-light text-deepteal-700">
      {{
        texts.navigationItems.organize.styling.stylingPerActivity.description
      }}
    </span>
    <Section>
      <span
        v-if="!updateLoading && activityTypes.length === 0"
        class="text-sm italic text-gray-500"
        >{{
          texts.navigationItems.organize.activityTypes.overview.noResultsFound
        }}</span
      >
      <!-- Table containing all activity types -->
      <List v-if="activityTypes.length > 0">
        <template v-slot:header>
          <ListItemColumn>{{ texts.models.activityType.title }}</ListItemColumn>
          <ListItemColumn></ListItemColumn>
          <ListItemColumn></ListItemColumn>
        </template>
        <ListItem>
          <ListItemColumn accent>{{
            texts.models.activityType.default
          }}</ListItemColumn>
          <ListItemColumn class="text-left">
            <a
              class="cursor-pointer text-emerald-500 hover:text-emerald-700"
              :href="`${env.VITE_PROSPECT_APP_BASE_URL}/activities/${tenant.uri}`"
              target="_blank"
              >{{ texts.generic.preview }}</a
            >
          </ListItemColumn>
          <ListItemColumn class="text-right">
            <a
              class="cursor-pointer text-emerald-500 hover:text-emerald-700"
              @click="() => openUpdateActivityTypeSlideOver(null)"
              >{{ texts.actions.edit }}</a
            ></ListItemColumn
          >
        </ListItem>
        <ListItem
          v-for="activityType in activityTypes"
          :key="activityType.name"
        >
          <ListItemColumn accent>{{ activityType.name }} </ListItemColumn>
          <ListItemColumn class="text-left">
            <a
              class="cursor-pointer text-emerald-500 hover:text-emerald-700"
              :href="`${env.VITE_PROSPECT_APP_BASE_URL}/activities/${tenant.uri}?activityTypeId=${activityType.id}`"
              target="_blank"
              >{{ texts.generic.preview }}</a
            >
          </ListItemColumn>
          <ListItemColumn class="text-right"
            ><a
              class="cursor-pointer text-emerald-500 hover:text-emerald-700"
              @click="() => openUpdateActivityTypeSlideOver(activityType.id)"
              >{{ texts.actions.edit }}</a
            ></ListItemColumn
          >
        </ListItem>
      </List>
    </Section>
  </Page>

  <!-- SlideOver panel for activity type specific styling-->
  <SlideOver
    v-model:visible="slideOverCreateOpen"
    :title="texts.navigationItems.organize.styling.stylingPerActivity.editTitle"
    :subTitle="
      texts.navigationItems.organize.styling.stylingPerActivity.description
    "
    size="lg"
  >
    <PublicActivityPageForm
      v-if="currentSettings"
      :initialValues="currentSettings"
      @submit="handleSubmit"
    >
      <ButtonSubmit
        :loading="updateActivityTypePageSettingsState === AsyncState.Loading"
      />
      <ButtonCancel @click="slideOverCreateOpen = false" />
    </PublicActivityPageForm>
  </SlideOver>
</template>

<script setup lang="ts">
import { ref } from "vue";

import Section from "@/components/common/section/Section.vue";
import Page from "@/components/common/page/Page.vue";
import ActivityType from "@/models/activityType";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import PublicActivityPageForm from "./PublicActivityPageForm.vue";
import {
  IPublicActivityPageSettingsDTO,
  PublicActivityPageSettingsDTO,
} from "@/lib/eduConfigurationServiceClient";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { PublicActivityPageSettingsFormValues } from "@/views/settings/organization/public-activity-page-settings/PublicActivityPageForm.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import settings from "@/store/context/settings.context";
import {
  AsyncState,
  useAsyncState,
} from "@/components/common/async/Async.types";
import env from "@/env";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import List from "@/components/common/list/List.vue";
import tenant from "@/store/context/tenant.context";
import texts from "@/utils/texts";

const props = defineProps<{
  publicPageSettings: IPublicActivityPageSettingsDTO[];
  fallbackSettings: IPublicActivityPageSettingsDTO;
}>();

const emit = defineEmits(["update:succeeded"]);

const slideOverCreateOpen = ref<boolean>(false);
const currentSettings = ref<IPublicActivityPageSettingsDTO>();
const updateLoading = ref<boolean>(true);

const openUpdateActivityTypeSlideOver = async (
  activityTypeId: string | null,
) => {
  const typeSpecificSettings = props.publicPageSettings.find(
    (i) => i.activityTypeId === activityTypeId,
  );

  if (typeSpecificSettings) {
    currentSettings.value = typeSpecificSettings;
  } else {
    currentSettings.value = {
      bannerUrl: props.fallbackSettings.bannerUrl,
      faviconUrl: props.fallbackSettings.faviconUrl,
      pageWidth: props.fallbackSettings.pageWidth,
      gtmCode: props.fallbackSettings.gtmCode,
      color: props.fallbackSettings.color,
      activityTypeId: activityTypeId ? activityTypeId : undefined,
      localizations: props.fallbackSettings.localizations,
    };
  }

  slideOverCreateOpen.value = true;
};

const activityTypes = ref<ActivityType[]>(settings.activityTypes);

const updateActivityTypePublicPageSettings = async (
  values: PublicActivityPageSettingsFormValues,
) => {
  const activityPageDto = new PublicActivityPageSettingsDTO({
    activityTypeId: values.activityTypeId,
    color: values.color,
    bannerUrl: values.bannerUrl,
    faviconUrl: values.faviconUrl,
    pageWidth: values.pageWidth,
    gtmCode: values.gtmCode,
    localizations: Object.entries(values.localizations).map(
      ([locale, localization]) => ({
        locale: locale,
        title: localization.title,
        description: localization.description,
      }),
    ),
  });
  await eduConfigurationServiceClient.updatePublicActivityPageSettings(
    activityPageDto,
  );

  slideOverCreateOpen.value = false;
  emit("update:succeeded");
};

const { state: updateActivityTypePageSettingsState, handler: handleSubmit } =
  useAsyncState(updateActivityTypePublicPageSettings, texts.notifications.edit);
</script>
