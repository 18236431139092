export enum RouteNamesActivityOverview {
  SETTINGS = "activity-overview-settings",
  REGISTRATIONLINKS = "activity-overview-registration-links",
  REGISTRATIONLINKS_EDIT = "activity-overview-registration-links-edit",
  SESSIONS = "activity-overview-sessions",
  REPORTS = "activity-overview-reports",
  STUDYPROGRAMS = "activity-overview-study-programs",
  LOCATIONS = "activity-overview-locations",
  MAILINGS_SMS = "activity-overview-malings-sms",
  MAILING_OVERVIEW = "activity-overview-maling-overview",
  NOTIFICATION_SETTINGS = "activity-overview-notification-settings",
  AFFILIATE_LINKS = "activity-overview-affiliate-links",
  SURVEY_LINKS = "activity-overview-survey-links",
  SURVEY_LINKS_EDIT = "activity-overview-survey-links-edit",
}

export enum RouteNamesActivityReports {
  OVERVIEW = "activity-reports-overview",
  VISITED_SURVEYS = "activity-reports-visited-surveys",
  VISITED_SURVEYS_SURVEY = "activity-reports-visited-surveys-survey",
}

export enum RouteNamesOrganize {
  MAINPAGE = "organize-mainpage",
  ACTIVITIES = "organize-activities",
  TRIAL_DAYS = "trial-days",
}

export enum RouteNamesMailings {
  MAINPAGE = "mailings-main",
  OVERVIEW = "mailings-overview",
  CREATE_GENERAL = "mailings-create-general",
  CREATE_INFORMATION = "mailings-create-information",
  CREATE_ACTIVITY_REMINDER = "mailings-create-activity-reminder",
  CREATE_ACTIVITY_INVITE = "mailings-create-activity-invite",
  CREATE_ACTIVITY_VISITED_SURVEY = "mailings-create-activity-visited-survey",
  MAILING = "mailings-mailing",
}

export enum RouteNamesMailing {
  OVERVIEW = "mailings-mailing-overview",
  EDIT = "mailings-mailing-edit",
}

export enum RouteNamesAccount {
  DETAILS = "account-details",
  STUDY_PROGRAMS = "account-study-programs",
}

export enum RouteNamesSettings {
  GENERAL = "settings-basic-information",
  ADMIN = "settings-admin",
  STUDY_PROGRAMS = "settings-study-programs",
  STUDY_PROGRAM_VARIABLES = "settings-study-program-variables",
  API_KEY = "settings-api-key",
  EMAIL_ADDRESSES = "settings-email-addresses",
  ORGANIZATION = "settings-organization",
  ACCOUNTS = "settings-accounts",
  ACCOUNTS_OVERVIEW = "accounts-overview",
  ACCOUNT = "account",
  ACCOUNT_CREATE = "account-create",
  EMAIL = "template-email",
  EMAIL_OVERVIEW = "template-overview-emails",
  EMAIL_EDIT_MAILING_TEMPLATE = "template-email-edit-mailing-template",
  EMAIL_CREATE_MAILING_TEMPLATE_GENERAL = "email-create-mailing-template-general",
  EMAIL_CREATE_MAILING_TEMPLATE_INFORMATION = "email-create-mailing-template-information",
  EMAIL_CREATE_MAILING_TEMPLATE_ACTIVITY_INVITE = "email-create-mailing-template-activity-invite",
  EMAIL_CREATE_MAILING_TEMPLATE_ACTIVITY_REMINDER = "email-create-mailing-template-activity-reminder",
  EMAIL_CREATE_MAILING_TEMPLATE_ACTIVITY_VISITED_SURVEY = "email-create-mailing-template-activity-visited-survey",
  EMAIL_EDIT_EMAIL_TEMPLATE = "template-email-edit-email-template",
  EMAIL_CREATE_EMAIL_TEMPLATE_CONFIRMATION = "template-email-create-email-template-confirmation",
  EMAIL_CREATE_EMAIL_TEMPLATE_BROCHURE = "template-email-create-email-template-brochure",
  EMAIL_CREATE_EMAIL_TEMPLATE_BROCHURE_HARDCOPY = "template-email-create-email-template-brochure-hardcopy",
  EMAIL_CREATE_EMAIL_TEMPLATE_BROCHURE_MAILROOM = "template-email-create-email-template-brochure-mailroom",
  EMAIL_CREATE_EMAIL_TEMPLATE_KEEPMEINFORMED = "template-email-create-email-template-keepmeinformed",
  EMAIL_CREATE_EMAIL_TEMPLATE_APPLICATION_CONFIRMATION = "template-email-create-email-template-application-confirmation",
  EMAIL_CREATE_EMAIL_TEMPLATE_ACTIVITY_CAPACITY_NOTIFICATION = "template-email-create-email-template-activity-capacity-notification",
  EMAIL_CREATE_EMAIL_TEMPLATE_TRIAL_DAY_CONFIRMATION = "template-email-create-email-template-trial-day-confirmation",
  EMAIL_CREATE_EMAIL_TEMPLATE_TRIAL_DAY_NOTIFICATION = "template-email-create-email-template-trial-day-notification",
  EMAIL_CREATE_EMAIL_REGISTRATION_NOTIFICATION = "template-email-create-email-registration-notification",
  EMAIL_CREATE_EMAIL_REGISTRATION_CANCELED_NOTIFICATION = "template-email-create-email-registration-canceled-notification",
  EMAIL_CREATE_EMAIL_AFFILIATE_REGISTRATIONS_REPORT_LINK = "template-email-create-email-affiliate-registrations-report-link",
  EMAIL_CREATE_EMAIL_AFFILIATE_REGISTRATIONS_REPORT = "template-email-create-email-affiliate-registrations-report",
  EMAIL_CREATE_EMAIL_TEMPLATE_ACTIVITY_SESSION_NOTIFICATION = "template-email-create-email-template-activity-session-notification",
  FORMS = "template-forms",
  FORMS_OVERVIEW = "template-overview-forms",
  FORMS_EDIT_TEMPLATE = "template-forms-edit-template",
  FORMS_CREATE_TEMPLATE = "template-forms-create-template",
  QUESTIONS = "template-questions",
  ACTIVITY_MAILINGS_CONFIGURATION = "activity-mailings-configuration",
}

export enum RouteNamesActivityMailingsConfiguration {
  ACTIVITY_MAILINGS_CONFIGURATIONS = "activity-mailings-configurations",
  MAILING_CONFIGURATIONS = "activity-mailings-configuration-mailing-configurations",
}

export enum RouteNamesMailingConfiguration {
  MAILING_CONFIGURATIONS = "mailing-configurations",
  MAILING_CONFIGURATION_CREATE = "mailing-configuration-create",
  MAILING_CONFIGURATION_EDIT = "mailing-configuration-edit",
}

export enum RouteNamesStudyPrograms {
  CREATE = "study-programs-create",
  EDIT = "study-programs-edit",
  MAINPAGE = "study-programs-main",
  OVERVIEW = "study-programs-overview",
  VARIABLES = "study-programs-variables",
  LOCATIONS = "study-programs-locations",
  LEVELS = "study-programs-levels",
  MODES = "study-programs-modes",
  DEPARTMENTS = "study-programs-departments",
  AREAS_OF_INTEREST = "study-programs-areas-of-interest",
}

export enum RouteNamesLocations {
  OVERVIEW = "locations-overview",
}

export enum RouteNamesLevels {
  OVERVIEW = "levels-overview",
}

export enum RouteNamesModes {
  OVERVIEW = "modes-overview",
}

export enum RouteNamesDepartments {
  OVERVIEW = "departments-overview",
}

export enum RouteNamesAreasOfInterest {
  OVERVIEW = "areas-of-interest-overview",
}

export enum RouteNamesContacts {
  MAINPAGE = "contacts-main",
  EDIT = "contacts-edit",
  MOMENTS = "contacts-moments",
  OVERVIEW = "contacts-overview",
}

export enum RouteNamesInformationRequests {
  MAINPAGE = "information-requests-main",
  BROCHURES = "brochures-overview",
  KEEPMEINFORMED = "keep-me-informed-overview",
  THIRDPARTYPROSPECTSOURCES = "third-party-prospect-sources-overview",
}

export enum RouteNamesApplicationsEnrollments {
  MAINPAGE = "applications-enrollments-main",
  APPLICATIONS = "applications-overview",
  ENROLLMENTS = "enrollments-overview",
}

export enum RouteNames {
  DASHBOARD = "dashboard",
  ORGANIZE = "organize",
  ACTIVITY_EDIT = "activity-edit",
  ACTIVITY_OVERVIEW = "activity-overview",
  ACTIVITY_SESSIONS = "activity-sessions",
  ACTIVITY_CREATE = "activity-create",
  MAILINGS = "mailings",
  LOCATIONS = "locations",
  NOT_FOUND = "not-found",
  UNAUTHORIZED = "unauthorized",
  MANAGE = "manage",
  TENANTS = "tenants",
  SETTINGS = "settings",
  CONTACTS = "contacts",
  UNSUBSCRIBED = "unsubscribed",
  INFORMATIONREQUESTS = "information-requests",
  APPLICATIONSENROLLMENTS = "applications-enrollments",
  ACTIVITY = "activity",
  ACCOUNTS_OVERVIEW = "accounts-overview",
  MAIN = "main",
  LOGIN = "login",
  LOGOUT = "logout",
  UNAVAILABLE = "unavailable",
}

export enum RouteMeta {
  NO_TENANT = "doesNotRequireTenant",
  NO_AUTH = "doesNotRequireAuthentication",
}
