<template>
  <FormLayout @submit="submitForm">
    <div class="flex flex-col gap-4">
      <LocalizationTabs
        v-slot="{ current }"
        :locales
        @add="addLocalization"
        @remove="removeLocalization"
      >
        <LocalizationSlot v-slot="{ locale }" :locales :current>
          <section class="flex flex-col gap-4">
            <h3 class="text-base font-semibold text-deepteal-700">
              {{ texts.generic.preview }}
            </h3>
            <BrowserWindow
              :icon="form.values.faviconUrl"
              :title="form.values.localizations[locale]?.title"
            >
              <template v-slot:banner>
                <img :src="form.values.bannerUrl" />
              </template>
              <div class="flex w-full flex-col gap-2 px-2">
                <h4 class="text-xl font-normal sm:text-xl">
                  {{ form.values.localizations[locale]?.title }}
                </h4>

                <p
                  class="text-sm text-gray-500"
                  v-html="form.values.localizations[locale]?.description"
                />
              </div>
            </BrowserWindow>
            <Divider />
          </section>
        </LocalizationSlot>
        <LocalizationSlot v-slot="{ locale }" :locales :current>
          <section class="flex flex-col gap-4">
            <FormFieldTextInput
              :id="`localizations.${locale}.title`"
              :label="`${texts.models.publicActivityPageSettings.pagetitle} (${
                texts.enums.culture[locale]
              })`"
            />
            <FormFieldWysiwyg
              :id="`localizations.${locale}.description`"
              :label="`${texts.models.publicActivityPageSettings.pagedescription} (${
                texts.enums.culture[locale]
              })`"
            />
          </section>
        </LocalizationSlot>
      </LocalizationTabs>
      <section class="flex flex-col gap-4">
        <h2
          v-if="!initialValues?.activityTypeId"
          class="text-lg font-semibold text-deepteal-700"
        >
          {{ texts.navigationItems.organize.styling.title }}
        </h2>
        <div class="flex items-start gap-4">
          <FormFieldTextInput
            id="color"
            :label="texts.models.publicActivityPageSettings.color"
            :type="TextInputType.COLOR"
            class="flex-1"
          />

          <FormFieldSelectNative
            id="pageWidth"
            :label="texts.models.publicActivityPageSettings.pageWidth"
            class="flex-1"
            :options="[
              { label: texts.generic.default, value: undefined },
              { label: '950 px', value: '950px' },
              { label: '1000 px', value: '1000px' },
              { label: '1050 px', value: '1050px' },
              { label: '1100 px', value: '1100px' },
              { label: '1150 px', value: '1150px' },
              { label: '1200 px', value: '1200px' },
            ]"
          />
        </div>
        <div class="flex items-start gap-4">
          <FormFieldTextInput
            id="bannerUrl"
            :label="texts.models.publicActivityPageSettings.bannerUrl"
            :type="TextInputType.URL"
            class="flex-1"
          />

          <FormFieldTextInput
            id="faviconUrl"
            :label="texts.models.publicActivityPageSettings.faviconUrl"
            :type="TextInputType.URL"
            class="flex-1"
          />
        </div>
      </section>
      <section class="flex flex-col gap-4">
        <FormFieldTextInput
          id="gtmCode"
          :label="texts.models.publicActivityPageSettings.gtmCode"
          placeholder="GTM-XXXXXX"
        />
      </section>
    </div>
    <template v-slot:actions> <slot></slot> </template>
  </FormLayout>
</template>

<script setup lang="ts">
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import FormFieldWysiwyg from "@/components/common/wysiwyg/FormFieldWysiwyg.vue";
import { Culture } from "@/enums";
import { IPublicActivityPageSettingsDTO } from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";
import { useForm } from "vee-validate";
import { computed, watch } from "vue";
import * as yup from "yup";
import FormLayout from "@/components/common/form/FormLayout.vue";
import settings from "@/store/context/settings.context";
import LocalizationTabs from "@/components/localization-tabs/LocalizationTabs.vue";
import LocalizationSlot from "@/components/localization-tabs/LocalizationSlot.vue";
import Divider from "@/components/common/divider/Divider.vue";
import BrowserWindow from "@/views/settings/forms/components/form-template-editor/BrowserWindow.vue";
import FormFieldSelectNative from "@/components/common/select/FormFieldSelectNative.vue";

export interface PublicActivityPageSettingsFormValues {
  activityTypeId?: string;
  color: string;
  bannerUrl?: string;
  faviconUrl?: string;
  pageWidth?: string;
  gtmCode?: string;
  localizations: PublicActivityPageSettingsFormValuesLocalizations;
}

type PublicActivityPageSettingsFormValuesLocalizations = Partial<
  Record<Culture, PublicActivityPageSettingsFormValuesLocalization>
>;
interface PublicActivityPageSettingsFormValuesLocalization {
  title: string;
  description?: string;
}

const props = defineProps<{
  initialValues: IPublicActivityPageSettingsDTO;
}>();

const emit = defineEmits<{
  submit: [values: PublicActivityPageSettingsFormValues];
}>();

const localizationSchema =
  yup.object<PublicActivityPageSettingsFormValuesLocalization>(
    settings.availableLanguages.reduce(
      (acc, language) => ({
        ...acc,
        [language.locale.value as Culture]: yup.lazy((value) =>
          value
            ? yup.object({
                title: yup.string().max(256).required(),
                description: yup.string().max(1024),
              })
            : yup.mixed(),
        ),
      }),
      {},
    ),
  );

const form = useForm<PublicActivityPageSettingsFormValues>({
  validationSchema: yup.object({
    activityTypeId: yup.string(),
    color: yup.string().required(),
    bannerUrl: yup.string(),
    faviconUrl: yup.string(),
    pageWidth: yup.string(),
    gtmCode: yup.string(),
    localizations: localizationSchema,
  }),
});

const submitForm = form.handleSubmit((values) => emit("submit", values));

const initializeFormValues = (newSettings: IPublicActivityPageSettingsDTO) => {
  if (newSettings) {
    const valueslocalizations: PublicActivityPageSettingsFormValuesLocalizations =
      newSettings.localizations.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.locale as Culture]: {
            title: cur.title,
            description: cur.description,
          },
        }),
        {},
      );

    const values: PublicActivityPageSettingsFormValues = {
      activityTypeId: newSettings.activityTypeId,
      color: newSettings.color,
      bannerUrl: newSettings.bannerUrl,
      faviconUrl: newSettings.faviconUrl,
      pageWidth: newSettings.pageWidth,
      gtmCode: newSettings.gtmCode,
      localizations: valueslocalizations,
    };

    form.setValues(values);
  }
};

watch(
  props.initialValues,
  (newSettings) => {
    initializeFormValues(newSettings);
  },
  { immediate: true },
);

const locales = computed(
  () => Object.keys(form.values.localizations) as Culture[],
);

function addLocalization(culture: Culture) {
  form.setFieldValue("localizations", {
    ...form.values.localizations,
    [culture]: { title: "" },
  });
}

function removeLocalization(culture: Culture) {
  const newDict = { ...form.values.localizations };
  delete newDict[culture];
  form.setFieldValue("localizations", newDict);
}
</script>
