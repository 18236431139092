<template>
  <SwitchGroup as="div" class="flex items-center">
    <Switch
      v-model="enabledInternal"
      :class="[
        { 'cursor-not-allowed opacity-50': isDisabled },
        enabledInternal ? 'bg-emerald-600' : 'bg-gray-200',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2',
      ]"
    >
      <span class="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        :class="[
          enabledInternal ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
        ]"
      />
    </Switch>
    <SwitchLabel v-if="label" as="span" class="ml-3">
      <label class="text-sm font-medium text-gray-900">{{ label }}</label>
    </SwitchLabel>
  </SwitchGroup>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";

const props = defineProps<{
  modelValue?: boolean;
  label?: string;
  disabled?: boolean;
}>();
const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
}>();

const isDisabled = computed(() => props.disabled);

const enabledInternal = computed({
  get: () => props.modelValue,
  set: (value) => {
    if (isDisabled.value) return;
    emit("update:modelValue", value);
  },
});

if (props.modelValue === undefined) {
  enabledInternal.value = false;
}
</script>
