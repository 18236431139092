<template>
  <FormLayout @submit.prevent="submitForm">
    <template v-slot:header>
      <slot name="header"></slot>
    </template>
    <FormFieldTextInput
      :id="fieldIds.name"
      :label="texts.models.affiliate.name"
    />
    <FormFieldTextInput
      :id="fieldIds.emailAddress"
      :label="texts.models.affiliate.emailAddress"
    />
    <FormFieldSingleSelectCombobox
      :displayMode="FormFieldDisplayMode.Column"
      :formFieldId="fieldIds.preEducationSchoolId"
      :options="settings.preEducationSchools"
      :formatOptionDisplayString="(s) => s.displayName"
      :searchFilter="
        (school, query) =>
          school.displayName.toLowerCase().includes(query.toLowerCase())
      "
      :label="texts.models.affiliate.preEducationSchool"
    />
    <FormFieldSelect
      :id="fieldIds.locale"
      :items="
        settings.availableLanguages.map((l) => ({
          label: l.name,
          value: l.locale.value,
        }))
      "
      :label="texts.models.affiliate.locale"
    ></FormFieldSelect>
    <template v-slot:actions>
      <slot></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import FormLayout from "@/components/common/form/FormLayout.vue";
import { IAffiliateDto } from "@/lib/eduConfigurationServiceClient";
import { useForm } from "vee-validate";
import * as yup from "yup";
import { Culture } from "@/enums";
import FormFieldSelect from "@/components/common/select/FormFieldSelect.vue";
import texts from "@/utils/texts";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import { fields } from "@/utils/miscellaneous";
import FormFieldSingleSelectCombobox from "@/components/common/combobox/FormFieldSingleSelectCombobox.vue";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import settings from "@/store/context/settings.context";
import {
  AffiliateFormValues,
  convertToFormValues,
} from "@/views/settings/organization/affiliates/AffiliateForm.types";

const props = defineProps<{
  item?: IAffiliateDto;
}>();

const fieldIds = fields<IAffiliateDto>();

const form = useForm<AffiliateFormValues>({
  validationSchema: yup.object({
    id: yup.string(),
    name: yup.string().required(),
    locale: yup.mixed<Culture>().required(),
    emailAddress: yup.string().email().required(),
    preEducationSchoolId: yup.string(),
  }),
  initialValues: props.item ? convertToFormValues(props.item) : undefined,
});

const emit = defineEmits<{
  submit: [values: IAffiliateDto];
}>();

const submitForm = form.handleSubmit((values) => emit("submit", values));
</script>
