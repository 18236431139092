<template>
  <RouterLinkAuth
    v-slot="{ navigate }"
    :to="{
      name: RouteNamesMailingConfiguration.MAILING_CONFIGURATION_EDIT,
      params: { mailingConfigurationId: item.id },
    }"
    custom
  >
    <ListItem clickable @click="navigate">
      <ListItemColumn :flex="0">
        <Icon
          :icon="mailingTypeIcon[item.type]"
          :color="mailingTypeColor[item.type]"
        />
      </ListItemColumn>

      <ListItemColumn accent>
        <span>{{ item.name }}</span>
      </ListItemColumn>
      <ListItemDivider hide />
      <ListItemColumn hide>{{ item.subject }}</ListItemColumn>
      <ListItemDivider hide />
      <ListItemColumn hide>{{ schedulingText }}</ListItemColumn>
    </ListItem>
  </RouterLinkAuth>
</template>

<script setup lang="ts">
import Icon from "@/components/common/icon/Icon.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import ListItemDivider from "@/components/common/list/ListItemDivider.vue";
import { mailingTypeColor, mailingTypeIcon } from "@/models/mailingType";
import RouterLinkAuth from "@/components/router/router-link-auth/RouterLinkAuth.vue";
import { RouteNamesMailingConfiguration } from "@/router/routeNames";
import { interpolate } from "@/dictionary";
import texts from "@/utils/texts";
import { MailingConfigurationOverviewDto } from "@/lib/eduConfigurationServiceClient";
import { computed } from "vue";
import { DateTime } from "luxon";

const props = defineProps<{
  item: MailingConfigurationOverviewDto;
}>();

const schedulingText = computed(() => {
  const days = props.item.relativePlanningDayOffset;
  const time = props.item.relativePlanningTimeOfDay;

  if (days === undefined || time === undefined) {
    return undefined;
  }
  let dayText =
    days > 0
      ? texts.models.mailingConfiguration.schedulingPeriod.relativeAfter
      : texts.models.mailingConfiguration.schedulingPeriod.relativeBefore;

  return `${interpolate(dayText, Math.abs(days).toString())}; ${interpolate(
    texts.models.mailingConfiguration.schedulingTime.value,
    DateTime.fromObject({
      hour: time.hours,
      minute: time.minutes,
    }).toLocaleString(DateTime.TIME_SIMPLE),
  )}`;
});
</script>
