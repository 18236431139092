<template>
  <RouterLinkAuth
    v-slot="{ navigate }"
    :to="{
      name: RouteNamesActivityMailingsConfiguration.MAILING_CONFIGURATIONS,
      params: { activityMailingsConfigurationId: item.id },
    }"
    custom
  >
    <ListItem clickable @click="navigate">
      <ListItemColumn :flex="2" accent> {{ props.item.name }} </ListItemColumn>
      <ListItemDivider hide />
      <ListItemColumn :flex="1" hide>
        <Dot :colorHex="activityTypeColor"></Dot>
        <span>{{ activityTypeName }}</span>
      </ListItemColumn>
      <ListItemColumn :flex="0">
        <ListItemActions @click.stop>
          <DropdownItem @click="emit('edit', item)">
            <Icon icon="edit" :color="Color.DeepTeal"></Icon>
            <span class="text-deepteal-500">{{ texts.actions.edit }}</span>
          </DropdownItem>
          <DropdownItem @click="emit('delete', item)">
            <Icon icon="delete" :color="Color.Alert"></Icon>
            <span class="text-alert-500">{{ texts.actions.delete }}</span>
          </DropdownItem>
        </ListItemActions>
      </ListItemColumn>
    </ListItem>
  </RouterLinkAuth>
</template>

<script setup lang="ts">
import Dot from "@/components/common/dot/Dot.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import settings from "@/store/context/settings.context";
import { getGuidColor } from "@/utils/color";
import { ActivityMailingsConfigurationDto } from "@/lib/eduConfigurationServiceClient";
import { computed } from "vue";
import Icon from "@/components/common/icon/Icon.vue";
import ListItemActions from "@/components/common/list/ListItemActions.vue";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import { Color } from "@/enums";
import texts from "@/utils/texts";
import RouterLinkAuth from "@/components/router/router-link-auth/RouterLinkAuth.vue";
import { RouteNamesActivityMailingsConfiguration } from "@/router/routeNames";
import ListItemDivider from "@/components/common/list/ListItemDivider.vue";

const props = defineProps<{
  item: ActivityMailingsConfigurationDto;
}>();

const emit = defineEmits<{
  edit: [ActivityMailingsConfigurationDto];
  delete: [ActivityMailingsConfigurationDto];
}>();

const activityTypeColor = computed(() =>
  getGuidColor(props.item.activityTypeId),
);

const activityTypeName = computed(() => {
  const activityType = settings.activityTypes.find(
    (activityType) => activityType.id === props.item.activityTypeId,
  );
  return activityType?.name;
});
</script>
