<template>
  <Page
    :loading="loading"
    :error="error"
    class="flex flex-col gap-16 md:flex-row"
  >
    <div class="-m-1 flex-1 truncate p-1">
      <ActivityFilter
        v-if="studyPrograms && activityTypes && activities"
        :activities="activities"
        :activityTypes="activityTypes"
        :studyPrograms="studyPrograms"
        @filter="(event) => (filteredActivities = event)"
      />
    </div>
    <div class="flex flex-2 flex-col gap-6">
      <div
        class="flex flex-col gap-5 text-left md:flex-row md:items-start md:justify-between"
      >
        <MonthPicker v-model:dateTime="selectedDateTime" grouped></MonthPicker>
        <div class="flex flex-col gap-3 md:flex-row">
          <DropdownWrapper :origin="DropdownOrigin.TopRight">
            <template v-slot:button>
              <ButtonGroup
                :color="Color.Gray"
                :size="ButtonSize.sm"
                flat
                round
                gap
              >
                <Button @click="downloadOverviewForCurrentMonth">
                  <IconAsync
                    :state="state"
                    icon="download"
                    :color="Color.Gray"
                    :size="IconSize.sm"
                  />
                  <span>
                    {{
                      texts.navigationItems.organize.downloadOverview
                        .forCurrentMonth
                    }}
                  </span>
                </Button>
                <ButtonGroupMenuButton />
              </ButtonGroup>
            </template>
            <template v-slot:items>
              <DropdownItem
                icon="download"
                :label="
                  texts.navigationItems.organize.downloadOverview
                    .forCurrentRecruitmentYear
                "
                @click="downloadOverviewForCurrentYear"
              />
            </template>
          </DropdownWrapper>
          <ButtonAdd v-if="canWrite" @click="goToCreateActivityPage" />
        </div>
      </div>
      <Async :loading="loadingActivities">
        <template v-if="filteredActivities">
          <List>
            <template v-slot:header>
              <ListItemColumn :flex="8" />
              <ListItemColumn :flex="0"
                ><div class="w-px"></div
              ></ListItemColumn>
              <ListItemColumn :flex="1" class="overflow-visible text-xs" hide>{{
                texts.models.activity.registrants
              }}</ListItemColumn>
              <ListItemColumn :flex="0" hide
                ><div class="w-px"></div
              ></ListItemColumn>
              <ListItemColumn :flex="1" class="overflow-visible text-xs" hide>{{
                texts.models.activity.visitors
              }}</ListItemColumn>
              <ListItemColumn :flex="0" hide
                ><div class="w-px"></div
              ></ListItemColumn>
              <ListItemColumn :flex="0">
                <div class="w-8"></div>
              </ListItemColumn>
            </template>
            <ActivityItem
              v-for="activity in filteredActivities"
              :key="activity.id"
              :activity="activity"
              @copy="(value) => (loading = value)"
            />
          </List>
        </template>
      </Async>
    </div>
  </Page>
</template>

<script setup lang="ts">
import { Permission } from "@/lib/eduConfigurationServiceClient";
import Page from "@/components/common/page/Page.vue";
import Activity from "@/models/activity";
import { getActivities } from "@/services/activity.service";
import { computed, ref, watch } from "vue";
import logger from "@/plugins/logger";
import { useFileDownloader } from "@/utils/downloadFile";
import { ButtonSize } from "@/components/common/button/Button.types";
import Button from "@/components/common/button/Button.vue";
import ButtonGroup from "@/components/common/button/ButtonGroup.vue";
import ButtonGroupMenuButton from "@/components/common/button/ButtonGroupMenuButton.vue";
import { Color } from "@/enums";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import DropdownWrapper from "@/components/common/dropdown/DropdownWrapper.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import IconAsync from "@/components/common/icon/IconAsync.vue";
import Async from "@/components/common/loader/Async.vue";
import ActivityItem from "./components/ActivityItem.vue";
import ActivityFilter from "./components/ActivityFilter.vue";
import ActivityType from "@/models/activityType";
import { getActivityTypes } from "@/services/activityTypes.service";
import ButtonAdd from "@/components/common/button/ButtonAdd.vue";
import { useRouter } from "vue-router";
import { RouteNames } from "@/router/routeNames";
import settings from "@/store/context/settings.context";
import { DateTime } from "luxon";
import MonthPicker from "@/components/common/monthpicker/MonthPicker.vue";
import { hasPermissions } from "@/router/helpers/userCanGoTo";
import { Serializer, useSessionStorage } from "@vueuse/core";
import { SessionStorageKey } from "@/enums";
import texts from "@/utils/texts";
import List from "@/components/common/list/List.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { getCurrentRecruitmentYear } from "@/utils/recruitmentYear";

const luxonSerializer: Serializer<DateTime<true>> = {
  read: (raw: string) => {
    const date = DateTime.fromISO(raw);
    if (!date.isValid) throw new Error("Invalid date");
    return date;
  },
  write: (v: DateTime<true>) => v.toISO(),
};

const selectedDateTime = useSessionStorage(
  SessionStorageKey.ActivitySelectedDateTime,
  DateTime.now(),
  {
    serializer: luxonSerializer,
  },
);

const monthEarliestDate = computed(() =>
  selectedDateTime.value.startOf("month").startOf("day"),
);

const monthLatestDate = computed(() =>
  selectedDateTime.value.endOf("month").endOf("day"),
);

const canWrite = hasPermissions([Permission.ActivitiesWrite]);

const loading = ref(true);
const error = ref(false);

const activities = ref<Activity[]>();
const filteredActivities = ref<Activity[]>();
const loadingActivities = ref(false);

watch(selectedDateTime, async () => {
  getActivitiesForMonth();
});

const getActivitiesForMonth = async () => {
  loadingActivities.value = true;
  try {
    const activityDtos = await getActivities(null, true, null, {
      minEndDateTime: monthEarliestDate.value.toISO() ?? undefined,
      maxStartDateTime: monthLatestDate.value.toISO() ?? undefined,
    });
    activities.value = activityDtos.map((dto) => new Activity(dto));
  } catch (e) {
    error.value = true;
    logger.error(e);
  }
  loadingActivities.value = false;
};

const activityTypes = ref<ActivityType[]>();
const studyPrograms = settings.studyPrograms;

const { state, downloadExport } = useFileDownloader();

const downloadOverviewForCurrentMonth = () => {
  downloadExport(
    () => getActivitiesExport(monthEarliestDate.value, monthLatestDate.value),
    texts.navigationItems.brochures.downloadRequests,
  );
};

const recruitmentYear = getCurrentRecruitmentYear();
const downloadOverviewForCurrentYear = () => {
  downloadExport(
    () => getActivitiesExport(recruitmentYear.start, recruitmentYear.end),
    texts.navigationItems.brochures.downloadRequests,
  );
};

const getActivitiesExport = (from: DateTime, to: DateTime) =>
  eduConfigurationServiceClient.getActivitiesExport(
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    to,
    from,
    undefined,
  );

const initialize = async () => {
  try {
    activityTypes.value = await getActivityTypes();
    await getActivitiesForMonth();
  } catch (e) {
    error.value = true;
    logger.error(e);
  }
  loading.value = false;
};
initialize();

const router = useRouter();
const goToCreateActivityPage = () => {
  router.push({ name: RouteNames.ACTIVITY_EDIT });
};
</script>
