<template>
  <Notifications
    class="max-w-md pt-4"
    width="100%"
    position="bottom left"
    :duration="5000"
    :speed="500"
  >
    <template v-slot:body="{ item, close }">
      <NotificationComponent
        :type="item.type"
        :title="item.title"
        :content="item.text"
        :close="close"
      />
    </template>
  </Notifications>
  <component :is="layout">
    <RefreshableRouterView />
  </component>
</template>

<script setup lang="ts">
import NotificationComponent from "@/components/common/notification/Notification.vue";
import RefreshableRouterView from "@/components/router/RefreshableRouterView.vue";
import { layout } from "@/router/guards/layoutGuard";
import { setAuthContext } from "@/store/context/auth.context";
import { useAuth0 } from "@auth0/auth0-vue";
import { Notifications } from "@kyvg/vue3-notification";

const { getAccessTokenSilently, user } = useAuth0();

setTimeout(async () => {
  if (!user.value) return;
  const token = await getAccessTokenSilently();
  setAuthContext(token, user.value);
}, 60000);
</script>
