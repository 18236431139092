import { Culture } from "@/enums";
import {
  CreateAffiliateDto,
  IAffiliateDto,
  UpdateAffiliateDto,
} from "@/lib/eduConfigurationServiceClient";

export type AffiliateFormValues = {
  id: string;
  name: string;
  locale: Culture;
  emailAddress: string;
  preEducationSchoolId?: string;
};

export function convertToFormValues(item: IAffiliateDto): AffiliateFormValues {
  return {
    id: item.id,
    name: item.name,
    locale: item.locale as Culture,
    emailAddress: item.emailAddress,
    preEducationSchoolId: item.preEducationSchoolId,
  };
}

export function convertToCreateDto(values: AffiliateFormValues) {
  return new CreateAffiliateDto(values);
}

export function convertToUpdateDto(values: AffiliateFormValues) {
  return new UpdateAffiliateDto(values);
}
