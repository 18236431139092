<template>
  <Error v-if="error" />
  <Loader v-if="isLoading" />
  <FormLayout v-else-if="selectionContext" @submit="handleSubmit">
    <h2 class="text-xl font-semibold">
      {{ componentTexts.basicInformation }}
    </h2>
    <FormFieldTextInput :id="fieldIds.name" :label="componentTexts.name" />
    <FormFieldTextInput
      :id="fieldIds.description"
      :label="componentTexts.description"
    />
    <h2 class="text-xl font-semibold">
      {{ componentTexts.selectionCriteria }}
    </h2>
    <SelectionDefinition
      v-model="selectionDefinition"
      :context="selectionContext"
      :exclude="[
        SelectionCriterionDtoType.IsRegisteredForSession,
        SelectionCriterionDtoType.IsInTargetAudience,
        SelectionCriterionDtoType.IsNotTargetAudience,
      ]"
      nofilter
    >
      <Icon icon="stacks" :size="IconSize.lg" class="ml-2 text-deepteal-600" />
    </SelectionDefinition>

    <template v-slot:actions>
      <slot></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import {
  SelectionCriterionDtoType,
  SelectionDefinitionDto,
  SelectionDefinitionDtoResponseType,
  TargetAudienceDTO,
} from "@/lib/eduConfigurationServiceClient";
import { useForm } from "vee-validate";
import { fields } from "@/utils/miscellaneous";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import texts from "@/utils/texts";
import { loadCriterionFieldContext } from "@/components/selection/SelectionCriterionForm.context";
import SelectionDefinition from "@/components/selection/SelectionDefinition.vue";
import * as yup from "yup";
import { ref } from "vue";
import Icon from "@/components/common/icon/Icon.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import {
  TargetAudienceFormValues,
  TargetAudienceSubmitValues,
} from "@/views/settings/organization/target-audiences/TargetAudienceForm.types";
import { useAsyncState } from "@vueuse/core";
import Loader from "@/components/common/loader/Loader.vue";
import FormLayout from "@/components/common/form/FormLayout.vue";
import Error from "@/components/common/error/Error.vue";

const props = defineProps<{
  item?: TargetAudienceDTO;
}>();

const emit = defineEmits<{
  submit: [TargetAudienceSubmitValues];
}>();

const componentTexts = texts.navigationItems.organize.targetAudiences.form;

const {
  state: selectionContext,
  isLoading,
  error,
} = useAsyncState(loadCriterionFieldContext, undefined, {
  immediate: true,
});

const form = useForm<TargetAudienceFormValues>({
  initialValues: {
    name: props.item?.name ?? "",
    description: props.item?.description ?? "",
  },
  validationSchema: yup.object({
    name: yup.string().required(),
    description: yup.string().required(),
  }),
});

const selectionDefinition = ref<SelectionDefinitionDto>(
  props.item?.selectionDefinition ??
    new SelectionDefinitionDto({
      responseType: SelectionDefinitionDtoResponseType.Prospects,
      criteria: [],
      filters: {
        studyProgramFilter: {
          studyProgramIds: [],
          activityIds: [],
        },
        activityIds: [],
        dateRange: undefined,
      },
    }),
);

const fieldIds = fields<TargetAudienceFormValues>();

const handleSubmit = form.handleSubmit((values) => {
  emit("submit", {
    ...values,
    selectionDefinition: selectionDefinition.value,
  });
});
</script>
