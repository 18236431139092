<template>
  <TransitionFade>
    <Card v-if="regLinks" class="flex flex-col gap-2">
      <CardHeader :text="textsReports.registrationsPerLink.title"></CardHeader>
      <List>
        <template v-slot:header>
          <ListItemColumnBase :flex="2">
            {{ texts.models.registrationLink.title }}
          </ListItemColumnBase>
          <ListItemColumnBase>
            # {{ textsReports.registrantCount }}
          </ListItemColumnBase>
        </template>
        <ListItemBase
          v-for="regLink in regLinks"
          :key="regLink.id"
          noBackground
        >
          <ListItemColumnBase :flex="2">{{ regLink.name }}</ListItemColumnBase>
          <ListItemColumnBase>
            <span :class="[getTextColor(Color.Lavender), 'w-12 font-bold']">{{
              regLink.registrationCount
            }}</span>
            <RegistrationLinkDownloadButton :registrationLinkId="regLink.id" />
          </ListItemColumnBase>
        </ListItemBase>
      </List>
    </Card>
  </TransitionFade>
</template>

<script setup lang="ts">
import { RegistrationLinkWithRegistrationCountDTO } from "@/lib/eduConfigurationServiceClient";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import RegistrationLinkDownloadButton from "./RegistrationLinkDownloadButton.vue";
import { ref, watch } from "vue";
import texts from "@/utils/texts";
import List from "@/components/common/list/List.vue";
import ListItemColumnBase from "@/components/common/list/ListItemColumnBase.vue";
import ListItemBase from "@/components/common/list/ListItemBase.vue";
import CardHeader from "@/components/common/card/CardHeader.vue";
import Card from "@/components/common/card/Card.vue";
import { getTextColor } from "@/utils/color";
import TransitionFade from "@/components/common/transition/TransitionFade.vue";
import { Color } from "@/enums";

const props = defineProps<{ activityId: string }>();

const textsReports = texts.navigationItems.organize.activity.reports;

const regLinks = ref<RegistrationLinkWithRegistrationCountDTO[]>();

watch(
  () => props.activityId,
  async (value) => {
    const dto =
      await eduConfigurationServiceClient.getAllRegistrationLinksWithRegistrationCounts(
        value,
      );

    if (dto.length > 0 && dto.some((r) => r.registrationCount > 0)) {
      regLinks.value = dto;
    }
  },
  { immediate: true },
);
</script>
