<template>
  <ModelPageBuilder
    :modelTexts="{
      title: texts.models.activityMailingsConfiguration.title,
      plural: texts.models.activityMailingsConfiguration.plural,
    }"
    :list
    :createItem
    :editItem
    :deleteItem
  />
</template>

<script setup lang="ts">
import ModelPageBuilder from "@/components/model-page-builder/ModelPageBuilder.vue";
import texts from "@/utils/texts";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import ActivityMailingsConfigurationForm, {
  ActivityMailingsConfigurationFormValues,
} from "@/views/settings/activity-mailings-configurations/ActivityMailingsConfigurationForm.vue";
import ActivityMailingsConfigurationListItemHeader from "@/views/settings/activity-mailings-configurations/ActivityMailingsConfigurationListItemHeader.vue";
import ActivityMailingsConfigurationListItem from "@/views/settings/activity-mailings-configurations/ActivityMailingsConfigurationListItem.vue";
import {
  CreateActivityMailingsConfigurationDto,
  UpdateActivityMailingsConfigurationDto,
} from "@/lib/eduConfigurationServiceClient";
import settings from "@/store/context/settings.context";

const list = {
  load: async () =>
    (await eduConfigurationServiceClient.getActivityMailingsConfigurations())
      .toSorted((a, b) => a.name.localeCompare(b.name))
      .toSorted((a, b) => {
        const actTypeNames = Object.fromEntries(
          settings.activityTypes.map((s) => [s.id, s.name]),
        );
        return actTypeNames[a.activityTypeId].localeCompare(
          actTypeNames[b.activityTypeId],
        );
      }),
  item: ActivityMailingsConfigurationListItem,
  header: ActivityMailingsConfigurationListItemHeader,
};

const createItem = {
  form: {
    component: ActivityMailingsConfigurationForm,
    submit: async (values: ActivityMailingsConfigurationFormValues) =>
      eduConfigurationServiceClient.createActivityMailingsConfiguration(
        convertFormToCreateDTO(values),
      ),
  },
};

const editItem = {
  form: {
    load: (id: string) =>
      eduConfigurationServiceClient.getActivityMailingsConfiguration(id),
    component: ActivityMailingsConfigurationForm,
    submit: (id: string, values: ActivityMailingsConfigurationFormValues) =>
      eduConfigurationServiceClient.updateActivityMailingsConfiguration(
        id,
        convertFormToUpdateDTO(values),
      ),
  },
};

const deleteItem = {
  submit: (id: string) =>
    eduConfigurationServiceClient.deleteActivityMailingsConfiguration(id),
};

function convertFormToCreateDTO(form: ActivityMailingsConfigurationFormValues) {
  return new CreateActivityMailingsConfigurationDto({
    name: form.name,
    activityTypeId: form.activityTypeId,
  });
}

function convertFormToUpdateDTO(form: ActivityMailingsConfigurationFormValues) {
  return new UpdateActivityMailingsConfigurationDto({
    name: form.name,
    activityTypeId: form.activityTypeId,
  });
}
</script>
