<template>
  <FormLayout @submit="submitForm">
    <FormFieldArrayHelper
      v-slot="{ field, key }"
      :fieldId="fieldId"
      :fields="localizations"
    >
      <FormFieldTextInput
        :id="`${key}.name`"
        :label="`${texts.models.activityLocation.name} (${
          texts.enums.culture[field.locale]
        })`"
        :data-testid="`name_${field.locale}`"
      />
    </FormFieldArrayHelper>
    <template v-slot:actions>
      <slot></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import { useForm } from "vee-validate";
import FormLayout from "@/components/common/form/FormLayout.vue";
import { ActivityTypeDTO } from "@/lib/eduConfigurationServiceClient";
import {
  ActivityTypeFormValues,
  ActivityTypeFormValuesLocalization,
  validationSchema,
} from "@/views/settings/organization/activity-types/ActivityTypeForm.types";
import { useLocalizations } from "@/models/helpers/localizable";
import FormFieldArrayHelper from "@/components/common/form/FormFieldArrayHelper.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import texts from "@/utils/texts";

const props = defineProps<{
  item?: ActivityTypeDTO;
}>();

const emit = defineEmits<{
  submit: [ActivityTypeFormValues];
}>();

const form = useForm<ActivityTypeFormValues>({ validationSchema });

const { fieldId, localizations } = useLocalizations(
  props.item?.localizations as ActivityTypeFormValuesLocalization[],
);

const submitForm = form.handleSubmit((values) => emit("submit", values));
</script>
