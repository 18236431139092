<template>
  <div class="flex flex-col gap-4">
    <div class="flex items-center gap-8">
      <Dropdown
        :label="texts.actions.chooseTemplate"
        :origin="DropdownOrigin.TopLeft"
      >
        <DropdownItem
          v-for="option in templateOptions"
          :key="option.value"
          :label="option.label"
          class="w-full"
          @click="selectedTemplate = option.value"
        ></DropdownItem>
      </Dropdown>
      <Toggle v-model="bee.state.preview" :label="texts.actions.showPreview" />
    </div>
    <div
      id="bee-container"
      class="animation-pulse h-beeEditor w-full border border-deepteal-100 bg-deepteal-100 shadow"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import Dropdown from "@/components/common/dropdown/Dropdown.vue";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import Toggle from "@/components/common/toggle/Toggle.vue";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { BeeInstanceValues } from "@/utils/bee/bee-instance";
import texts from "@/utils/texts";
import { MailingConfigurationFormValues } from "@/views/settings/activity-mailings-configurations/mailing-configuration/mailing-configuration-form/MailingConfigurationForm.vue";
import { IEntityContentJson } from "@beefree.io/sdk/dist/types/bee";
import { useAsyncState } from "@vueuse/core";
import { FormContext } from "vee-validate";
import { computed, ref, watch } from "vue";

const form = defineModel<FormContext<MailingConfigurationFormValues>>("form", {
  required: true,
});

const bee = defineModel<BeeInstanceValues>("bee", { required: true });

const { state: templates } = useAsyncState(
  () =>
    eduConfigurationServiceClient.getMailingTemplates(
      undefined,
      undefined,
      undefined,
    ),
  [],
);

const selectedTemplate = ref<string | undefined>();
const templateOptions = computed(() =>
  templates.value
    .filter(
      (template) =>
        !form.value.values.type ||
        template.mailingType === form.value.values.type,
    )
    .map((template) => ({
      value: template.id,
      label: template.name,
    })),
);

watch(selectedTemplate, async (id) => {
  if (!id) return;

  const template =
    await eduConfigurationServiceClient.getMailingTemplateById(id);

  bee.value.load(JSON.parse(template.configuration) as IEntityContentJson);
});
</script>
