<template>
  <div
    :class="[
      'rounded-lg print:break-inside-avoid',
      getBgColor(color, !dark),
      getTextColor(textColor),
      gradientColor,
      flat ? '' : 'shadow',
      'border border-gray-100',
      {
        'p-1 sm:p-2': size === CardSize.xs,
        'p-2 sm:p-4': size === CardSize.sm,
        'p-4 sm:p-6': size === CardSize.md,
        'p-6 sm:p-8': size === CardSize.lg,
        'p-8 sm:p-12': size === CardSize.xl,
      },
    ]"
    :style="imageStyle"
  >
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { CardSize } from "./Card.types";
import { Color } from "@/enums";
import {
  getBgColor,
  getForegroundColor,
  getFromColor,
  getTextColor,
} from "@/utils/color";

const props = withDefaults(
  defineProps<{
    size?: CardSize;
    color?: Color;
    flat?: boolean;
    colorIndicator?: Color;
    dark?: boolean;
    image?: string;
  }>(),
  {
    size: CardSize.md,
    color: Color.White,
    flat: false,
    colorIndicator: undefined,
    dark: false,
    image: undefined,
  },
);

const imageStyle = computed(() =>
  props.image
    ? {
        "background-image": `url(${props.image})`,
        "background-position": "bottom",
        "background-repeat": "no-repeat",
        "background-size": " 100% auto",
      }
    : undefined,
);

const gradientColor = computed(() =>
  props.colorIndicator
    ? [
        `bg-gradient-to-r ${getFromColor(
          props.colorIndicator,
        )}  to-transparent`,
        {
          "from-[2px] to-[2px]": props.size === CardSize.xs,
          "from-[3px] to-[3px]": props.size === CardSize.sm,
          "from-[4px] to-[4px]": props.size === CardSize.md,
          "from-[5px] to-[5px]": props.size === CardSize.lg,
          "from-[6px] to-[6px]": props.size === CardSize.xl,
        },
      ]
    : undefined,
);

const textColor = computed(() => {
  if (props.dark || [Color.Black, Color.White].includes(props.color)) {
    return getForegroundColor(props.color);
  } else {
    return props.color;
  }
});
</script>
