<template>
  <Page :loading="isLoading" :error="!!error" class="flex flex-col gap-8">
    <PageHeading>
      {{ texts.navigationItems.manage.settings.navigationTabs.accounts }}
    </PageHeading>
    <div class="grid grid-cols-1 items-center gap-4 xl:grid-cols-4">
      <div>
        <Search v-model="search" :placeholder="texts.actions.search" />
      </div>

      <div class="flex items-center gap-4 xl:col-start-4 xl:justify-self-end">
        <div class="flex items-center gap-2">
          <Checkbox
            id="blocked-accounts"
            v-model="includeBlockedAccounts"
            data-testid="blocked-accounts"
          />
          <label
            for="blocked-accounts"
            class="text-sm font-medium text-gray-500"
            >{{ texts.navigationItems.accounts.includeBlockedAccounts }}</label
          >
        </div>
        <ButtonAdd @click="createOpen = true" />
      </div>
    </div>

    <List>
      <template v-slot:header>
        <ListItemColumn :flex="0"><div class="w-10"></div></ListItemColumn>
        <ListItemColumn :flex="2">{{
          texts.navigationItems.accounts.accountTable.headers.account
        }}</ListItemColumn>
        <ListItemColumn :flex="1" hide>{{
          texts.navigationItems.accounts.accountTable.headers.role
        }}</ListItemColumn>
        <ListItemColumn :flex="1" hide>{{
          texts.navigationItems.accounts.accountTable.headers.accountType
        }}</ListItemColumn>
        <ListItemColumn :flex="1" hide>{{
          texts.navigationItems.accounts.accountTable.headers.status
        }}</ListItemColumn>
        <ListItemColumn :flex="0"><div class="w-6"></div></ListItemColumn>
      </template>
      <AccountListItem
        v-for="account in filteredAccounts"
        :key="account.id"
        :account="account"
        @clicked:details="(id) => (editingId = id)"
        @block:succeeded="loadAccounts"
        @delete:succeeded="loadAccounts"
      />
    </List>
    <AccountCreate
      v-model:visible="createOpen"
      @create:succeeded="loadAccounts"
    />
    <AccountEdit v-model:id="editingId" @edit:succeeded="loadAccounts" />
  </Page>
</template>

<script setup lang="ts">
import ButtonAdd from "@/components/common/button/ButtonAdd.vue";
import Search from "@/components/common/search/Search.vue";
import Checkbox from "@/components/common/checkbox/Checkbox.vue";
import { computed, ref } from "vue";
import Account from "@/models/account";
import List from "@/components/common/list/List.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import AccountListItem from "@/views/settings/users/AccountListItem.vue";
import texts from "@/utils/texts";
import { getAllAccounts } from "@/services/account.service";
import { SessionStorageKey } from "@/enums";
import { useAsyncState, useSessionStorage } from "@vueuse/core";
import Page from "@/components/common/page/Page.vue";
import AccountCreate from "@/views/settings/users/AccountCreate.vue";
import AccountEdit from "@/views/settings/users/AccountEdit.vue";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";

const {
  state: accounts,
  isLoading,
  error,
  execute: loadAccounts,
} = useAsyncState(
  () => getAllAccounts().then((acc) => acc.map((dto) => new Account(dto))),
  [],
);

const filteredAccounts = computed<Account[]>(() =>
  accounts.value.filter(
    (account: Account) =>
      (search.value == undefined ||
        account.name.toLowerCase().includes(search.value.toLowerCase()) ||
        account.email.toLowerCase().includes(search.value.toLowerCase())) &&
      (includeBlockedAccounts.value || !account.isBlocked),
  ),
);

const createOpen = ref(false);
const editingId = ref<string>();

const search = useSessionStorage<string | undefined>(
  SessionStorageKey.AccountsSearchTerm,
  undefined,
);

const includeBlockedAccounts = ref<boolean>(false);
</script>
