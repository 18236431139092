<template>
  <FormLayout @submit="submitForm">
    <FormFieldArrayHelper
      v-slot="{ field, key }"
      :fieldId="fieldId"
      :fields="localizations"
    >
      <FormFieldTextInput
        :id="`${key}.name`"
        :label="`${texts.models.activityLocation.name} (${
          texts.enums.culture[field.locale]
        })`"
        :data-testid="`name_${field.locale}`"
      />
    </FormFieldArrayHelper>
    <template v-slot:actions>
      <slot></slot>
    </template>
  </FormLayout>
</template>
<script setup lang="ts">
import { useForm } from "vee-validate";
import * as yup from "yup";
import {
  SessionTypeFormValues,
  SessionTypeFormValuesLocalization,
} from "./SessionTypeForm.types";
import FormLayout from "@/components/common/form/FormLayout.vue";
import texts from "@/utils/texts";
import FormFieldArrayHelper from "@/components/common/form/FormFieldArrayHelper.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import { useLocalizations } from "@/models/helpers/localizable";
import { SessionTypeDto } from "@/lib/eduConfigurationServiceClient";
import { Culture } from "@/enums";

const props = defineProps<{ item?: SessionTypeDto }>();

const emit = defineEmits<{
  submit: [SessionTypeFormValues];
}>();

const form = useForm<SessionTypeFormValues>({
  validationSchema: yup.object({
    localizations: yup.array().of(
      yup.object({
        locale: yup.mixed<Culture>().required(),
        name: yup.string().requiredWhenMainLocale(),
      }),
    ),
  }),
});

const { fieldId, localizations } = useLocalizations(
  props.item?.localizations as SessionTypeFormValuesLocalization[],
);

const submitForm = form.handleSubmit((values) => emit("submit", values));
</script>
