<template>
  <div
    :class="[
      'rounded-full',
      {
        'h-1.5 w-1.5': size === 'xs',
        'h-3 w-3': size === 'sm',
        'h-4 w-4': size === 'md',
        'h-5 w-5': size === 'lg',
        'h-6 w-6': size === 'xl',
      },
    ]"
    :style="{
      backgroundColor: colorHex,
      boxShadow: boxShadowSize
        ? `inset 0 0 0 ${boxShadowSize} rgb(255 255 255 / 0.8)`
        : undefined,
    }"
  ></div>
</template>

<script setup lang="ts">
import { Size } from "@/enums";
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    colorHex?: string;
    size?: Size;
  }>(),
  {
    colorHex: undefined,
    size: "md",
  },
);

const boxShadowSize = computed(() => {
  switch (props.size) {
    case "sm":
      return "2px";
    case "md":
      return "2.5px";
    case "lg":
      return "3px";
    case "xl":
      return "4px";
    default:
      return undefined;
  }
});
</script>
