<template>
  <ApplicationDrawer v-model:visible="drawer" />
  <div>
    <div class="flex h-16 justify-between bg-white shadow">
      <button
        class="border-r border-gray-200 bg-white px-5 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-lavender-500 md:hidden"
        @click="() => (drawer = true)"
      >
        <span class="sr-only">Open sidebar</span>
        <Icon filled icon="menu" aria-hidden="true" />
      </button>
      <div class="flex">
        <CultureSettings
          :origin="ApplicationMenuOrigin.TopRight"
        ></CultureSettings>
        <ApplicationMenu
          class="px-5"
          :origin="ApplicationMenuOrigin.TopRight"
          showName
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import ApplicationDrawer from "./ApplicationDrawer.vue";
import { ApplicationMenuOrigin } from "./ApplicationMenu.types";
import ApplicationMenu from "./ApplicationMenu.vue";
import Icon from "@/components/common/icon/Icon.vue";
import CultureSettings from "@/components/layout/application-layout/CultureSettings.vue";

const drawer = ref(false);
</script>
