<template>
  <header class="flex flex-col gap-2">
    <RouterLinkAuthWithIcon
      :data-testId="testIds.backToOverview"
      :to="{ name: RouteNames.CONTACTS }"
      icon="arrow_left_alt"
      :text="texts.actions.backToOverview"
    >
    </RouterLinkAuthWithIcon>
    <PageHeading
      icon="account_circle"
      :title="fullName"
      :badgeType="contactBadge?.type"
      :badgeLabel="contactBadge?.label"
      :badgeIcon="contactBadge?.icon"
    >
      <template v-slot:actions>
        <DropdownWrapper
          v-if="prospect.anonymization == AnonymizationState.NotAnonymized"
          :origin="DropdownOrigin.TopRight"
          class="h-6"
        >
          <template v-slot:button>
            <MenuButton :data-testid="testIds.actions">
              <Icon :size="IconSize.lg" icon="more_vert" :color="Color.Emerald">
              </Icon>
            </MenuButton>
          </template>
          <template v-slot:items>
            <DropdownItem
              :data-testid="testIds.action.edit"
              :label="texts.navigationItems.contact.actions.anonymize"
              icon="person_off"
              @click="anomymizationSlideOverOpen = true"
            ></DropdownItem>
            <DropdownItem
              :label="componentTexts.addEnrollment.title"
              icon="school"
              @click="slideOverAddEnrollmentOpen = true"
            ></DropdownItem>
          </template>
        </DropdownWrapper>
      </template>
    </PageHeading>
  </header>
  <SlideOver
    v-model:visible="anomymizationSlideOverOpen"
    :title="texts.navigationItems.contact.anonymization.title"
    :subTitle="texts.navigationItems.contact.anonymization.subtitle"
  >
    <ContactAnonymizeForm
      :contact="prospect"
      @anonymized="
        anomymizationSlideOverOpen = false;
        emit('anonymized');
      "
    />
  </SlideOver>
  <SlideOver
    v-model:visible="slideOverAddEnrollmentOpen"
    :title="componentTexts.addEnrollment.title"
    :subTitle="componentTexts.addEnrollment.subTitle"
  >
    <ContactMomentAddEnrollmentForm
      :prospect="prospect"
      @added:enrollment="
        slideOverAddEnrollmentOpen = false;
        emit('added:enrollment');
      "
    >
      <ButtonSubmit></ButtonSubmit>
      <ButtonCancel @click="slideOverAddEnrollmentOpen = false" />
    </ContactMomentAddEnrollmentForm>
  </SlideOver>
</template>

<script setup lang="ts">
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import DropdownWrapper from "@/components/common/dropdown/DropdownWrapper.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import Icon from "@/components/common/icon/Icon.vue";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import RouterLinkAuthWithIcon from "@/components/router/router-link-auth/RouterLinkAuthWithIcon.vue";
import { Color } from "@/enums";
import { AnonymizationState } from "@/lib/eduConfigurationServiceClient";
import { Prospect } from "@/models/contact";
import { ContactMoment } from "@/models/contact-moment";
import { RouteNames } from "@/router/routeNames";
import { testIds } from "@/utils/testing";
import texts from "@/utils/texts";
import ContactAnonymizeForm from "@/views/contacts/components/ContactAnonymizeForm.vue";
import ContactMomentAddEnrollmentForm from "@/views/contacts/components/ContactMomentAddEnrollmentForm.vue";
import { getContactBadge } from "@/views/contacts/Contact.helpers";
import { MenuButton } from "@headlessui/vue";
import { computed, ref } from "vue";

const componentTexts = texts.navigationItems.contact.contactMoments;

const props = defineProps<{
  prospect: Prospect;
  contactMoments?: ContactMoment[];
}>();

const emit = defineEmits<{
  anonymized: [];
  "added:enrollment": [];
}>();

const fullName = computed<string>(
  () =>
    `${props.prospect?.firstName} ${
      props.prospect?.infix
        ? `${props.prospect?.infix} ${props.prospect?.lastName}`
        : props.prospect?.lastName
    }`,
);

const contactBadge = computed(() =>
  getContactBadge(props.prospect, props.contactMoments),
);

const anomymizationSlideOverOpen = ref<boolean>(false);
const slideOverAddEnrollmentOpen = ref<boolean>(false);
</script>
