<template>
  <Modal
    v-model:visible="visible"
    :type="ModalType.danger"
    :title="interpolate(texts.actions.deleteItem, modelTexts.title)"
    :description="
      interpolate(texts.actions.confirmDelete.text, modelTexts.title)
    "
  >
    <template v-slot:buttons>
      <ButtonDelete
        :loading="loading"
        :disabled="!visible"
        :size="ButtonSize.sm"
        @click="onSubmitDelete"
      />
      <ButtonCancel :disabled="loading" :size="ButtonSize.sm" @click="reset" />
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ButtonSize } from "@/components/common/button/Button.types";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ButtonDelete from "@/components/common/button/ButtonDelete.vue";
import { ModalType } from "@/components/common/modal/Modal.types";
import Modal from "@/components/common/modal/Modal.vue";
import {
  DeleteItemProps,
  ModelTextProps,
} from "@/components/model-page-builder/ModelPageBuilder.vue";
import { interpolate } from "@/dictionary";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import { ref } from "vue";

const props = defineProps<{
  modelTexts: ModelTextProps;
  deleteItem: DeleteItemProps;
}>();

const emit = defineEmits<{
  deleted: [];
}>();

const id = defineModel<string>();
const visible = defineModel<boolean>("visible", { required: true });
const loading = ref(false);

async function onSubmitDelete() {
  try {
    if (!props.deleteItem) {
      throw new Error("Delete is not defined");
    }
    if (!id.value) {
      throw new Error("DeleteId is not defined");
    }
    loading.value = true;
    await props.deleteItem.submit(id.value);
    Notify.success(texts.notifications.delete.success, [
      props.modelTexts.title,
    ]);
    reset();
    emit("deleted");
  } catch (e) {
    Notify.failure(texts.notifications.delete.failure, [
      props.modelTexts.title,
    ]);
    throw e;
  } finally {
    loading.value = false;
  }
}

function reset() {
  id.value = undefined;
  visible.value = false;
}
</script>
