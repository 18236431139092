import { MailingType } from "@/enums";
import {
  ActivityInviteMailingDTO,
  LocalizedActivityDTO,
  SelectionDefinitionDto,
} from "@/lib/eduConfigurationServiceClient";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import settings from "@/store/context/settings.context";
import { DateTime } from "luxon";

export interface MailingStatisticsBasicData {
  type?: MailingType;
  localeName: string;
  name?: string;
  activity?: LocalizedActivityDTO;
  activityId?: string;
  activityName?: string;
  registrationLinkName?: string;
  subject?: string;
  fromName?: string;
  fromEmailAddress?: string;
  mailingContent?: string;
  selectionDefinition?: SelectionDefinitionDto;
  sentDateTime?: DateTime;
}

export interface MailingStatsItem {
  title: string;
  number: number;
  percentage: number;
}

export async function getMailingStatisticsBasicDataFromMailing(
  mailing: ActivityInviteMailingDTO,
): Promise<MailingStatisticsBasicData> {
  if (!mailing.selectionDefinitionId) {
    throw new Error("Selection definition ID is missing");
  }

  const selectionDefinition: SelectionDefinitionDto =
    await eduConfigurationServiceClient.getSelectionDefinition(
      mailing.selectionDefinitionId,
    );

  const language = settings.availableLanguages.find(
    (lang) => lang.locale.value === mailing.locale,
  )?.name;
  if (!language) {
    throw new Error(`Language with locale ${mailing.locale} not found`);
  }

  let activity: LocalizedActivityDTO | undefined;
  if (mailing.activityId) {
    const activities = await eduConfigurationServiceClient.getActivities(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    activity = activities.find((a) => a.id === mailing.activityId);
  }

  return {
    type: mailing.type,
    activity: activity,
    activityId: mailing.activityId,
    activityName: mailing.activityName,
    localeName: language,
    name: mailing.name,
    subject: mailing.subject,
    fromEmailAddress: mailing.fromEmailAddress,
    fromName: mailing.fromName,
    mailingContent: mailing.mailingContent?.content,
    sentDateTime: mailing.sentDateTime,
    selectionDefinition: selectionDefinition,
  };
}
