<template>
  <div
    class="flex screen:h-screen screen:overflow-hidden screen:bg-[#F2F6F4] screen:bg-gradient-to-b screen:from-white/50 screen:to-transparent"
    :data-testid="testIds.applicationShell"
  >
    <div v-if="slots.sidebar" class="hidden md:block print:hidden">
      <slot name="sidebar"></slot>
    </div>
    <div class="flex-1">
      <div v-if="slots.topbar" class="block md:hidden print:hidden">
        <slot name="topbar"></slot>
      </div>
      <div
        class="flex h-full w-full flex-1 flex-col items-center justify-center overflow-y-scroll"
      >
        <main
          :data-testid="testIds.applicationMainView"
          :class="[
            'h-full w-full flex-1',
            {
              'screen:sm:max-w-screen-3xl': contentSize === 'xl',
              'screen:sm:max-w-screen-2xl': contentSize === 'lg',
              'screen:sm:max-w-screen-xl': contentSize === 'md',
              'screen:sm:max-w-screen-lg': contentSize === 'sm',
              'screen:sm:max-w-screen-md': contentSize === 'xs',
            },
          ]"
        >
          <div class="flex flex-col px-4 py-4 md:px-12 md:py-8">
            <slot></slot>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Size } from "@/enums";
import { testIds } from "@/utils/testing";

withDefaults(
  defineProps<{
    contentSize?: Size;
  }>(),
  {
    contentSize: "lg",
  },
);

const slots = defineSlots<{
  sidebar: [];
  topbar: [];
  default: [];
}>();
</script>
