<template>
  <form class="flex flex-col gap-8" @submit="submitForm">
    <LocalizationTabs
      v-slot="{ current }"
      v-model="activeCulture"
      :locales
      @add="addLocale"
      @remove="removeLocale"
    >
      <LocalizationSlot v-slot="{ locale }" :current :locales>
        <FormFieldTextInput
          :id="`localizations.${locale}.name`"
          :label="texts.models.surveyLink.name"
          :displayMode="FormFieldDisplayMode.Row"
        />
      </LocalizationSlot>
    </LocalizationTabs>

    <Divider />

    <FormFieldSelect
      id="formId"
      :label="texts.models.surveyLink.form"
      :items="availableForms"
      :displayMode="FormFieldDisplayMode.Row"
      :disabled="availableForms.length === 0"
    />
    <div
      class="-mt-4 grid grid-cols-1 gap-x-4 gap-y-1 sm:grid-cols-3 sm:items-center"
    >
      <FormFieldCheckbox
        id="copyAsInstance"
        data-testid="copyAsInstance"
        :label="texts.navigationItems.organize.activity.form.copyAsInstance"
        :displayMode="FormFieldDisplayMode.Column"
        :disabled="availableForms.length === 0"
        class="col-span-2 col-start-2"
      />

      <div class="col-span-2 col-start-2 mt-4">
        <div v-if="availableForms.length === 0">
          <Alert
            :content="
              texts.navigationItems.organize.activity.form.noFormsAvailable
            "
          />
        </div>
        <div v-else>
          <Alert
            :content="
              texts.navigationItems.organize.activity.form
                .copyAsInstanceDescription
            "
          />
        </div>
      </div>
    </div>

    <SectionDivider />

    <div class="flex items-center justify-between gap-4">
      <ButtonSubmit :loading="createLoading">
        <span>{{ texts.actions.create }}</span>
      </ButtonSubmit>
      <ButtonCancel @click="emit('create:cancel')" />
    </div>
  </form>
</template>

<script setup lang="ts">
import Alert from "@/components/common/alert/Alert.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import FormFieldCheckbox from "@/components/common/checkbox/FormFieldCheckbox.vue";
import Divider from "@/components/common/divider/Divider.vue";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import SectionDivider from "@/components/common/section/SectionDivider.vue";
import FormFieldSelect from "@/components/common/select/FormFieldSelect.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import LocalizationSlot from "@/components/localization-tabs/LocalizationSlot.vue";
import LocalizationTabs from "@/components/localization-tabs/LocalizationTabs.vue";
import { Culture } from "@/enums";
import { ActivityDTO } from "@/lib/eduConfigurationServiceClient";
import logger from "@/plugins/logger";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import settings from "@/store/context/settings.context";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import { FormType } from "@/lib/formsServiceClient";
import { useForm } from "vee-validate";
import { computed, ref } from "vue";
import * as yup from "yup";
import {
  SurveyLinkCreateFormValues,
  convertToCreateDTO,
} from "./SurveyLinkForm.types";
import { objectLocalizationSchemaOf } from "@/yup-extensions";

const defaultCulture = settings.mainLanguage.locale.value as Culture;

const props = defineProps<{
  activity: ActivityDTO;
}>();

const emit = defineEmits<{
  "create:cancel": [];
  "create:success": [];
}>();

const locales = computed(() =>
  form.values.localizations
    ? (Object.keys(form.values.localizations) as Culture[])
    : [defaultCulture],
);

const textsSurveyLink = texts.navigationItems.organize.activity.surveyLinks;

const formIdsInUse = ref<string[]>([]);
eduConfigurationServiceClient
  .getSurveyLinkFormIdsInUse(props.activity.id)
  .then((ids) => {
    formIdsInUse.value = ids;
  })
  .catch((e) => {
    logger.error(e);
  });

const availableForms = computed(() =>
  settings.forms
    .filter(
      (f) =>
        f.type == FormType.ActivityVisitedSurvey &&
        f.isTemplate &&
        !formIdsInUse.value.includes(f.id),
    )
    .map((f) => ({
      value: f.id,
      label: f.name,
    })),
);

const form = useForm<SurveyLinkCreateFormValues>({
  validationSchema: yup.object({
    localizations: objectLocalizationSchemaOf(
      yup.object({
        name: yup.string().max(256).required(),
        prospectAppDescription: yup.string().max(1024),
      }),
    ),
    formId: yup.string().required(),
    copyAsInstance: yup.boolean().required(),
  }),
});

const activeCulture = ref<Culture>(
  settings.mainLanguage.locale.value as Culture,
);

function addLocale(culture: Culture) {
  form.setFieldValue(
    "localizations",
    addToLocalizations(form.values.localizations, culture, {
      name: "",
    }),
  );
}

function removeLocale(culture: Culture) {
  form.setFieldValue(
    "localizations",
    removeFromLocalizations(form.values.localizations, culture),
  );
}

function addToLocalizations<T>(
  localizations: Partial<Record<Culture, T>>,
  culture: Culture,
  value: T,
) {
  return { ...localizations, [culture]: value };
}

function removeFromLocalizations<T>(
  localizations: Partial<Record<Culture, T>>,
  culture: Culture,
) {
  const newDict = { ...localizations };
  delete newDict[culture];
  return newDict;
}

const createLoading = ref<boolean>(false);
const submitForm = form.handleSubmit((values) => {
  createLoading.value = true;

  const dto = convertToCreateDTO(
    values as SurveyLinkCreateFormValues,
    settings.forms,
  );

  eduConfigurationServiceClient
    .createSurveyLink(props.activity.id, dto)
    .then(() => {
      Notify.success(textsSurveyLink.create.success);
      emit("create:success");
    })
    .catch((e) => {
      Notify.failure(textsSurveyLink.create.failure);
      logger.error(e);
    })
    .finally(() => {
      createLoading.value = false;
    });
});
</script>
