import {
  SelectionDefinitionDto,
  TargetAudienceDTO,
} from "@/lib/eduConfigurationServiceClient";
import { emptyUuid } from "@/utils/miscellaneous";

export const SelectionContextInjectionKey = "selectionContext";

export type TargetAudienceFormValues = {
  name: string;
  description: string;
};

export type TargetAudienceSubmitValues = TargetAudienceFormValues & {
  selectionDefinition: SelectionDefinitionDto;
};

export const convertToCreateDto = (
  values: TargetAudienceSubmitValues,
): TargetAudienceDTO => {
  return new TargetAudienceDTO({
    id: emptyUuid,
    name: values.name,
    description: values.description,
    selectionDefinition: values.selectionDefinition,
  });
};

export const convertToUpdateDto = (
  id: string,
  values: TargetAudienceSubmitValues,
): TargetAudienceDTO => {
  return new TargetAudienceDTO({
    id,
    name: values.name,
    description: values.description,
    selectionDefinition: values.selectionDefinition,
  });
};
